import { Component, OnInit } from '@angular/core';
import { CacheService } from '../services/cache.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footer_text: any;
	theme: any;

	constructor(private cacheService: CacheService) {
		/* @HostListener('scroll')
		onScroll() {
			if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
				console.log("you're at the bottom of the page");
			}
		} */
	}

	ngOnInit() {
		/* $(".show-footer").hide();
		if ($(window).scrollTop() + $(window).height() == $(document).height()) {
			$(".show-footer").show();
		}
		$(window).scroll(function () {
			if ($(window).scrollTop() + $(window).height() == $(document).height()) {
				$(".show-footer").show();
			} else {
				$(".show-footer").hide();
			}
		}); */

		this.theme = this.cacheService.getTheme();
		let footertext = this.cacheService.getFooterText();
		this.footer_text = footertext ? footertext : '';
	}
}

