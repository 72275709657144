import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiUrls } from '../../shared/constants/ApiUrls';
import { ApiService } from './api.service';
import { RequestContentType, ResponseContentType } from '../../shared/enums/enum';

@Injectable()
export class AuthenticationService {

    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }

    constructor(
        private httpService: ApiService
    ) {

    }

    login(corporate: number, clientid: string, username: string, password: string): Observable<any> {
        let body = {
            corporate: corporate,
            username: username,
            password: password,
            grant_type: "password",
            client_id: clientid
        };

        return this.httpService.postAs(
            'token',
            body,
            ResponseContentType.Json,
            RequestContentType.FORM,
            true
        )
    }

    logout() {
        let params = {
            "Type": 'LogOut',
            "Control": 'W',
        };

        return this.httpService.postAs(ApiUrls.Login.Logout, params)
    }
}