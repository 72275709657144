import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'ng2-bootstrap-modal';
import { MalihuScrollbarService } from "ngx-malihu-scrollbar";
import { ApiUrls } from '../../shared/constants/ApiUrls';
import { ApiService } from '../services/api.service';
import { CacheService } from '../services/cache.service';
import { AuthenticationService } from '../services/authentication.service';
import { CommonUtils } from '../../shared/utility/common-utils';
import { ResponseType } from '../../shared/enums/enum';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LockUnlockUserComponent } from './lock-unlock-user/lock-unlock-user.component';
import { PermissionService } from '../services/permission.service';
import { headerAnimation } from '../../shared/utility/menu-animate';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	animations: [
		headerAnimation
	]
})
export class HeaderComponent implements OnInit {
	selected: any;
	state: any;
	fix_menu = false;
	isHideMenu = false;
	SelectedMenuId: any;
	user_name: any;
	ui_preferance: any;
	browser_url: any;
	menu_position: any;
	fix_header: any;
	corporate_id: any;
	username: any;
	password: any;
	user_role: any;
	menu_content: any;
	view_user_popup: any;
	user_role_name: any;
	main_logo: any;
	profile_pic_aac: any;
	theme: any;
	app_url: any;
	DynamicMenus: any = [];
	permission: any = [];
	isChangePwd: any = false;
	isLockUser: any = false;

	constructor(
		private router: Router,
		public dialogService: DialogService,
		private permissionsService: PermissionService,
		private mScrollbarService: MalihuScrollbarService,
		private httpService: ApiService,
		private cacheService: CacheService,
		private authenticationService: AuthenticationService) {
	}

	ngOnInit() {
		this.corporate_id = this.cacheService.getCorporate();
		this.theme = this.cacheService.getTheme();
		this.user_name = this.cacheService.getNickName();
		this.user_role_name = this.cacheService.getRoleName();
		this.profile_pic_aac = this.cacheService.getProfilePic();
		this.main_logo = this.cacheService.getLogoUrl();
		this.getMenuPosition();
		this.getMenuData();
		this.getPermissions();
	}

	ngOnDestroy() {
		this.mScrollbarService.destroy('#myHeaderId');
		this.mScrollbarService.destroy('#myHeaderId1');
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.mScrollbarService.initScrollbar('#myHeaderId', { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } });
			this.mScrollbarService.initScrollbar('#myHeaderId1', { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } });
		}, 500)
	}

	setPermission() {
		this.permission = this.permissionsService.getFormPermissions('F006');

		if (this.permission && this.permission.length) {
			let sel = this.permission.filter(d => d.FormTab_Srno == 'F006-07');
			if (sel.length > 0) {
				if (sel[0].ViewRights) {
					this.isChangePwd = true;
				} else {
					this.isChangePwd = false;
				}
			} else {
				this.isChangePwd = false;
			}
		}

		let fpermis = this.permissionsService.getFormPermissions('F407');
		if (fpermis && fpermis.length) {
			let sel1 = fpermis.filter(d => d.FormTab_Srno == 'F407-01');
			if (sel1.length > 0) {
				if (sel1[0].ViewRights) {
					this.isLockUser = true;
				} else {
					this.isLockUser = false;
				}
			} else {
				this.isLockUser = false;
			}
		}
	}

	getMenuItem(ar, pid) {
		let op = [];
		for (var items in ar) {
			let item = ar[items];
			if (item.ParentMenuId == pid) {
				op[item.MenuId] = {
					'MenuId': item.MenuId,
					'MenuName': item.MenuName,
					'ParentMenuId': item.ParentMenuId,
					'icon': item.icon,
					'MenuUrl': item.MenuUrl,
					'Params': item.Params,
				};
				let children = this.getMenuItem(ar, item.MenuId);
				if (children) {
					op[item.MenuId]['sub'] = children;
				}
			}
		}
		return op;
	}

	clearIds(MenuObject, kd) {
		let returnArr = [];
		if (MenuObject) {
			for (var dc in MenuObject) {
				let objs = {
					'MenuId': MenuObject[dc].MenuId,
					'MenuName': MenuObject[dc].MenuName,
					'ParentMenuId': MenuObject[dc].ParentMenuId,
					'icon': MenuObject[dc].icon,
					'MenuUrl': MenuObject[dc].MenuUrl,
					'Params': MenuObject[dc].Params,
					'MasterMenuId': kd,
					'submenu': []
				}

				if (MenuObject[dc] && MenuObject[dc].sub) {
					objs.submenu = this.clearIds(MenuObject[dc].sub, kd);
				};

				returnArr.push(objs);
			}
		};
		return returnArr;
	}

	getMenuData() {
		let params = {
			Type: "menubind"
		};

		this.httpService.post(ApiUrls.Login.BaseLoad, params).subscribe(res => {
			if (res.ResponseType == 1) {
				this.DynamicMenus = [];

				let parent_menu = this.getMenuItem(res.Data.Table, null);

				for (var kd in parent_menu) {
					let objs = {
						'MenuId': parent_menu[kd].MenuId,
						'MenuName': parent_menu[kd].MenuName,
						'ParentMenuId': parent_menu[kd].ParentMenuId,
						'icon': parent_menu[kd].icon,
						'MenuUrl': parent_menu[kd].MenuUrl,
						'Params': parent_menu[kd].Params,
						'MasterMenuId': kd,
						'submenu': []
					}
					objs.submenu = this.clearIds(parent_menu[kd].sub, kd);
					this.DynamicMenus.push(objs);
				}
			}
		});
	}

	getPermissions() {
		let params = {
			Type: "Permissions"
		};

		this.httpService.post(ApiUrls.Login.BaseLoad, params).subscribe(res => {
			if (res.ResponseType == 1) {
				this.permissionsService.setPermisions(res.Data.Table);
				this.setPermission();
			}
		});
	}

	logoutUser() {
		this.authenticationService.logout().subscribe(
			res => {
				if (res.ResponseType == 1) {
					this.cacheService.removeAll();
					this.router.navigate(['']);
				}
			},
			ex => {
				this.cacheService.removeAll();
				this.router.navigate(['']);
			});
	}

	gotoProfile() {
		this.router.navigate(['updateprofile']);
	}

	openPasswordModal() {
		let modal = this.dialogService.addDialog(ChangePasswordComponent, {
			dialogModel: {
				showOldPassword: true,
				email: this.cacheService.getEmail()
			}
		}).subscribe((isConfirmed) => {
			if (isConfirmed) { }
			else { }
		});
	}

	openLockUnlockUserModal() {
		let modal = this.dialogService.addDialog(LockUnlockUserComponent, {
			dialogModel: {

			}
		}).subscribe((isConfirmed) => {
			if (isConfirmed) {

			}
			else {
			}
		});
	}

	openUserPopup(e) {
		e.stopPropagation();
		if (this.view_user_popup == true) {
			this.view_user_popup = false
		}
		else {
			this.view_user_popup = true;
		}
	}

	closeViewPopup() {
		if (this.view_user_popup == true) {
			this.view_user_popup = false;
		}
	}

	getMenuPosition() {
		var menu_data = this.cacheService.getUIPreference();
		if (menu_data) {
			if (menu_data['VerLeftMenuBar'] == true) {
				this.menu_position = 'side_menu';
				this.cacheService.setMenuPosition('side_menu');
			}
			if (menu_data['TopMenuBar'] == true) {
				this.menu_position = 'top_menu';
				this.cacheService.setMenuPosition('top_menu');
				//TODO:
				//	if (menu_data['FreezeHeader'] == true) {
				// this.menu_position = 'freeze_header';
				// this.cacheService.setMenuPosition('freeze_header');
				//	}
			}
		}
	}

	setSelectedMenuId(menuid) {
		this.SelectedMenuId = menuid;
	}

	setFixMenu() {
		if (this.fix_menu == true) {
			this.fix_menu = false;
		} else {
			this.fix_menu = true;
		}
	}

	select(item) {
		this.selected = (this.selected === item ? null : item);
	}

	setFalse() {
		this.isHideMenu = false;
	}

	isActive(item) {
		return this.selected === item;
	}
}
