import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grid-box',
  templateUrl: './grid-box.component.html',
  styleUrls: ['./grid-box.component.css']
})

export class GridBoxComponent implements OnInit {

  //Input Perameter

  @Input('navUrl') navUrl: string;
  @Input('isLoadFirstTime') isLoadFirstTime: any;
  @Input() gridBoxlayout: TemplateRef<any>;

  @Input('currentPage')
  currentPage: any;
  @Input('totalRecords')
  totalRecords: any;

  @Input('records')
  records: any;

  @Input('fields')
  fields: any;
  currentRecords: any;

  //Grid Box Display Perameter 
  allRecords: any = [];
  skelRecords: any = [];
  reorderfields: any = [];
  selected_field_icons: any = [];
  visible_fileds_data: any;
  isRelativePos: any;

  @Output('pageChanged')
  pageChanged = new EventEmitter<any>();

  @Output('edit')
  edit = new EventEmitter<any>();

  constructor(private router: Router) { }

  ngOnInit() {
    this.skelRecords = [];
    for (var i = 0; i < 10; i++) {
      this.skelRecords.push(i);
    };
  }

  ngOnChanges(item) {
    if (item.records && item.records.currentValue) {
      this.reloadRecords();
    }
  }

  reloadRecords() {
    if (this.currentPage == 1) {
      this.isRelativePos = this.totalRecords % 5;
      this.allRecords = this.records;
    } else {
      this.allRecords = this.allRecords.concat(this.records);
    }

    this.visible_fileds_data = this.fields;
    this.updateVisibleFields();

    if (this.fields) {
      for (var rd in this.fields) {
        this.selected_field_icons[this.fields[rd].TableColumnHeader] = this.fields[rd].IconClass;
      }
    } else {
      this.selected_field_icons = [];
    }
  }

  lazyLoadData() {
    this.pageChanged.emit();
  }

  onEdit(item) {
    if (this.navUrl) {
      if (item.InquiryCode && item.QuotCode && item.QuotTitle) {
        this.router.navigate([this.navUrl, { srno: item.Srno, qid: item.QuotCode, inq: item.InquiryCode }], { skipLocationChange: true });
      } else if (item.CustomForm_Srno) {
        this.router.navigate([this.navUrl, { srno: item.Srno, csrno: item.CustomForm_Srno }], { skipLocationChange: true });
      } else {
        this.router.navigate([this.navUrl, { srno: item.Srno, tablesrno: 0 }], { skipLocationChange: true });
      }
    }
    else {
      this.edit.emit(item);
    }
  }

  updateVisibleFields() {
    let new_order = [];

    if (this.visible_fileds_data != undefined && this.visible_fileds_data.length > 0) {
      this.visible_fileds_data.forEach((data, i) => {
        let colum_header = data.TableColumnHeader;
        let colum_headerMaster = data.FrontColumnHeader;

        let Visibility = data.Visibility;
        let FixedOrder = data.FixedOrder;

        if (Visibility == true || Visibility == 1 || Visibility == "True") {
          new_order.push({ "header": colum_headerMaster, "value": colum_header });
        }
      });
      this.reorderfields = new_order;
    }
  }

  getImpClass(indx) {
    if (((this.totalRecords - this.isRelativePos) <= indx)) {
      return true;
    } else {
      return false;
    }
  }

  getName(data) {
    if (data.Json_Data) {
      let tempd = JSON.parse(data.Json_Data);
      for(var ds in tempd) {
        return tempd[ds];
      }
    };
  }
}