import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeLayoutComponent } from './core/layouts/home-layout.component';
import { LoginLayoutComponent } from './core/layouts/login-layout.component';

const routes: Routes = [
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: 'app/login/login.module#LoginModule',
      },
      {
        path: 'webquotation',
        loadChildren: 'app/webquotation/webquotation.module#WebquotationModule',
      }
    ]
  },
  {
    path: '',
    component: HomeLayoutComponent,
    //canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: 'app/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'agentsignup',
        loadChildren: 'app/signup/signup.module#SignupModule',
      },
      {
        path: 'emailtemplate',
        loadChildren: 'app/email-template/email-template.module#EmailTemplateModule',
      },
      {
        path: 'updateprofile',
        loadChildren: 'app/user-profile/user-profile.module#UserProfileModule',
      },
      {
        path: 'form',
        loadChildren: 'app/form/form.module#FormModule',
      },
      {
        path: 'tab',
        loadChildren: 'app/tab/tab.module#TabModule',
      },
      {
        path: 'section',
        loadChildren: 'app/section/section.module#SectionModule',
      },
      {
        path: 'tire1',
        loadChildren: 'app/tire1/tire1.module#Tire1Module',
      },
      {
        path: 'profitCenter',
        loadChildren: 'app/profit-center/profit-center.module#ProfitCenterModule',
      },
      {
        path: 'userhierarchy',
        loadChildren: 'app/user-hierarchy/user-hierarchy.module#UserHierarchyModule',
      },
      {
        path: 'customerweblead',
        loadChildren: 'app/customer-web-lead/customer-web-lead.module#CustomerWebLeadModule',
      },
      {
        path: 'cmsArticle',
        loadChildren: 'app/cms-article/cms-article.module#CmsArticleModule',
      },
      {
        path: 'note',
        loadChildren: 'app/note/note.module#NoteModule',
      },
      {
        path: 'receipt',
        loadChildren: 'app/receipt/receipt.module#ReceiptModule',
      },
      {
        path: 'payable',
        loadChildren: 'app/payable/payable.module#PayableModule',
      },
      {
        path: 'advanceexpense',
        loadChildren: 'app/advance-expense/advance-expense.module#AdvanceExpenseModule',
      },
      {
        path: 'serviceallotment',
        loadChildren: 'app/allotment/allotment.module#AllotmentModule',
      },
      {
        path: 'whitelabelstep1',
        loadChildren: 'app/whitelabel-step1/whitelabel-step1.module#WhitelabelStep1Module',
      },
      {
        path: 'master',
        loadChildren: 'app/common-master/common-master.module#CommonMasterModule',
      },
      {
        path: 'Corporaterole',
        loadChildren: 'app/manage-role/manage-role.module#ManageRoleModule'
      },
      {
        path: 'CorporateUser',
        loadChildren: 'app/manage-user/manage-user.module#ManageUserModule'
      },
      {
        path: 'standardtag',
        loadChildren: 'app/standardtag/standardtag.module#StandardtagModule',
      },
      {
        path: 'gridviewmaster',
        loadChildren: 'app/gridviewmaster/gridviewmaster.module#GridviewmasterModule',
      },
      {
        path: 'Employee',
        loadChildren: 'app/employee/employee.module#EmployeeModule',
      },
      {
        path: 'configmaster',
        loadChildren: 'app/config-master/config-master.module#ConfigMasterModule',
      },
      {
        path: 'whitelabelstep2',
        loadChildren: 'app/whitelabel-step2/whitelabel-step2.module#WhitelabelStep2Module',
      },
      {
        path: 'whitelabelstep3',
        loadChildren: 'app/whitelabel-step3/whitelabel-step3.module#WhitelabelStep3Module',
      },
      {
        path: 'agent',
        loadChildren: 'app/agent/agent.module#AgentModule',
      },
      {
        path: 'supplier',
        loadChildren: 'app/supplier/supplier.module#SupplierModule',
      },
      {
        path: 'traveller',
        loadChildren: 'app/traveller/traveller.module#TravellerModule',
      },
      {
        path: 'customer',
        loadChildren: 'app/customer/customer.module#CustomerModule',
      },
      {
        path: 'booking',
        loadChildren: 'app/booking/booking.module#BookingModule',
      },
      {
        path: 'b2cbooking',
        loadChildren: 'app/b2cbooking/b2cbooking.module#B2cbookingModule',
      },
      {
        path: 'product',
        loadChildren: 'app/product/product.module#ProductModule',
      },
      {
        path: 'inquiry',
        loadChildren: 'app/inquiry/inquiry.module#InquiryModule',
      },
      {
        path: 'maintenance',
        loadChildren: 'app/maintenance/maintenance.module#MaintenanceModule',
      },
      {
        path: 'quotation',
        loadChildren: 'app/quotation/quotation.module#QuotationModule',
      },
      {
        path: 'reportwriter',
        loadChildren: 'app/report-writer/report-writer.module#ReportWriterModule',
      },
      {
        path: 'dashboardwriter',
        loadChildren: 'app/report-dashboard-writer/report-dashboard-writer.module#ReportDashboardWriterModule',
      },
      {
        path: 'reports',
        loadChildren: 'app/report/report.module#ReportModule',
      },
      {
        path: 'corporatebi',
        loadChildren: 'app/report-corporate-permission/report-corporate-permission.module#ReportCorporatePermissionModule',
      },
      {
        path: 'reportauth',
        loadChildren: 'app/report-permission/report-permission.module#ReportPermissionModule',
      },
      {
        path: 'task',
        loadChildren: 'app/task/task.module#TaskModule',
      },
      {
        path: 'event',
        loadChildren: 'app/event/event.module#EventModule',
      },
      {
        path: 'notification',
        loadChildren: 'app/notification/notification.module#NotificationModule',
      },
      {
        path: 'calandar',
        loadChildren: 'app/calandar/calandar.module#CalandarModule',
      },
      {
        path: 'dynamicform',
        loadChildren: 'app/dynamic-form/dynamic-form.module#DynamicFormModule',
      },
      {
        path: 'customform',
        loadChildren: 'app/custom-form/custom-form.module#CustomFormModule',
      },
      {
        path: 'asterisk',
        loadChildren: 'app/asterisk/asterisk.module#AsteriskModule',
      },
      {
        path: 'support',
        loadChildren: 'app/support/support.module#SupportModule',
      },
      {
        path: 'allinone',
        loadChildren: 'app/allinone/allinone.module#AllInOneModule',
      },
      {
        path: 'hotelcontract',
        loadChildren: 'app/hotelcontract/hotelcontract.module#HotelcontractModule',
      },
      {
        path: 'product-multilingual-info',
        loadChildren: 'app/multi-info/multi-info.module#MultiInfoModule',
      },
      {
        path: 'plans',
        loadChildren: 'app/plans/plans.module#PlansModule',
      }
    ]
  },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
