import { Injectable } from '@angular/core';
import { RequestOptions, Headers, Http, URLSearchParams } from '@angular/http';
import { HttpParams } from '@angular/common/http';
import { CacheService } from './cache.service';
import { ResponseContentType, RequestContentType, ResponseType } from '../../shared/enums/enum';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../shared/constants/AppSettings';
import { Router } from '@angular/router';
import { CommonUtils } from '../../shared/utility/common-utils';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {
    //private ApiUrlPrefix.ApiBaseUrl = 'http://localhost:3545/';

    constructor(
        private http: Http,
        private cacheService: CacheService,
        private router: Router
    ) {

    }



    get(path: string,
        params: Object,
        acceptType?: ResponseContentType,
        contentType?: RequestContentType,
        fullUrl: boolean = false): Observable<any> {
        let httpParams = new HttpParams();

        if (params) {
            for (const key in params) {
                httpParams.set(key, params[key]);
            }
        }

        return this.http.get(fullUrl ? path : `${AppSettings.ApiBaseUrl}${path}`,
            {
                headers: this.getHeaders(contentType, acceptType),
                params: httpParams
            })
            .pipe(
                map(res => acceptType && acceptType != ResponseContentType.Json ? res : res.json())
            )
            .pipe(
                catchError(error => {
                    return Observable.throw(error.json().error || 'Server error');
                })
            );
    }

    put(path: string,
        body: Object = {},
        acceptType?: ResponseContentType,
        contentType?: RequestContentType): Observable<any> {

        console.log(path + ' : ' + JSON.stringify(body));

        let options = new RequestOptions({ headers: this.getHeaders(contentType, acceptType) });

        return this.http.put(
            `${AppSettings.ApiBaseUrl}${path}`,
            contentType === RequestContentType.FORM ? this.getUrlParams(body) : contentType === RequestContentType.FORM_DATA ? this.getFormData(body) : body,
            options)
            .pipe(
                map(res => acceptType && acceptType !== ResponseContentType.Json ? res : res.json())
            )
            .pipe(
                catchError(error => {
                    return Observable.throw(error.json().error || 'Server error');
                })
            );
    }

    post(path: string,
        body: Object = {},
        acceptType?: ResponseContentType,
        contentType?: RequestContentType): Observable<any> {
        return this.postAs(path, body, acceptType, contentType)
            .pipe(
                catchError(error => {
                    return this.formatErrors(error, path, body, acceptType, contentType);
                })
            );
    }

    postAs(path: string,
        body: Object = {},
        acceptType?: ResponseContentType,
        contentType?: RequestContentType,
        skipToken = false): Observable<any> {

        if (!environment.production)
            console.log(path + ' : ' + JSON.stringify(body));

        let options = new RequestOptions({ headers: this.getHeaders(contentType, acceptType, skipToken) });

        if (acceptType == ResponseContentType.Blob)
            options.responseType = ResponseContentType.Blob;

        return this.http.post(
            `${AppSettings.ApiBaseUrl}${path}`,
            contentType === RequestContentType.FORM ? this.getUrlParams(body) : contentType === RequestContentType.FORM_DATA ? this.getFormData(body) : body,
            options)
            .pipe(
                map(res => acceptType && acceptType !== ResponseContentType.Json ? res : res.json())
            )
    }

    getUrlParams(body: Object): URLSearchParams {
        let httpParams = new URLSearchParams();

        if (body) {
            for (const key in body) {
                httpParams.set(key, body[key]);
            }
        }

        return httpParams;
    }

    getFormData(body: Object) {
        let formData = new FormData();

        if (body) {
            for (const key in body) {
                let data = body[key];

                if (Array.isArray(data)) {
                    data.forEach(element => {
                        formData.append(key, element);
                    });
                }
                else {
                    formData.append(key, data);
                }
            }
        }

        return formData;
    }

    formatErrors(
        error,
        path: string,
        body: Object = {},
        acceptType?: ResponseContentType,
        contentType?: RequestContentType): Observable<any> {
        if (error.status === 401) {
            /// Unauthorized, Authorization has been denied for this request
            let refreshToken = this.cacheService.getRefreshToken();
            if (refreshToken) {
                //Get New Access Token 
                this.getAccessToken().subscribe(response => {
                    if (response.access_token) {
                        this.setRefreshTokenDetails(response);
                        this.post(path, body, acceptType, contentType);
                    }
                    else {
                        CommonUtils.toaster("Session Expired", ResponseType.ERROR);
                        this.redirectToLogin();
                    }
                })
            }
            else {
                CommonUtils.toaster("Session Expired", ResponseType.ERROR);
                this.redirectToLogin();
            }
        }
        else if (error.status === 403) {
            //Access Denied
            let err = error.json();
            CommonUtils.toaster(err.Message, ResponseType.ERROR);
            this.redirectToHome();
            return Observable.throw(error.json().error || 'Access Denied');
        }
        else if (error.status === 409) {
            //Access Denied
            let err = error.json();
            CommonUtils.toaster(err.Message, ResponseType.ERROR);
            this.redirectToLogin();
            return Observable.throw(error.json().error || 'Access Denied');
        }
        else if (error.status === 429) {
            //Too Many Requests
            let err = error.json();
            CommonUtils.toaster(err, ResponseType.ERROR);
            return Observable.throw(error.json() || 'Too Many Requests');
        }
        else if (error.status === 500) {
            let err = error.json();
            CommonUtils.toaster(err.Message, ResponseType.ERROR);
            //Internal Server Error
            return Observable.throw(error.json().error || 'Internal Server Error');
        }
        else if (error.status === 400) {
            let err = error.json();
            CommonUtils.toaster(err.Message, ResponseType.ERROR);
            //Bad Request
            return Observable.throw(error.json().error || 'Bad Request');
        }
        else {
            return Observable.throw(error.json().error || 'Server error');
        }
    }

    getHeaders(contentType: RequestContentType, acceptType: ResponseContentType, skipToken = false) {
        const headersConfig = new Headers();

        if (!contentType) {
            contentType = RequestContentType.JSON;
        }
        if (!acceptType) {
            acceptType = ResponseContentType.Json;
        }

        if (contentType == RequestContentType.FORM_DATA)
            headersConfig.append('enctype', this.getContentType(contentType));
        else
            headersConfig.append('Content-Type', this.getContentType(contentType));

        headersConfig.append('Accept', this.getAcceptType(acceptType));

        this.AppendHeaders(headersConfig, skipToken);

        return headersConfig;
    }

    private getAccessToken() {
        let body = {
            username: this.cacheService.getUsername(),
            refresh_token: this.cacheService.getRefreshToken(),
            grant_type: "refresh_token",
            client_id: this.cacheService.getClientId(),
            corporate: this.cacheService.getCorporate(),
            token_id: this.cacheService.getTokenId()
        };

        return this.postAs(
            'token',
            body,
            ResponseContentType.Json,
            RequestContentType.FORM,
            true
        ).pipe(
            catchError(error => {
                CommonUtils.toaster("Session Expired", ResponseType.ERROR);
                this.redirectToLogin();
                return Observable.throw(error.json().error || 'Session Expired');
            })
        )
    }

    private getRefreshToken() {
        return new Promise((resolve, reject) => this.getAccessToken().subscribe(resolve, reject));
    }

    private setRefreshTokenDetails(response) {
        this.cacheService.setToken(response.access_token);
        this.cacheService.setRefreshToken(response.refresh_token);
        this.cacheService.setTokenExpires(response[".expires"]);
    }

    private AppendHeaders(headersConfig, skipToken) {
        let headers = this.getHttpHeaders(skipToken);

        headers.forEach(element => {
            headersConfig.append(element.key, element.value);
        });
    }

    public getHttpHeaders(skipToken = false) {
        let headers = [];
        let deviceDetails = this.getDeviceDetails();

        headers.push({ key: 'X-Token', value: AppSettings.XToken });
        headers.push({ key: 'X-DeviceType', value: 'Web' });
        headers.push({ key: 'X-DeviceID', value: deviceDetails.browser });
        headers.push({ key: 'X-BrowserVersion', value: deviceDetails.browserVersion });
        headers.push({ key: 'X-OSVersion', value: deviceDetails.os + ' ' + deviceDetails.osVersion });
        headers.push({ key: 'X-ScreenResolution', value: deviceDetails.screen });

        if (skipToken) {
            headers.push({ key: 'X-AuthType', value: AppSettings.XAuthType });
        }
        else {
            const token = this.cacheService.getToken();
            const expiresOn = this.cacheService.getTokenExpires();

            if (expiresOn && new Date(expiresOn) < new Date() && this.cacheService.getIsTemporaryToken() == 'false') {
                this.getRefreshToken().then(res => {
                    this.setRefreshTokenDetails(res);
                });
            }

            if (token) {
                headers.push({ key: 'Authorization', value: `bearer ${token}` });
            }
        }

        return headers;
    }

    private getContentType(contentType: RequestContentType): string {
        let val = '';
        switch (contentType) {
            case RequestContentType.FORM:
                val = 'application/x-www-form-urlencoded';
                break;

            case RequestContentType.FORM_DATA:
                val = 'multipart/form-data';
                break;

            case RequestContentType.JSON:
                val = 'application/json';
                break;
        }

        return val;
    }

    private getAcceptType(acceptType: ResponseContentType): string {
        let val = '';
        switch (acceptType) {
            case ResponseContentType.Blob:
                val = 'application/octet-stream';
                break;

            case ResponseContentType.Text:
                val = 'text/plain';
                break;

            case ResponseContentType.Json:
                val = 'application/json';
                break;
        }

        return val;
    }

    private redirectToLogin() {
        this.cacheService.removeAll();
        this.router.navigate(['']);
    }

    private redirectToHome() {
        this.router.navigate(['home']);
    }

    private getDeviceDetails() {
        let unknown = '-';

        // screen
        let screenSize = '';
        if (screen.width) {
            let width = (screen.width) ? screen.width : '';
            let height = (screen.height) ? screen.height : '';
            screenSize += '' + width + " x " + height;
        }

        // browser
        let nVer = navigator.appVersion;
        let nAgt = navigator.userAgent;
        let browser = navigator.appName;
        let version = '' + parseFloat(navigator.appVersion);
        let majorVersion = parseInt(navigator.appVersion, 10);
        let nameOffset, verOffset, ix;

        // Opera
        if ((verOffset = nAgt.indexOf('Opera')) != -1) {
            browser = 'Opera';
            version = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf('Version')) != -1) {
                version = nAgt.substring(verOffset + 8);
            }
        }
        // Opera Next
        if ((verOffset = nAgt.indexOf('OPR')) != -1) {
            browser = 'Opera';
            version = nAgt.substring(verOffset + 4);
        }
        // Edge
        else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
            browser = 'Microsoft Edge';
            version = nAgt.substring(verOffset + 5);
        }
        // MSIE
        else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
            browser = 'Microsoft Internet Explorer';
            version = nAgt.substring(verOffset + 5);
        }
        // Chrome
        else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
            browser = 'Chrome';
            version = nAgt.substring(verOffset + 7);
        }
        // Safari
        else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
            browser = 'Safari';
            version = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf('Version')) != -1) {
                version = nAgt.substring(verOffset + 8);
            }
        }
        // Firefox
        else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
            browser = 'Firefox';
            version = nAgt.substring(verOffset + 8);
        }
        // MSIE 11+
        else if (nAgt.indexOf('Trident/') != -1) {
            browser = 'Microsoft Internet Explorer';
            version = nAgt.substring(nAgt.indexOf('rv:') + 3);
        }
        // Other browsers
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
            browser = nAgt.substring(nameOffset, verOffset);
            version = nAgt.substring(verOffset + 1);
            if (browser.toLowerCase() == browser.toUpperCase()) {
                browser = navigator.appName;
            }
        }
        // trim the version string
        if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
        if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
        if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

        majorVersion = parseInt('' + version, 10);
        if (isNaN(majorVersion)) {
            version = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        // mobile version
        let mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

        // cookie
        let cookieEnabled = (navigator.cookieEnabled) ? true : false;

        if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
            document.cookie = 'testcookie';
            cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
        }

        // system
        let os = unknown;
        let clientStrings = [
            { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Chrome OS', r: /CrOS/ },
            { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
        ];
        for (let id in clientStrings) {
            let cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }

        let osVersion: any = 'unknown';

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
            os = 'Windows';
        }

        switch (os) {
            case 'Mac OS X':
                osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
                break;

            case 'Android':
                osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
                break;

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
                break;
        }

        // // flash (you'll need to include swfobject)
        // /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
        // let flashVersion = 'no check';
        // if (typeof swfobject != 'undefined') {
        //     let fv = swfobject.getFlashPlayerVersion();
        //     if (fv.major > 0) {
        //         flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
        //     }
        //     else {
        //         flashVersion = unknown;
        //     }
        // }


        return {
            screen: screenSize,
            browser: browser,
            browserVersion: version,
            browserMajorVersion: majorVersion,
            mobile: mobile,
            os: os,
            osVersion: osVersion,
            cookies: cookieEnabled
        };
    }

    /* get(path: string,
        params: Object,
        acceptType?: any,
        contentType?: any): Observable<any> {
        let httpParams = new HttpParams();
 
        if (params) {
            for (const key in params) {
                httpParams.set(key, params[key]);
            }
        }
 
        return this.http.get(`${this.AppSettings.ApiBaseUrl}${path}`,
            { headers: this.getHeaders(contentType, acceptType), params: httpParams })
            .map(res => acceptType && acceptType != 'Json' ? res : res.json())
            .catch(error => {
                return Observable.throw(error.json().error || 'Server error');
            });
    }
 
    put(path: string,
        body: Object = {},
        acceptType?: any,
        contentType?: any): Observable<any> {
 
        console.log(path + ' : ' + JSON.stringify(body));
 
        return this.http.put(
            `${this.AppSettings.ApiBaseUrl}${path}`,
            body,
            new RequestOptions({ headers: this.getHeaders(contentType, acceptType) }))
            .map(res => acceptType && acceptType != 'Json' ? res : res.json())
            .catch(this.formatErrors);
    }
 
    post(path: string,
        body: Object = {},
        acceptType?: any,
        contentType?: any): Observable<any> {
        console.log(path + ' : ' + JSON.stringify(body));
        let httpParams = new URLSearchParams();
 
        if (body) {
            for (const key in body) {
                httpParams.set(key, body[key]);
            }
        }
 
        return this.http.post(
            `${this.AppSettings.ApiBaseUrl}${path}`,
            contentType == 'FORM' ? httpParams : body,
            new RequestOptions({ headers: this.getHeaders(contentType, acceptType) }))
            .map(res => {
                debugger;
                return acceptType && acceptType != 'Json' ? res : res.json()
            })
            .catch(error => {
                debugger;
                console.log(error);
                return Observable.throw(error.json().error || 'Server error');
            });
    }
 
    private getHeaders(contentType: any, acceptType: any) {
        const headersConfig = new Headers();
 
        //headersConfig.append('Access-Control-Allow-Origin', '*');
        //headersConfig.append('Access-Control-Allow-Headers', 'Content-Type');
 
        if (!contentType) {
            contentType = 'JSON';
        }
        if (!acceptType) {
            acceptType = 'Json';
        }
 
        headersConfig.append('Content-Type', this.getContentType(contentType));
        headersConfig.append('Accept', this.getAcceptType(acceptType));
 
        const token = this.cacheService.getToken();
 
        if (token) {
            headersConfig.append('Authorization', `bearer ${token}`);
        }
 
        return headersConfig;
    }
 
    private getContentType(contentType: any): string {
        let val = '';
        switch (contentType) {
            case 'FORM':
                val = 'application/x-www-form-urlencoded';
                break;
 
            case 'FORM_DATA':
                val = 'multipart/form-data';
                break;
 
            case 'JSON':
                val = 'application/json';
                break;
        }
 
        return val;
    }
 
    private getAcceptType(acceptType: any): string {
        let val = '';
        switch (acceptType) {
            case 'Blob':
                val = 'application/octet-stream';
                break;
 
            case 'Text':
                val = 'text/plain';
                break;
 
            case 'Json':
                val = 'application/json';
                break;
        }
 
        return val;
    } */


}

