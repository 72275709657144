
export class ApiUrls {
    static readonly Prefix: string = 'api/';

    static readonly FormLoad: string = 'FormLoad';
    static readonly FormPermissions: string = 'FormPermissions';

    static readonly BaseLoad: string = 'BaseLoad';
    static readonly Save: string = 'Save';
    static readonly Delete: string = 'Delete';

    static readonly GridFormLoad: string = 'GridFormLoad';
    static readonly GridBaseLoad: string = 'GridBaseLoad';
    static readonly GridLoad: string = 'GridLoad';

    static Login = new class Login {
        private Login: string = ApiUrls.Prefix + 'Login/';

        FormLoad: string = this.Login + ApiUrls.FormLoad;
        BaseLoad: string = this.Login + ApiUrls.BaseLoad;
        Logout: string = this.Login + 'Logout';
    };

    static AgentRegistration = new class AgentRegistration {
        private AgentRegistration: string = ApiUrls.Prefix + 'AgentRegistration/';
        private SearchAgentRegistration = 'Search/';
        Search_API: string = this.AgentRegistration + this.SearchAgentRegistration;
        SearchAgentRegistration_GridFormLoad: string = this.AgentRegistration + this.SearchAgentRegistration + ApiUrls.GridFormLoad;
        SearchAgentRegistration_GridBaseLoad: string = this.AgentRegistration + this.SearchAgentRegistration + ApiUrls.GridBaseLoad;
        SearchAgentRegistration_GridLoad: string = this.AgentRegistration + this.SearchAgentRegistration + ApiUrls.GridLoad;

        CreateAgentRegistration_FormLoad: string = this.AgentRegistration + ApiUrls.FormLoad;
        CreateAgentRegistration_BaseLoad: string = this.AgentRegistration + ApiUrls.BaseLoad;
        CreateAgentRegistration_Save: string = this.AgentRegistration + ApiUrls.Save;
        CreateAgentRegistration_Update: string = this.AgentRegistration + 'Update';
        CreateAgentRegistration_Edit: string = this.AgentRegistration + 'Edit';
    };



    static CustomerRegistration = new class CustomerRegistration {
        private CustomerRegistration: string = ApiUrls.Prefix + 'CustomerRegistration/';
        private CustomerRegistration1: string = ApiUrls.Prefix + 'CustomerSignUp/';

        private SearchCustomerRegistration = 'Search/';
        Search_API: string = this.CustomerRegistration + this.SearchCustomerRegistration;
        SearchCustomerRegistration_GridFormLoad: string = this.CustomerRegistration + this.SearchCustomerRegistration + ApiUrls.GridFormLoad;
        SearchCustomerRegistration_GridBaseLoad: string = this.CustomerRegistration + this.SearchCustomerRegistration + ApiUrls.GridBaseLoad;
        SearchCustomerRegistration_GridLoad: string = this.CustomerRegistration + this.SearchCustomerRegistration + ApiUrls.GridLoad;

        CreateCustomerRegistration_FormLoad: string = this.CustomerRegistration1 + ApiUrls.FormLoad;
        CreateCustomerRegistration_BaseLoad: string = this.CustomerRegistration1 + ApiUrls.BaseLoad;
        CreateCustomerRegistration_Save: string = this.CustomerRegistration1 + ApiUrls.Save;
        CreateCustomerRegistration_Update: string = this.CustomerRegistration1 + 'Update';
        CreateCustomerRegistration_Edit: string = this.CustomerRegistration1 + 'Edit';
    };

    static EmailTemplate = new class EmailTemplate {
        private EmailTemplate: string = ApiUrls.Prefix + 'EmailTemplate/';

        private SearchEmailTemplate = 'Search/';
        Search_API: string = this.EmailTemplate + this.SearchEmailTemplate;
        SearchEmailTemplate_GridFormLoad: string = this.EmailTemplate + this.SearchEmailTemplate + ApiUrls.GridFormLoad;
        SearchEmailTemplate_GridBaseLoad: string = this.EmailTemplate + this.SearchEmailTemplate + ApiUrls.GridBaseLoad;
        SearchEmailTemplate_GridLoad: string = this.EmailTemplate + this.SearchEmailTemplate + ApiUrls.GridLoad;

        private CreateEmailTemplate = 'Create/';
        CreateEmailTemplate_FormLoad: string = this.EmailTemplate + this.CreateEmailTemplate + ApiUrls.FormLoad;
        CreateEmailTemplate_BaseLoad: string = this.EmailTemplate + this.CreateEmailTemplate + ApiUrls.BaseLoad;
        CreateEmailTemplate_Save: string = this.EmailTemplate + this.CreateEmailTemplate + ApiUrls.Save;
        getTemplateHtml: string = ApiUrls.Prefix + 'Export/getTemplateHtml';
    };


    static Tire1 = new class Tire1 {
        private Tire1: string = ApiUrls.Prefix + 'MarketingPromotion/';
        private SearchTire1 = 'DataTier1Search/';
        Search_API: string = this.Tire1 + this.SearchTire1;
        SearchTire1_GridFormLoad: string = this.Tire1 + this.SearchTire1 + ApiUrls.GridFormLoad;
        SearchTire1_GridBaseLoad: string = this.Tire1 + this.SearchTire1 + ApiUrls.GridBaseLoad;
        SearchTire1_GridLoad: string = this.Tire1 + this.SearchTire1 + ApiUrls.GridLoad;
        private CreateTire1 = 'DataTier1Create/';
        CreateTire1_FormLoad: string = this.Tire1 + this.CreateTire1 + ApiUrls.FormLoad;
        CreateTire1_BaseLoad: string = this.Tire1 + this.CreateTire1 + ApiUrls.BaseLoad;
        CreateTire1_Save: string = this.Tire1 + this.CreateTire1 + ApiUrls.Save;
        CreateTire1_GetDuplicatesTier1: string = this.Tire1 + this.CreateTire1 + 'GetDuplicatesTier1';
    };


    static ProfitCenter = new class ProfitCenter {
        private ProfitCenter: string = ApiUrls.Prefix + 'ProfitCenter/';
        private SearchProfitCenter = 'Search/';
        Search_API: string = this.ProfitCenter + this.SearchProfitCenter;
        SearchProfitCenter_GridFormLoad: string = this.ProfitCenter + this.SearchProfitCenter + ApiUrls.GridFormLoad;
        SearchProfitCenter_GridBaseLoad: string = this.ProfitCenter + this.SearchProfitCenter + ApiUrls.GridBaseLoad;
        SearchProfitCenter_GridLoad: string = this.ProfitCenter + this.SearchProfitCenter + ApiUrls.GridLoad;
        private CreateProfitCenter = 'Create/';
        CreateProfitCenter_FormLoad: string = this.ProfitCenter + this.CreateProfitCenter + ApiUrls.FormLoad;
        CreateProfitCenter_BaseLoad: string = this.ProfitCenter + this.CreateProfitCenter + ApiUrls.BaseLoad;
        CreateProfitCenter_Save: string = this.ProfitCenter + this.CreateProfitCenter + ApiUrls.Save;
    };

    static UserHierarchy = new class UserHierarchy {
        private UserHierarchy: string = ApiUrls.Prefix + 'User/';
        private CreateUserHierarchy = 'Hirerchy/';
        CreateUserHierarchy_FormLoad: string = this.UserHierarchy + this.CreateUserHierarchy + ApiUrls.FormLoad;
        CreateUserHierarchy_BaseLoad: string = this.UserHierarchy + this.CreateUserHierarchy + ApiUrls.BaseLoad;
    };

    static UpdateProfile = new class UpdateProfile {
        private UpdateProfile: string = ApiUrls.Prefix + 'ManageUser/';
        private UpdateUserProfile = 'UserProfile/';
        UpdateUserProfile_FormLoad: string = this.UpdateProfile + this.UpdateUserProfile + ApiUrls.FormLoad;
        UpdateUserProfile_BaseLoad: string = this.UpdateProfile + this.UpdateUserProfile + ApiUrls.BaseLoad;
        UpdateUserProfile_Save: string = this.UpdateProfile + this.UpdateUserProfile + ApiUrls.Save;
    };

    static ForgotPassword = new class ForgotPassword {
        private forgotPassword: string = ApiUrls.Prefix + 'ForgotPassword/';

        FormLoad: string = this.forgotPassword + ApiUrls.FormLoad;
        BaseLoad: string = this.forgotPassword + ApiUrls.BaseLoad;
    };

    static LockUser = new class LockUser {
        private lockuser: string = ApiUrls.Prefix + 'LockUnlockUser/';

        FormLoad: string = this.lockuser + ApiUrls.FormLoad;
        BaseLoad: string = this.lockuser + ApiUrls.BaseLoad;
    };

    static WhiteLabelStep1 = new class WhiteLabelStep1 {
        private WhiteLabelStep1: string = ApiUrls.Prefix + 'WhiteLabelStep1/';

        private SearchCorporate = 'SearchCorporate/';
        Search_API: string = this.WhiteLabelStep1 + this.SearchCorporate;
        SearchCorporate_GridFormLoad: string = this.WhiteLabelStep1 + this.SearchCorporate + ApiUrls.GridFormLoad;
        SearchCorporate_GridBaseLoad: string = this.WhiteLabelStep1 + this.SearchCorporate + ApiUrls.GridBaseLoad;
        SearchCorporate_GridLoad: string = this.WhiteLabelStep1 + this.SearchCorporate + ApiUrls.GridLoad;

        private CorporateDetail = 'CorporateDetail/';
        CorporateDetail_FormLoad: string = this.WhiteLabelStep1 + this.CorporateDetail + ApiUrls.FormLoad;
        CorporateDetail_BaseLoad: string = this.WhiteLabelStep1 + this.CorporateDetail + ApiUrls.BaseLoad;
        CorporateDetail_Save: string = this.WhiteLabelStep1 + this.CorporateDetail + ApiUrls.Save;
        CorporateDetail_Delete: string = this.WhiteLabelStep1 + this.CorporateDetail + ApiUrls.Delete;
        CorporateDetail_FormPermission: string = this.WhiteLabelStep1 + this.CorporateDetail + ApiUrls.FormPermissions;

        private Database = 'Database/';
        Database_FormLoad: string = this.WhiteLabelStep1 + this.Database + ApiUrls.FormLoad;
        Database_BaseLoad: string = this.WhiteLabelStep1 + this.Database + ApiUrls.BaseLoad;
        Database_Save: string = this.WhiteLabelStep1 + this.Database + ApiUrls.Save;

        private UIPreference = 'UIPreference/';
        UIPreference_FormLoad: string = this.WhiteLabelStep1 + this.UIPreference + ApiUrls.FormLoad;
        UIPreference_BaseLoad: string = this.WhiteLabelStep1 + this.UIPreference + ApiUrls.BaseLoad;
        UIPreference_Save: string = this.WhiteLabelStep1 + this.UIPreference + ApiUrls.Save;

        private BillingSubscription = 'Billing/';
        BillingSubscription_FormLoad: string = this.WhiteLabelStep1 + this.BillingSubscription + ApiUrls.FormLoad;
        BillingSubscription_BaseLoad: string = this.WhiteLabelStep1 + this.BillingSubscription + ApiUrls.BaseLoad;
        BillingSubscription_Save: string = this.WhiteLabelStep1 + this.BillingSubscription + ApiUrls.Save;

        private PasswordPreference = 'PasswordPreference/';
        PasswordPreference_FormLoad: string = this.WhiteLabelStep1 + this.PasswordPreference + ApiUrls.FormLoad;
        PasswordPreference_BaseLoad: string = this.WhiteLabelStep1 + this.PasswordPreference + ApiUrls.BaseLoad;
        PasswordPreference_Save: string = this.WhiteLabelStep1 + this.PasswordPreference + ApiUrls.Save;
    };

    static MasterDataEntry = new class MasterDataEntry {
        private MasterDataEntry: string = ApiUrls.Prefix + 'MasterDataEntry/';

        private SearchEntry = 'SearchEntry/';
        Search_API: string = this.MasterDataEntry + this.SearchEntry;
        SearchEntry_GridFormLoad: string = this.MasterDataEntry + this.SearchEntry + ApiUrls.GridFormLoad;
        SearchEntry_GridBaseLoad: string = this.MasterDataEntry + this.SearchEntry + ApiUrls.GridBaseLoad;
        SearchEntry_GridLoad: string = this.MasterDataEntry + this.SearchEntry + ApiUrls.GridLoad;

        private CreateEntry = 'CreateEntry/';
        CreateEntry_FormLoad: string = this.MasterDataEntry + this.CreateEntry + ApiUrls.FormLoad;
        CreateEntry_BaseLoad: string = this.MasterDataEntry + this.CreateEntry + ApiUrls.BaseLoad;
        CreateEntry_Save: string = this.MasterDataEntry + this.CreateEntry + ApiUrls.Save;
    };

    static CorporateRole = new class CorporateRole {
        private CorporateRole: string = ApiUrls.Prefix + 'ManageRole/';

        private SearchRole = 'SearchRole/';
        Search_API: string = this.CorporateRole + this.SearchRole;
        SearchRole_GridFormLoad: string = this.CorporateRole + this.SearchRole + ApiUrls.GridFormLoad;
        SearchRole_GridBaseLoad: string = this.CorporateRole + this.SearchRole + ApiUrls.GridBaseLoad;
        SearchRole_GridLoad: string = this.CorporateRole + this.SearchRole + ApiUrls.GridLoad;
        //Role
        private CreateRole = 'CreateRole/';
        CreateRole_FormLoad: string = this.CorporateRole + this.CreateRole + ApiUrls.FormLoad;
        CreateRole_BaseLoad: string = this.CorporateRole + this.CreateRole + ApiUrls.BaseLoad;
        CreateRole_Save: string = this.CorporateRole + this.CreateRole + ApiUrls.Save;
        CreateRole_FormPermission: string = this.CorporateRole + this.CreateRole + ApiUrls.FormPermissions;
        //RolePermission
        private CreateRolePermission = 'RolePermission/';
        CreateRolePermission_BaseLoad: string = this.CorporateRole + this.CreateRolePermission + ApiUrls.BaseLoad;
        CreateRolePermission_Save: string = this.CorporateRole + this.CreateRolePermission + ApiUrls.Save;
        //RoleTabPermission
        private CreateRoleTabPermission = 'RoleTabPermission/';
        CreateRoleTabPermission_BaseLoad: string = this.CorporateRole + this.CreateRoleTabPermission + ApiUrls.BaseLoad;
        CreateRoleTabPermission_Save: string = this.CorporateRole + this.CreateRoleTabPermission + ApiUrls.Save;
        //RoleTabSectionPemission
        private CreateRoleTabSectionPermission = 'RoleTabSectionPermission/';
        RoleTabSectionPermission_BaseLoad: string = this.CorporateRole + this.CreateRoleTabSectionPermission + ApiUrls.BaseLoad;
        RoleTabSectionPermission_Save: string = this.CorporateRole + this.CreateRoleTabSectionPermission + ApiUrls.Save;
    };

    static CorporateUser = new class CorporateUser {
        private CorporateUser: string = ApiUrls.Prefix + 'ManageUser/';

        private SearchUser = 'Search/';
        Search_API: string = this.CorporateUser + this.SearchUser;
        SearchUser_GridFormLoad: string = this.CorporateUser + this.SearchUser + ApiUrls.GridFormLoad;
        SearchUser_GridBaseLoad: string = this.CorporateUser + this.SearchUser + ApiUrls.GridBaseLoad;
        SearchUser_GridLoad: string = this.CorporateUser + this.SearchUser + ApiUrls.GridLoad;

        private CreateUser = 'CreateUser/';
        CreateUser_FormLoad: string = this.CorporateUser + this.CreateUser + ApiUrls.FormLoad;
        CreateUser_BaseLoad: string = this.CorporateUser + this.CreateUser + ApiUrls.BaseLoad;
        CreateUser_Save: string = this.CorporateUser + this.CreateUser + ApiUrls.Save;
        CreateUser_FormPermission: string = this.CorporateUser + this.CreateUser + ApiUrls.FormPermissions;
        //RolePermission
        private CreateRolePermission = 'UserPermission/';
        CreateRolePermission_BaseLoad: string = this.CorporateUser + this.CreateRolePermission + ApiUrls.BaseLoad;
        CreateRolePermission_Save: string = this.CorporateUser + this.CreateRolePermission + ApiUrls.Save;
        //RoleTabPermission
        private CreateRoleTabPermission = 'UserTabPermission/';
        CreateRoleTabPermission_BaseLoad: string = this.CorporateUser + this.CreateRoleTabPermission + ApiUrls.BaseLoad;
        CreateRoleTabPermission_Save: string = this.CorporateUser + this.CreateRoleTabPermission + ApiUrls.Save;
        //RoleTabSectionPemission
        private CreateRoleTabSectionPermission = 'UserTabSectionPermission/';
        RoleTabSectionPermission_BaseLoad: string = this.CorporateUser + this.CreateRoleTabSectionPermission + ApiUrls.BaseLoad;
        RoleTabSectionPermission_Save: string = this.CorporateUser + this.CreateRoleTabSectionPermission + ApiUrls.Save;

    };

    static StandardTag = new class StandardTag {
        private StandardTag: string = ApiUrls.Prefix + 'Corprate/';
        private CreateStandardTag = 'StandardTag/';
        CreateStandardTag_FormLoad: string = this.StandardTag + this.CreateStandardTag + ApiUrls.FormLoad;
        CreateStandardTag_BaseLoad: string = this.StandardTag + this.CreateStandardTag + ApiUrls.BaseLoad;
        CreateStandardTag_Save: string = this.StandardTag + this.CreateStandardTag + ApiUrls.Save;
        CreateStandardTag_FormPermission: string = this.StandardTag + this.CreateStandardTag + ApiUrls.FormPermissions;
    };

    static GridView = new class GridView {
        private GridView: string = ApiUrls.Prefix + 'GridView/';
        private CreateGridView = 'Master/';
        CreateGridView_FormLoad: string = this.GridView + this.CreateGridView + ApiUrls.FormLoad;
        CreateGridView_BaseLoad: string = this.GridView + this.CreateGridView + ApiUrls.BaseLoad;
        CreateGridView_Save: string = this.GridView + this.CreateGridView + ApiUrls.Save;
        CreateGridView_FormPermission: string = this.GridView + this.CreateGridView + ApiUrls.FormPermissions;
    };

    static Employee = new class Employee {
        private Employee: string = ApiUrls.Prefix + 'Employee/';
        private SearchEmployee = 'Search/';
        Search_API: string = this.Employee + this.SearchEmployee;
        SearchEmployee_GridFormLoad: string = this.Employee + this.SearchEmployee + ApiUrls.GridFormLoad;
        SearchEmployee_GridBaseLoad: string = this.Employee + this.SearchEmployee + ApiUrls.GridBaseLoad;
        SearchEmployee_GridLoad: string = this.Employee + this.SearchEmployee + ApiUrls.GridLoad;

        private CreateEmployee = 'CreateEmployee/';
        CreateEmployee_FormLoad: string = this.Employee + this.CreateEmployee + ApiUrls.FormLoad;
        CreateEmployee_BaseLoad: string = this.Employee + this.CreateEmployee + ApiUrls.BaseLoad;
        CreateEmployee_Save: string = this.Employee + this.CreateEmployee + ApiUrls.Save;
        CreateUser_FormPermission: string = this.Employee + this.CreateEmployee + ApiUrls.FormPermissions;
    };

    static Maintenance = new class Maintenance {
        private Maintenance: string = ApiUrls.Prefix + 'Maintenance/';
        private SearchMaintenance = 'Search/';
        Search_API: string = this.Maintenance + this.SearchMaintenance;
        SearchMaintenance_GridFormLoad: string = this.Maintenance + this.SearchMaintenance + ApiUrls.GridFormLoad;
        SearchMaintenance_GridBaseLoad: string = this.Maintenance + this.SearchMaintenance + ApiUrls.GridBaseLoad;
        SearchMaintenance_GridLoad: string = this.Maintenance + this.SearchMaintenance + ApiUrls.GridLoad;

        private CreateMaintenance = 'Create/';
        CreateMaintenance_FormLoad: string = this.Maintenance + this.CreateMaintenance + ApiUrls.FormLoad;
        CreateMaintenance_BaseLoad: string = this.Maintenance + this.CreateMaintenance + ApiUrls.BaseLoad;
        CreateMaintenance_Save: string = this.Maintenance + this.CreateMaintenance + ApiUrls.Save;
        CreateMaintenance_FormPermission: string = this.Maintenance + this.CreateMaintenance + ApiUrls.FormPermissions;
    };

    static Form = new class Form {
        private Form: string = ApiUrls.Prefix + 'Form/';
        private SearchForm = 'Search/';

        Search_API: string = this.Form + this.SearchForm;
        SearchForm_GridFormLoad: string = this.Form + this.SearchForm + ApiUrls.GridFormLoad;
        SearchForm_GridBaseLoad: string = this.Form + this.SearchForm + ApiUrls.GridBaseLoad;
        SearchForm_GridLoad: string = this.Form + this.SearchForm + ApiUrls.GridLoad;

        private CreateForm = 'Create/';
        CreateForm_FormLoad: string = this.Form + this.CreateForm + ApiUrls.FormLoad;
        CreateForm_BaseLoad: string = this.Form + this.CreateForm + ApiUrls.BaseLoad;
        CreateForm_Save: string = this.Form + this.CreateForm + ApiUrls.Save;
    };

    static Tab = new class Tab {
        private Tab: string = ApiUrls.Prefix + 'Tab/';
        private SearchTab = 'Search/';

        Search_API: string = this.Tab + this.SearchTab;
        SearchTab_GridFormLoad: string = this.Tab + this.SearchTab + ApiUrls.GridFormLoad;
        SearchTab_GridBaseLoad: string = this.Tab + this.SearchTab + ApiUrls.GridBaseLoad;
        SearchTab_GridLoad: string = this.Tab + this.SearchTab + ApiUrls.GridLoad;

        private CreateTab = 'Create/';
        CreateTab_FormLoad: string = this.Tab + this.CreateTab + ApiUrls.FormLoad;
        CreateTab_BaseLoad: string = this.Tab + this.CreateTab + ApiUrls.BaseLoad;
        CreateTab_Save: string = this.Tab + this.CreateTab + ApiUrls.Save;
    };

    static Section = new class Section {
        private Section: string = ApiUrls.Prefix + 'Section/';
        private SearchSection = 'Search/';

        Search_API: string = this.Section + this.SearchSection;
        SearchSection_GridFormLoad: string = this.Section + this.SearchSection + ApiUrls.GridFormLoad;
        SearchSection_GridBaseLoad: string = this.Section + this.SearchSection + ApiUrls.GridBaseLoad;
        SearchSection_GridLoad: string = this.Section + this.SearchSection + ApiUrls.GridLoad;

        private CreateSection = 'Create/';
        CreateSection_FormLoad: string = this.Section + this.CreateSection + ApiUrls.FormLoad;
        CreateSection_BaseLoad: string = this.Section + this.CreateSection + ApiUrls.BaseLoad;
        CreateSection_Save: string = this.Section + this.CreateSection + ApiUrls.Save;
    };

    static Inquiry = new class Inquiry {
        private Inquiry: string = ApiUrls.Prefix + 'Inquiry/';
        private SearchInquiry = 'Search/';

        Search_API: string = this.Inquiry + this.SearchInquiry;
        SearchInquiry_GridFormLoad: string = this.Inquiry + this.SearchInquiry + ApiUrls.GridFormLoad;
        SearchInquiry_GridBaseLoad: string = this.Inquiry + this.SearchInquiry + ApiUrls.GridBaseLoad;
        SearchInquiry_GridLoad: string = this.Inquiry + this.SearchInquiry + ApiUrls.GridLoad;

        private CreateInquiry = 'CreateInquiry/';
        CreateInquiry_FormLoad: string = this.Inquiry + this.CreateInquiry + ApiUrls.FormLoad;
        CreateInquiry_BaseLoad: string = this.Inquiry + this.CreateInquiry + ApiUrls.BaseLoad;
        CreateInquiry_Save: string = this.Inquiry + this.CreateInquiry + ApiUrls.Save;
        CopyInquiry_BaseLoad: string = this.Inquiry + 'CopyInquiry/' + ApiUrls.BaseLoad;

        private ShareInquiry = 'ShareInquiry/';
        ShareInquiry_FormLoad: string = this.Inquiry + this.ShareInquiry + ApiUrls.FormLoad;
        ShareInquiry_BaseLoad: string = this.Inquiry + this.ShareInquiry + ApiUrls.BaseLoad;
        ShareInquiry_Save: string = this.Inquiry + this.ShareInquiry + ApiUrls.Save;

        private ShareInquiryAccess = 'ShareInquiryAccess/';
        ShareInquiryAccess_FormLoad: string = this.Inquiry + this.ShareInquiryAccess + ApiUrls.FormLoad;
        ShareInquiryAccess_BaseLoad: string = this.Inquiry + this.ShareInquiryAccess + ApiUrls.BaseLoad;
        ShareInquiryAccess_Save: string = this.Inquiry + this.ShareInquiryAccess + ApiUrls.Save;

        private ShareInquiryEffort = 'ShareInquiryEffort/';
        ShareInquiryEffort_FormLoad: string = this.Inquiry + this.ShareInquiryEffort + ApiUrls.FormLoad;
        ShareInquiryEffort_BaseLoad: string = this.Inquiry + this.ShareInquiryEffort + ApiUrls.BaseLoad;

        private CancelInquiry = 'CancelInquiry/';
        CancelInquiry_FormLoad: string = this.Inquiry + this.CancelInquiry + ApiUrls.FormLoad;
        CancelInquiry_BaseLoad: string = this.Inquiry + this.CancelInquiry + ApiUrls.BaseLoad;
        CancelInquiry_Save: string = this.Inquiry + this.CancelInquiry + ApiUrls.Save;

        private LostInquiry = 'LostInquiry/';
        LostInquiry_FormLoad: string = this.Inquiry + this.LostInquiry + ApiUrls.FormLoad;
        LostInquiry_BaseLoad: string = this.Inquiry + this.LostInquiry + ApiUrls.BaseLoad;
        LostInquiry_Save: string = this.Inquiry + this.LostInquiry + ApiUrls.Save;

        private TrashInquiry = 'TrashInquiry/';
        TrashInquiry_FormLoad: string = this.Inquiry + this.TrashInquiry + ApiUrls.FormLoad;
        TrashInquiry_BaseLoad: string = this.Inquiry + this.TrashInquiry + ApiUrls.BaseLoad;
        TrashInquiry_Save: string = this.Inquiry + this.TrashInquiry + ApiUrls.Save;

        private CreateInclusions = 'Inclusions/';
        CreateInclusions_FormLoad: string = this.Inquiry + this.CreateInclusions + ApiUrls.FormLoad;
        CreateInclusions_BaseLoad: string = this.Inquiry + this.CreateInclusions + ApiUrls.BaseLoad;
        CreateInclusions_Save: string = this.Inquiry + this.CreateInclusions + ApiUrls.Save;
        CreateInclusions_SaveRate: string = this.Inquiry + this.CreateInclusions + "SaveRate";
    };

    static CMSArticle = new class CMSArticle {
        private CMSArticle: string = ApiUrls.Prefix + 'CMS/';
        private SearchCMSArticle = 'ArticleSearch/';
        Search_API: string = this.CMSArticle + this.SearchCMSArticle;
        SearchCMSArticle_GridFormLoad: string = this.CMSArticle + this.SearchCMSArticle + ApiUrls.GridFormLoad;
        SearchCMSArticle_GridBaseLoad: string = this.CMSArticle + this.SearchCMSArticle + ApiUrls.GridBaseLoad;
        SearchCMSArticle_GridLoad: string = this.CMSArticle + this.SearchCMSArticle + ApiUrls.GridLoad;
        private CreateCMSArticle = 'Article/';
        CreateCMSArticle_FormLoad: string = this.CMSArticle + this.CreateCMSArticle + ApiUrls.FormLoad;
        CreateCMSArticle_BaseLoad: string = this.CMSArticle + this.CreateCMSArticle + ApiUrls.BaseLoad;
        CreateCMSArticle_Save: string = this.CMSArticle + this.CreateCMSArticle + ApiUrls.Save;
        private CreateCmsContent = 'Content/';
        CreateCmsContent_FormLoad: string = this.CMSArticle + this.CreateCmsContent + ApiUrls.FormLoad;
        CreateCmsContent_BaseLoad: string = this.CMSArticle + this.CreateCmsContent + ApiUrls.BaseLoad;
        CreateCmsContent_Save: string = this.CMSArticle + this.CreateCmsContent + ApiUrls.Save;
        PreviewArticle: string = this.CMSArticle + this.CreateCmsContent + 'PreviewArticle';
    };

    static B2CBooking = new class B2CBooking {
        private B2CBooking: string = ApiUrls.Prefix + 'B2CBooking/';
        private SearchB2CBooking = 'Search/';
        Search_API: string = this.B2CBooking + this.SearchB2CBooking;
        SearchB2CBooking_GridFormLoad: string = this.B2CBooking + this.SearchB2CBooking + ApiUrls.GridFormLoad;
        SearchB2CBooking_GridBaseLoad: string = this.B2CBooking + this.SearchB2CBooking + ApiUrls.GridBaseLoad;
        SearchB2CBooking_GridLoad: string = this.B2CBooking + this.SearchB2CBooking + ApiUrls.GridLoad;

        private CreateHeader = 'Header/';
        CreateHeader_FormLoad: string = this.B2CBooking + this.CreateHeader + ApiUrls.FormLoad;
        CreateHeader_BaseLoad: string = this.B2CBooking + this.CreateHeader + ApiUrls.BaseLoad;
        CreateHeader_Save: string = this.B2CBooking + this.CreateHeader + ApiUrls.Save;

        private CreateTravelesBuckets = 'TravelerBuckets/';
        private CreateTravelerBucketsMember = 'TravelerBucketsMember/';
        private CreateTravelerSettings = 'TravelerSettings/';

        CreateTravelesBuckets_FormLoad: string = this.B2CBooking + this.CreateTravelesBuckets + ApiUrls.FormLoad;
        CreateTravelesBuckets_BaseLoad: string = this.B2CBooking + this.CreateTravelesBuckets + ApiUrls.BaseLoad;
        CreateTravelesBuckets_Save: string = this.B2CBooking + this.CreateTravelesBuckets + ApiUrls.Save;
        CreateTravelerBucketsMember_Save: string = this.B2CBooking + this.CreateTravelerBucketsMember + ApiUrls.Save;
        CreateTravelerSettings_Save: string = this.B2CBooking + this.CreateTravelerSettings + ApiUrls.Save;
        CreateTravelesSettings_BaseLoad: string = this.B2CBooking + this.CreateTravelerSettings + ApiUrls.BaseLoad;

        private B2CBookingDetails = 'Details/';
        B2CBookingDetails_FormLoad: string = this.B2CBooking + this.B2CBookingDetails + ApiUrls.FormLoad;
        B2CBookingDetails_BaseLoad: string = this.B2CBooking + this.B2CBookingDetails + ApiUrls.BaseLoad;
        B2CBookingDetails_Save: string = this.B2CBooking + this.B2CBookingDetails + ApiUrls.Save;

        private B2CBookingNotify = 'Notify/';
        B2CBookingNotify_FormLoad: string = this.B2CBooking + this.B2CBookingNotify + ApiUrls.FormLoad;
        B2CBookingNotify_BaseLoad: string = this.B2CBooking + this.B2CBookingNotify + ApiUrls.BaseLoad;
        B2CBookingNotify_Save: string = this.B2CBooking + this.B2CBookingNotify + ApiUrls.Save;

        private B2CAdditionalDocs = 'B2CAdditionalDocs/';
        B2CAdditionalDocs_FormLoad: string = this.B2CBooking + this.B2CAdditionalDocs + ApiUrls.FormLoad;
        B2CAdditionalDocs_BaseLoad: string = this.B2CBooking + this.B2CAdditionalDocs + ApiUrls.BaseLoad;
        B2CAdditionalDocs_Save: string = this.B2CBooking + this.B2CAdditionalDocs + ApiUrls.Save;

        private B2CBookingBookPopupFlight = 'BookPopupFlight/';
        B2CBookingBookPopupFlight_FormLoad: string = this.B2CBooking + this.B2CBookingBookPopupFlight + ApiUrls.FormLoad;
        B2CBookingBookPopupFlight_BaseLoad: string = this.B2CBooking + this.B2CBookingBookPopupFlight + ApiUrls.BaseLoad;
        B2CBookingBookPopupFlight_Save: string = this.B2CBooking + this.B2CBookingBookPopupFlight + ApiUrls.Save;

        private B2CBookingHotel = 'Hotel/';
        B2CBookingHotel_FormLoad: string = this.B2CBooking + this.B2CBookingHotel + ApiUrls.FormLoad;
        B2CBookingHotel_BaseLoad: string = this.B2CBooking + this.B2CBookingHotel + ApiUrls.BaseLoad;
        B2CBookingHotel_Save: string = this.B2CBooking + this.B2CBookingHotel + ApiUrls.Save;

        private B2CBookingSightseeing = 'Sightseeing/';
        B2CBookingSightseeing_FormLoad: string = this.B2CBooking + this.B2CBookingSightseeing + ApiUrls.FormLoad;
        B2CBookingSightseeing_BaseLoad: string = this.B2CBooking + this.B2CBookingSightseeing + ApiUrls.BaseLoad;
        B2CBookingSightseeing_Save: string = this.B2CBooking + this.B2CBookingSightseeing + ApiUrls.Save;

        private B2CBookingTransferSIC = 'TransferSIC/';
        B2CBookingTransferSIC_FormLoad: string = this.B2CBooking + this.B2CBookingTransferSIC + ApiUrls.FormLoad;
        B2CBookingTransferSIC_BaseLoad: string = this.B2CBooking + this.B2CBookingTransferSIC + ApiUrls.BaseLoad;
        B2CBookingTransferSIC_Save: string = this.B2CBooking + this.B2CBookingTransferSIC + ApiUrls.Save;

        private B2CBookingTransferPVT = 'TransferPVT/';
        B2CBookingTransferPVT_FormLoad: string = this.B2CBooking + this.B2CBookingTransferPVT + ApiUrls.FormLoad;
        B2CBookingTransferPVT_BaseLoad: string = this.B2CBooking + this.B2CBookingTransferPVT + ApiUrls.BaseLoad;
        B2CBookingTransferPVT_Save: string = this.B2CBooking + this.B2CBookingTransferPVT + ApiUrls.Save;

        private B2CBookingGuide = 'Guide/';
        B2CBookingGuide_FormLoad: string = this.B2CBooking + this.B2CBookingGuide + ApiUrls.FormLoad;
        B2CBookingGuide_BaseLoad: string = this.B2CBooking + this.B2CBookingGuide + ApiUrls.BaseLoad;
        B2CBookingGuide_Save: string = this.B2CBooking + this.B2CBookingGuide + ApiUrls.Save;

        private B2CBookingTrain = 'Train/';
        B2CBookingTrain_FormLoad: string = this.B2CBooking + this.B2CBookingTrain + ApiUrls.FormLoad;
        B2CBookingTrain_BaseLoad: string = this.B2CBooking + this.B2CBookingTrain + ApiUrls.BaseLoad;
        B2CBookingTrain_Save: string = this.B2CBooking + this.B2CBookingTrain + ApiUrls.Save;

        private B2CBookingMeal = 'Meal/';
        B2CBookingMeal_FormLoad: string = this.B2CBooking + this.B2CBookingMeal + ApiUrls.FormLoad;
        B2CBookingMeal_BaseLoad: string = this.B2CBooking + this.B2CBookingMeal + ApiUrls.BaseLoad;
        B2CBookingMeal_Save: string = this.B2CBooking + this.B2CBookingMeal + ApiUrls.Save;

        private B2CBookingCruise = 'Cruise/';
        B2CBookingCruise_FormLoad: string = this.B2CBooking + this.B2CBookingCruise + ApiUrls.FormLoad;
        B2CBookingCruise_BaseLoad: string = this.B2CBooking + this.B2CBookingCruise + ApiUrls.BaseLoad;
        B2CBookingCruise_Save: string = this.B2CBooking + this.B2CBookingCruise + ApiUrls.Save;

        private B2CBookingAdditionalInfo = 'AdditionalInfo/';
        B2CBookingAdditionalInfo_FormLoad: string = this.B2CBooking + this.B2CBookingAdditionalInfo + ApiUrls.FormLoad;
        B2CBookingAdditionalInfo_BaseLoad: string = this.B2CBooking + this.B2CBookingAdditionalInfo + ApiUrls.BaseLoad;
        B2CBookingAdditionalInfo_Save: string = this.B2CBooking + this.B2CBookingAdditionalInfo + ApiUrls.Save;

        private B2CBookingVouchers = 'Vouchers/';
        B2CBookingVouchers_FormLoad: string = this.B2CBooking + this.B2CBookingVouchers + ApiUrls.FormLoad;
        B2CBookingVouchers_BaseLoad: string = this.B2CBooking + this.B2CBookingVouchers + ApiUrls.BaseLoad;
        B2CBookingVouchers_Save: string = this.B2CBooking + this.B2CBookingVouchers + ApiUrls.Save;

        private B2CBookingStatusInfo = 'StatusInfo/';
        B2CBookingStatusInfo_FormLoad: string = this.B2CBooking + this.B2CBookingStatusInfo + ApiUrls.FormLoad;
        B2CBookingStatusInfo_BaseLoad: string = this.B2CBooking + this.B2CBookingStatusInfo + ApiUrls.BaseLoad;
        B2CBookingStatusInfo_Save: string = this.B2CBooking + this.B2CBookingStatusInfo + ApiUrls.Save;

        private B2CBookingShortRemarks = 'ShortRemarks/';
        B2CBookingShortRemarks_FormLoad: string = this.B2CBooking + this.B2CBookingShortRemarks + ApiUrls.FormLoad;
        B2CBookingShortRemarks_BaseLoad: string = this.B2CBooking + this.B2CBookingShortRemarks + ApiUrls.BaseLoad;
        B2CBookingShortRemarks_Save: string = this.B2CBooking + this.B2CBookingShortRemarks + ApiUrls.Save;

        private B2CBookingReportAnIncident = 'ReportAnIncident/';
        B2CBookingReportAnIncident_FormLoad: string = this.B2CBooking + this.B2CBookingReportAnIncident + ApiUrls.FormLoad;
        B2CBookingReportAnIncident_BaseLoad: string = this.B2CBooking + this.B2CBookingReportAnIncident + ApiUrls.BaseLoad;
        B2CBookingReportAnIncident_Save: string = this.B2CBooking + this.B2CBookingReportAnIncident + ApiUrls.Save;

        private B2CBookingOverallRemarks = 'OverallRemarks/';
        B2CBookingOverallRemarks_FormLoad: string = this.B2CBooking + this.B2CBookingOverallRemarks + ApiUrls.FormLoad;
        B2CBookingOverallRemarks_BaseLoad: string = this.B2CBooking + this.B2CBookingOverallRemarks + ApiUrls.BaseLoad;
        B2CBookingOverallRemarks_Save: string = this.B2CBooking + this.B2CBookingOverallRemarks + ApiUrls.Save;

        private B2CBookingTravelersIncludeExclude = 'TravelersIncludeExclude/';
        B2CBookingTravelersIncludeExclude_FormLoad: string = this.B2CBooking + this.B2CBookingTravelersIncludeExclude + ApiUrls.FormLoad;
        B2CBookingTravelersIncludeExclude_BaseLoad: string = this.B2CBooking + this.B2CBookingTravelersIncludeExclude + ApiUrls.BaseLoad;
        B2CBookingTravelersIncludeExclude_Save: string = this.B2CBooking + this.B2CBookingTravelersIncludeExclude + ApiUrls.Save;

        private B2CBookingUpdateStatus = 'UpdateStatus/';
        B2CBookingUpdateStatus_BaseLoad: string = this.B2CBooking + this.B2CBookingUpdateStatus + ApiUrls.BaseLoad;
        B2CBookingUpdateStatus_Save: string = this.B2CBooking + this.B2CBookingUpdateStatus + ApiUrls.Save;

    };

    static PaymentReceipt = new class PaymentReceipt {
        private PaymentReceipt: string = ApiUrls.Prefix + 'PaymentReceipt/';
        private PaymentSettlement: string = ApiUrls.Prefix + 'PaymentSettlement/';

        private SearchPaymentReceipt = 'Search/';
        Search_API: string = this.PaymentReceipt + this.SearchPaymentReceipt;
        SearchPaymentReceipt_GridFormLoad: string = this.PaymentReceipt + this.SearchPaymentReceipt + ApiUrls.GridFormLoad;
        SearchPaymentReceipt_GridBaseLoad: string = this.PaymentReceipt + this.SearchPaymentReceipt + ApiUrls.GridBaseLoad;
        SearchPaymentReceipt_GridLoad: string = this.PaymentReceipt + this.SearchPaymentReceipt + ApiUrls.GridLoad;

        private CreatePaymentReceiptHeader = 'Header/';
        CreatePaymentReceiptHeader_FormLoad: string = this.PaymentReceipt + this.CreatePaymentReceiptHeader + ApiUrls.FormLoad;
        CreatePaymentReceiptHeader_BaseLoad: string = this.PaymentReceipt + this.CreatePaymentReceiptHeader + ApiUrls.BaseLoad;
        CreatePaymentReceiptHeader_Save: string = this.PaymentReceipt + this.CreatePaymentReceiptHeader + ApiUrls.Save;

        private CreatePaymentReceiptSettlement = 'PaymentSettlement/';
        CreatePaymentReceiptSettlement_FormLoad: string = this.PaymentSettlement + ApiUrls.FormLoad;
        CreatePaymentReceiptSettlement_BaseLoad: string = this.PaymentSettlement + ApiUrls.BaseLoad;
        CreatePaymentReceiptSettlement_Save: string = this.PaymentSettlement + ApiUrls.Save;
    };

    static PaymentPayable = new class PaymentPayable {
        private PaymentPayable: string = ApiUrls.Prefix + 'PaymentPayable/';
        private PaymentPayableSettlement: string = ApiUrls.Prefix + 'PaymentPayableSettlement/';

        private SearchPaymentPayable = 'Search/';
        Search_API: string = this.PaymentPayable + this.SearchPaymentPayable;
        SearchPaymentPayable_GridFormLoad: string = this.PaymentPayable + this.SearchPaymentPayable + ApiUrls.GridFormLoad;
        SearchPaymentPayable_GridBaseLoad: string = this.PaymentPayable + this.SearchPaymentPayable + ApiUrls.GridBaseLoad;
        SearchPaymentPayable_GridLoad: string = this.PaymentPayable + this.SearchPaymentPayable + ApiUrls.GridLoad;

        private CreatePaymentPayableHeader = 'Header/';
        CreatePaymentPayableHeader_FormLoad: string = this.PaymentPayable + this.CreatePaymentPayableHeader + ApiUrls.FormLoad;
        CreatePaymentPayableHeader_BaseLoad: string = this.PaymentPayable + this.CreatePaymentPayableHeader + ApiUrls.BaseLoad;
        CreatePaymentPayableHeader_Save: string = this.PaymentPayable + this.CreatePaymentPayableHeader + ApiUrls.Save;

        private CreatePaymentPayableSettlement = 'PaymentPayableSettlement/';
        CreatePaymentPayableSettlement_FormLoad: string = this.PaymentPayableSettlement + ApiUrls.FormLoad;
        CreatePaymentPayableSettlement_BaseLoad: string = this.PaymentPayableSettlement + ApiUrls.BaseLoad;
        CreatePaymentPayableSettlement_Save: string = this.PaymentPayableSettlement + ApiUrls.Save;
    };

    static AdvanceExpense = new class AdvanceExpense {
        private AdvanceExpense: string = ApiUrls.Prefix + 'AdvanceExpense/';
        private SearchAdvanceExpense = 'SearchHeader/';
        Search_API: string = this.AdvanceExpense + this.SearchAdvanceExpense;
        SearchAdvanceExpense_GridFormLoad: string = this.AdvanceExpense + this.SearchAdvanceExpense + ApiUrls.GridFormLoad;
        SearchAdvanceExpense_GridBaseLoad: string = this.AdvanceExpense + this.SearchAdvanceExpense + ApiUrls.GridBaseLoad;
        SearchAdvanceExpense_GridLoad: string = this.AdvanceExpense + this.SearchAdvanceExpense + ApiUrls.GridLoad;
        private CreateAdvanceExpenseHeader = 'Header/';
        CreateAdvanceExpenseHeader_FormLoad: string = this.AdvanceExpense + this.CreateAdvanceExpenseHeader + ApiUrls.FormLoad;
        CreateAdvanceExpenseHeader_BaseLoad: string = this.AdvanceExpense + this.CreateAdvanceExpenseHeader + ApiUrls.BaseLoad;
        CreateAdvanceExpenseHeader_Save: string = this.AdvanceExpense + this.CreateAdvanceExpenseHeader + ApiUrls.Save;
        private CreateAdvanceExpenseSettlement = 'Settlement/';
        CreatePaymentReceiptSettlement_FormLoad: string = this.AdvanceExpense + this.CreateAdvanceExpenseSettlement + ApiUrls.FormLoad;
        CreatePaymentReceiptSettlement_BaseLoad: string = this.AdvanceExpense + this.CreateAdvanceExpenseSettlement + ApiUrls.BaseLoad;
        CreatePaymentReceiptSettlement_Save: string = this.AdvanceExpense + this.CreateAdvanceExpenseSettlement + ApiUrls.Save;
    };

    static ServicesAllotment = new class ServicesAllotment {
        private ServicesAllotment: string = ApiUrls.Prefix + 'Booking/';
        private CreateServicesAllotment = 'ServiceConsolidation/';
        CreateServicesAllotment_FormLoad: string = this.ServicesAllotment + this.CreateServicesAllotment + ApiUrls.FormLoad;
        CreateServicesAllotment_BaseLoad: string = this.ServicesAllotment + this.CreateServicesAllotment + ApiUrls.BaseLoad;
        CreateServicesAllotment_Save: string = this.ServicesAllotment + this.CreateServicesAllotment + ApiUrls.Save;
    };

    static CreditDebitNote = new class CreditDebitNote {
        private CreditDebitNote: string = ApiUrls.Prefix + 'CreditNote/';
        private SearchCreditDebitNote = 'Search/';
        Search_API: string = this.CreditDebitNote + this.SearchCreditDebitNote;
        SearchCreditDebitNote_GridFormLoad: string = this.CreditDebitNote + this.SearchCreditDebitNote + ApiUrls.GridFormLoad;
        SearchCreditDebitNote_GridBaseLoad: string = this.CreditDebitNote + this.SearchCreditDebitNote + ApiUrls.GridBaseLoad;
        SearchCreditDebitNote_GridLoad: string = this.CreditDebitNote + this.SearchCreditDebitNote + ApiUrls.GridLoad;
        CreateCreditDebitNote_FormLoad: string = this.CreditDebitNote + ApiUrls.FormLoad;
        CreateCreditDebitNote_BaseLoad: string = this.CreditDebitNote + ApiUrls.BaseLoad;
        CreateCreditDebitNote_Save: string = this.CreditDebitNote + ApiUrls.Save;
    };

    static DebitNote = new class DebitNote {
        private DebitNote: string = ApiUrls.Prefix + 'DebitNote/';
        private SearchDebitNote = 'Search/';
        Search_API: string = this.DebitNote + this.SearchDebitNote;
        SearchDebitNote_GridFormLoad: string = this.DebitNote + this.SearchDebitNote + ApiUrls.GridFormLoad;
        SearchDebitNote_GridBaseLoad: string = this.DebitNote + this.SearchDebitNote + ApiUrls.GridBaseLoad;
        SearchDebitNote_GridLoad: string = this.DebitNote + this.SearchDebitNote + ApiUrls.GridLoad;
        CreateDebitNote_FormLoad: string = this.DebitNote + ApiUrls.FormLoad;
        CreateDebitNote_BaseLoad: string = this.DebitNote + ApiUrls.BaseLoad;
        CreateDebitNote_Save: string = this.DebitNote + ApiUrls.Save;
    };

    static Agent = new class Agent {
        private Agent: string = ApiUrls.Prefix + 'Agent/';
        private SearchAgent = 'SearchAgent/';
        Search_API: string = this.Agent + this.SearchAgent;
        SearchAgent_GridFormLoad: string = this.Agent + this.SearchAgent + ApiUrls.GridFormLoad;
        SearchAgent_GridBaseLoad: string = this.Agent + this.SearchAgent + ApiUrls.GridBaseLoad;
        SearchAgent_GridLoad: string = this.Agent + this.SearchAgent + ApiUrls.GridLoad;
        private CreateAgent = 'CreateAgent/';
        CreateAgent_FormLoad: string = this.Agent + this.CreateAgent + ApiUrls.FormLoad;
        CreateAgent_BaseLoad: string = this.Agent + this.CreateAgent + ApiUrls.BaseLoad;
        CreateAgent_Save: string = this.Agent + this.CreateAgent + ApiUrls.Save;
        CreateAgent_FormPermission: string = this.Agent + this.CreateAgent + ApiUrls.FormPermissions;

        private CreateUser = 'User/';
        CreateUser_FormLoad: string = this.Agent + this.CreateUser + ApiUrls.FormLoad;
        CreateUser_BaseLoad: string = this.Agent + this.CreateUser + ApiUrls.BaseLoad;
        CreateUser_Save: string = this.Agent + this.CreateUser + ApiUrls.Save;

        private CreateBilling = 'Billing/';
        CreateBilling_FormLoad: string = this.Agent + this.CreateBilling + ApiUrls.FormLoad;
        CreateBilling_BaseLoad: string = this.Agent + this.CreateBilling + ApiUrls.BaseLoad;
        CreateBilling_Save: string = this.Agent + this.CreateBilling + ApiUrls.Save;

        private CreateSellingCriteria = 'SellingCriteria/';
        CreateSellingCriteria_FormLoad: string = this.Agent + this.CreateSellingCriteria + ApiUrls.FormLoad;
        CreateSellingCriteria_BaseLoad: string = this.Agent + this.CreateSellingCriteria + ApiUrls.BaseLoad;
        CreateSellingCriteria_Save: string = this.Agent + this.CreateSellingCriteria + ApiUrls.Save;

        private CreateCreditLimit = 'CreditLimit/';
        CreateCreditLimit_FormLoad: string = this.Agent + this.CreateCreditLimit + ApiUrls.FormLoad;
        CreateCreditLimit_BaseLoad: string = this.Agent + this.CreateCreditLimit + ApiUrls.BaseLoad;
        CreateCreditLimit_Save: string = this.Agent + this.CreateCreditLimit + ApiUrls.Save;

        private CreateAccount = 'Account/';
        CreateAccount_FormLoad: string = this.Agent + this.CreateAccount + ApiUrls.FormLoad;
        CreateAccount_BaseLoad: string = this.Agent + this.CreateAccount + ApiUrls.BaseLoad;
        CreateAccount_Save: string = this.Agent + this.CreateAccount + ApiUrls.Save;

    };
    static Booking = new class Booking {
        private Booking: string = ApiUrls.Prefix + 'Booking/';

        private SearchBooking = 'Search/';
        Search_API: string = this.Booking + this.SearchBooking;
        SearchBooking_GridFormLoad: string = this.Booking + this.SearchBooking + ApiUrls.GridFormLoad;
        SearchBooking_GridBaseLoad: string = this.Booking + this.SearchBooking + ApiUrls.GridBaseLoad;
        SearchBooking_GridLoad: string = this.Booking + this.SearchBooking + ApiUrls.GridLoad;

        private CreateBooking = 'BasicDetail/';
        CreateBooking_FormLoad: string = this.Booking + this.CreateBooking + ApiUrls.FormLoad;
        CreateBooking_BaseLoad: string = this.Booking + this.CreateBooking + ApiUrls.BaseLoad;
        CreateBooking_Save: string = this.Booking + this.CreateBooking + ApiUrls.Save;

        private OverallDiscount = 'OverallDiscount/';
        DiscountBooking_FormLoad: string = this.Booking + this.OverallDiscount + ApiUrls.FormLoad;
        DiscountBooking_BaseLoad: string = this.Booking + this.OverallDiscount + ApiUrls.BaseLoad;
        DiscountBooking_Save: string = this.Booking + this.OverallDiscount + ApiUrls.Save;

        private OverallMargin = 'OverallMargin/';
        MarginBooking_FormLoad: string = this.Booking + this.OverallMargin + ApiUrls.FormLoad;
        MarginBooking_BaseLoad: string = this.Booking + this.OverallMargin + ApiUrls.BaseLoad;
        MarginBooking_Save: string = this.Booking + this.OverallMargin + ApiUrls.Save;

        private BookingDetail = 'ServiceBasic/';
        BookingDetail_FormLoad: string = this.Booking + this.BookingDetail + ApiUrls.FormLoad;
        BookingDetail_BaseLoad: string = this.Booking + this.BookingDetail + ApiUrls.BaseLoad;
        BookingDetail_Save: string = this.Booking + this.BookingDetail + ApiUrls.Save;

        private BookRate = 'ServiceRate/';
        BookRate_FormLoad: string = this.Booking + this.BookRate + ApiUrls.FormLoad;
        BookRate_BaseLoad: string = this.Booking + this.BookRate + ApiUrls.BaseLoad;
        BookRate_Save: string = this.Booking + this.BookRate + ApiUrls.Save;

        private ServiceBook = 'ServiceBook/';
        ServiceBook_FormLoad: string = this.Booking + this.ServiceBook + ApiUrls.FormLoad;
        ServiceBook_BaseLoad: string = this.Booking + this.ServiceBook + ApiUrls.BaseLoad;
        ServiceBook_Save: string = this.Booking + this.ServiceBook + ApiUrls.Save;

        private ServiceConfirm = 'ServiceConfirm/';
        ServiceConfirm_FormLoad: string = this.Booking + this.ServiceConfirm + ApiUrls.FormLoad;
        ServiceConfirm_BaseLoad: string = this.Booking + this.ServiceConfirm + ApiUrls.BaseLoad;
        ServiceConfirm_Save: string = this.Booking + this.ServiceConfirm + ApiUrls.Save;

        private ServiceReCoinfirm = 'ServiceReCoinfirm/';
        ServiceReCoinfirm_FormLoad: string = this.Booking + this.ServiceReCoinfirm + ApiUrls.FormLoad;
        ServiceReCoinfirm_BaseLoad: string = this.Booking + this.ServiceReCoinfirm + ApiUrls.BaseLoad;
        ServiceReCoinfirm_Save: string = this.Booking + this.ServiceReCoinfirm + ApiUrls.Save;

        private Cancellation = 'Cancellation/';
        Cancellation_FormLoad: string = this.Booking + this.Cancellation + ApiUrls.FormLoad;
        Cancellation_BaseLoad: string = this.Booking + this.Cancellation + ApiUrls.BaseLoad;
        Cancellation_Save: string = this.Booking + this.Cancellation + ApiUrls.Save;

        private Reduction = 'Reduction/';
        Reduction_FormLoad: string = this.Booking + this.Reduction + ApiUrls.FormLoad;
        Reduction_BaseLoad: string = this.Booking + this.Reduction + ApiUrls.BaseLoad;
        Reduction_Save: string = this.Booking + this.Reduction + ApiUrls.Save;

        private BookingInvoice = 'Invoice/';
        BookingInvoice_FormLoad: string = this.Booking + this.BookingInvoice + ApiUrls.FormLoad;
        BookingInvoice_BaseLoad: string = this.Booking + this.BookingInvoice + ApiUrls.BaseLoad;
        BookingInvoice_Save: string = this.Booking + this.BookingInvoice + ApiUrls.Save;
        BookingInvoice_Export: string = this.Booking + this.BookingInvoice + "ExportInvoice";
        BookingInvoice_SendEmailWithAttachment: string = this.Booking + this.BookingInvoice + "SendEmailWithAttachment";

        private PaymentScheduleAgent = 'PaymentScheduleAgent/';
        PaymentScheduleAgent_FormLoad: string = this.Booking + this.PaymentScheduleAgent + ApiUrls.FormLoad;
        PaymentScheduleAgent_BaseLoad: string = this.Booking + this.PaymentScheduleAgent + ApiUrls.BaseLoad;
        PaymentScheduleAgent_Save: string = this.Booking + this.PaymentScheduleAgent + ApiUrls.Save;

        private PaymentScheduleSupplier = 'PaymentScheduleSupplier/';
        PaymentScheduleSupplier_FormLoad: string = this.Booking + this.PaymentScheduleSupplier + ApiUrls.FormLoad;
        PaymentScheduleSupplier_BaseLoad: string = this.Booking + this.PaymentScheduleSupplier + ApiUrls.BaseLoad;
        PaymentScheduleSupplier_Save: string = this.Booking + this.PaymentScheduleSupplier + ApiUrls.Save;
    };

    static Product = new class Product {
        private Product: string = ApiUrls.Prefix + 'Product/';

        private SearchProduct = 'Search/';
        Search_API: string = this.Product + this.SearchProduct;
        SearchProduct_GridFormLoad: string = this.Product + this.SearchProduct + ApiUrls.GridFormLoad;
        SearchProduct_GridBaseLoad: string = this.Product + this.SearchProduct + ApiUrls.GridBaseLoad;
        SearchProduct_GridLoad: string = this.Product + this.SearchProduct + ApiUrls.GridLoad;

        private CreateProduct = 'Create/';
        CreateProduct_FormLoad: string = this.Product + this.CreateProduct + ApiUrls.FormLoad;
        CreateProduct_BaseLoad: string = this.Product + this.CreateProduct + ApiUrls.BaseLoad;
        CreateProduct_Save: string = this.Product + this.CreateProduct + ApiUrls.Save;

        private ProductInclusions = 'Inclusions/';
        ProductInclusions_FormLoad: string = this.Product + this.ProductInclusions + ApiUrls.FormLoad;
        ProductInclusions_BaseLoad: string = this.Product + this.ProductInclusions + ApiUrls.BaseLoad;
        ProductInclusions_Save: string = this.Product + this.ProductInclusions + ApiUrls.Save;

        private ProductInfo = 'Info/';
        ProductInfo_FormLoad: string = this.Product + this.ProductInfo + ApiUrls.FormLoad;
        ProductInfo_BaseLoad: string = this.Product + this.ProductInfo + ApiUrls.BaseLoad;
        ProductInfo_Save: string = this.Product + this.ProductInfo + ApiUrls.Save;

        private ProductOverview = 'Overview/';
        ProductOverview_FormLoad: string = this.Product + this.ProductOverview + ApiUrls.FormLoad;
        ProductOverview_BaseLoad: string = this.Product + this.ProductOverview + ApiUrls.BaseLoad;
        ProductOverview_Save: string = this.Product + this.ProductOverview + ApiUrls.Save;

        private ProductMedia = 'Media/';
        ProductMedia_FormLoad: string = this.Product + this.ProductMedia + ApiUrls.FormLoad;
        ProductMedia_BaseLoad: string = this.Product + this.ProductMedia + ApiUrls.BaseLoad;
        ProductMedia_Save: string = this.Product + this.ProductMedia + ApiUrls.Save;

        private ProductUSP = 'USP/';
        ProductUSP_FormLoad: string = this.Product + this.ProductUSP + ApiUrls.FormLoad;
        ProductUSP_BaseLoad: string = this.Product + this.ProductUSP + ApiUrls.BaseLoad;
        ProductUSP_Save: string = this.Product + this.ProductUSP + ApiUrls.Save;

        private ProductSEOInfo = 'SEOInfo/';
        ProductSEOInfo_FormLoad: string = this.Product + this.ProductSEOInfo + ApiUrls.FormLoad;
        ProductSEOInfo_BaseLoad: string = this.Product + this.ProductSEOInfo + ApiUrls.BaseLoad;
        ProductSEOInfo_Save: string = this.Product + this.ProductSEOInfo + ApiUrls.Save;

        private ProductHighlight = 'Highlight/';
        ProductHighlight_FormLoad: string = this.Product + this.ProductHighlight + ApiUrls.FormLoad;
        ProductHighlight_BaseLoad: string = this.Product + this.ProductHighlight + ApiUrls.BaseLoad;
        ProductHighlight_Save: string = this.Product + this.ProductHighlight + ApiUrls.Save;

        private ProductItinerary = 'Itinerary/';
        ProductItinerary_FormLoad: string = this.Product + this.ProductItinerary + ApiUrls.FormLoad;
        ProductItinerary_BaseLoad: string = this.Product + this.ProductItinerary + ApiUrls.BaseLoad;
        ProductItinerary_Save: string = this.Product + this.ProductItinerary + ApiUrls.Save;
        ProductItinerary_SaveSightseeing: string = this.Product + this.ProductItinerary + 'SaveSightseeing';

        private ProductTour = 'Tour/';
        ProductTour_FormLoad: string = this.Product + this.ProductTour + ApiUrls.FormLoad;
        ProductTour_BaseLoad: string = this.Product + this.ProductTour + ApiUrls.BaseLoad;
        ProductTour_Save: string = this.Product + this.ProductTour + ApiUrls.Save;

        private ProductTourPriceVarient = 'TourPriceVarient/';
        ProductTourPriceVarient_FormLoad: string = this.Product + this.ProductTourPriceVarient + ApiUrls.FormLoad;
        ProductTourPriceVarient_BaseLoad: string = this.Product + this.ProductTourPriceVarient + ApiUrls.BaseLoad;
        ProductTourPriceVarient_Save: string = this.Product + this.ProductTourPriceVarient + ApiUrls.Save;

        private ProductTourPackage = 'TourPackage/';
        ProductTourPackage_FormLoad: string = this.Product + this.ProductTourPackage + ApiUrls.FormLoad;
        ProductTourPackage_BaseLoad: string = this.Product + this.ProductTourPackage + ApiUrls.BaseLoad;
        ProductTourPackage_Save: string = this.Product + this.ProductTourPackage + ApiUrls.Save;

        private ProductTourInventory = 'TourInventory/';
        ProductTourInventory_FormLoad: string = this.Product + this.ProductTourInventory + ApiUrls.FormLoad;
        ProductTourInventory_BaseLoad: string = this.Product + this.ProductTourInventory + ApiUrls.BaseLoad;
        ProductTourInventory_Save: string = this.Product + this.ProductTourInventory + ApiUrls.Save;

        private TourPriceVarientNotes = 'TourPriceVarientNotes/';
        TourPriceVarientNotes_FormLoad: string = this.Product + this.TourPriceVarientNotes + ApiUrls.FormLoad;
        TourPriceVarientNotes_BaseLoad: string = this.Product + this.TourPriceVarientNotes + ApiUrls.BaseLoad;
        TourPriceVarientNotes_Save: string = this.Product + this.TourPriceVarientNotes + ApiUrls.Save;

        private TourPackageOption = 'TourPackageOption/';
        TourPackageOption_FormLoad: string = this.Product + this.TourPackageOption + ApiUrls.FormLoad;
        TourPackageOption_BaseLoad: string = this.Product + this.TourPackageOption + ApiUrls.BaseLoad;
        TourPackageOption_Save: string = this.Product + this.TourPackageOption + ApiUrls.Save;

        private TourItinerary = 'TourItinerary/';
        TourItinerary_FormLoad: string = this.Product + this.TourItinerary + ApiUrls.FormLoad;
        TourItinerary_BaseLoad: string = this.Product + this.TourItinerary + ApiUrls.BaseLoad;
        TourItinerary_Save: string = this.Product + this.TourItinerary + ApiUrls.Save;

        private ItineraryDetails = 'ItineraryDetails/';
        ItineraryDetails_FormLoad: string = this.Product + this.ItineraryDetails + ApiUrls.FormLoad;
        ItineraryDetails_BaseLoad: string = this.Product + this.ItineraryDetails + ApiUrls.BaseLoad;
        ItineraryDetails_Save: string = this.Product + this.ItineraryDetails + ApiUrls.Save;

        private TourItineraryHotel = 'TourItineraryHotel/';
        TourItineraryHotel_FormLoad: string = this.Product + this.TourItineraryHotel + ApiUrls.FormLoad;
        TourItineraryHotel_BaseLoad: string = this.Product + this.TourItineraryHotel + ApiUrls.BaseLoad;
        TourItineraryHotel_Save: string = this.Product + this.TourItineraryHotel + ApiUrls.Save;

        private TourItinerarySightseeing = 'TourItinerarySightseeing/';
        TourItinerarySightseeing_FormLoad: string = this.Product + this.TourItinerarySightseeing + ApiUrls.FormLoad;
        TourItinerarySightseeing_BaseLoad: string = this.Product + this.TourItinerarySightseeing + ApiUrls.BaseLoad;
        TourItinerarySightseeing_Save: string = this.Product + this.TourItinerarySightseeing + ApiUrls.Save;

        private TourItineraryGuide = 'TourItineraryGuide/';
        TourItineraryGuide_FormLoad: string = this.Product + this.TourItineraryGuide + ApiUrls.FormLoad;
        TourItineraryGuide_BaseLoad: string = this.Product + this.TourItineraryGuide + ApiUrls.BaseLoad;
        TourItineraryGuide_Save: string = this.Product + this.TourItineraryGuide + ApiUrls.Save;

        private TourItineraryMeal = 'TourItineraryMeal/';
        TourItineraryMeal_FormLoad: string = this.Product + this.TourItineraryMeal + ApiUrls.FormLoad;
        TourItineraryMeal_BaseLoad: string = this.Product + this.TourItineraryMeal + ApiUrls.BaseLoad;
        TourItineraryMeal_Save: string = this.Product + this.TourItineraryMeal + ApiUrls.Save;

        private TourItineraryCruise = 'TourItineraryCruise/';
        TourItineraryCruise_FormLoad: string = this.Product + this.TourItineraryCruise + ApiUrls.FormLoad;
        TourItineraryCruise_BaseLoad: string = this.Product + this.TourItineraryCruise + ApiUrls.BaseLoad;
        TourItineraryCruise_Save: string = this.Product + this.TourItineraryCruise + ApiUrls.Save;

        private TourItineraryTrain = 'TourItineraryTrain/';
        TourItineraryTrain_FormLoad: string = this.Product + this.TourItineraryTrain + ApiUrls.FormLoad;
        TourItineraryTrain_BaseLoad: string = this.Product + this.TourItineraryTrain + ApiUrls.BaseLoad;
        TourItineraryTrain_Save: string = this.Product + this.TourItineraryTrain + ApiUrls.Save;

        private TourItineraryTransferSIC = 'TourItineraryTransferSIC/';
        TourItineraryTransferSIC_FormLoad: string = this.Product + this.TourItineraryTransferSIC + ApiUrls.FormLoad;
        TourItineraryTransferSIC_BaseLoad: string = this.Product + this.TourItineraryTransferSIC + ApiUrls.BaseLoad;
        TourItineraryTransferSIC_Save: string = this.Product + this.TourItineraryTransferSIC + ApiUrls.Save;

        private TourItineraryTransferPVT = 'TourItineraryTransferPVT/';
        TourItineraryTransferPVT_FormLoad: string = this.Product + this.TourItineraryTransferPVT + ApiUrls.FormLoad;
        TourItineraryTransferPVT_BaseLoad: string = this.Product + this.TourItineraryTransferPVT + ApiUrls.BaseLoad;
        TourItineraryTransferPVT_Save: string = this.Product + this.TourItineraryTransferPVT + ApiUrls.Save;

        private TourItineraryFlight = 'TourItineraryFlight/';
        TourItineraryFlight_FormLoad: string = this.Product + this.TourItineraryFlight + ApiUrls.FormLoad;
        TourItineraryFlight_BaseLoad: string = this.Product + this.TourItineraryFlight + ApiUrls.BaseLoad;
        TourItineraryFlight_Save: string = this.Product + this.TourItineraryFlight + ApiUrls.Save;

        private TourItineraryAdditionalInfo = 'TourItineraryAdditionalInfo/';
        TourItineraryAdditionalInfo_FormLoad: string = this.Product + this.TourItineraryAdditionalInfo + ApiUrls.FormLoad;
        TourItineraryAdditionalInfo_BaseLoad: string = this.Product + this.TourItineraryAdditionalInfo + ApiUrls.BaseLoad;
        TourItineraryAdditionalInfo_Save: string = this.Product + this.TourItineraryAdditionalInfo + ApiUrls.Save;

        private ProductNotify = 'TourItineraryNotify/';
        ProductNotify_FormLoad: string = this.Product + this.ProductNotify + ApiUrls.FormLoad;
        ProductNotify_BaseLoad: string = this.Product + this.ProductNotify + ApiUrls.BaseLoad;
        ProductNotify_Save: string = this.Product + this.ProductNotify + ApiUrls.Save;
    };

    static ProductLanguage = new class ProductLanguage {
        private ProductLanguage: string = ApiUrls.Prefix + 'ProductLanguage/';

        private SearchProductLanguage = 'Search/';
        Search_API: string = this.ProductLanguage + this.SearchProductLanguage;
        SearchProductLanguage_GridFormLoad: string = this.ProductLanguage + this.SearchProductLanguage + ApiUrls.GridFormLoad;
        SearchProductLanguage_GridBaseLoad: string = this.ProductLanguage + this.SearchProductLanguage + ApiUrls.GridBaseLoad;
        SearchProductLanguage_GridLoad: string = this.ProductLanguage + this.SearchProductLanguage + ApiUrls.GridLoad;

        private CreateProductLanguage = 'Create/';
        CreateProductLanguage_FormLoad: string = this.ProductLanguage + this.CreateProductLanguage + ApiUrls.FormLoad;
        CreateProductLanguage_BaseLoad: string = this.ProductLanguage + this.CreateProductLanguage + ApiUrls.BaseLoad;
        CreateProductLanguage_Save: string = this.ProductLanguage + this.CreateProductLanguage + ApiUrls.Save;

        private ProductLanguageInfo = 'Info/';
        ProductLanguageInfo_FormLoad: string = this.ProductLanguage + this.ProductLanguageInfo + ApiUrls.FormLoad;
        ProductLanguageInfo_BaseLoad: string = this.ProductLanguage + this.ProductLanguageInfo + ApiUrls.BaseLoad;
        ProductLanguageInfo_Save: string = this.ProductLanguage + this.ProductLanguageInfo + ApiUrls.Save;

        private ProductLanguageOverview = 'OverView/';
        ProductLanguageOverview_FormLoad: string = this.ProductLanguage + this.ProductLanguageOverview + ApiUrls.FormLoad;
        ProductLanguageOverview_BaseLoad: string = this.ProductLanguage + this.ProductLanguageOverview + ApiUrls.BaseLoad;
        ProductLanguageOverview_Save: string = this.ProductLanguage + this.ProductLanguageOverview + ApiUrls.Save;

        private ProductLanguageUSP = 'USP/';
        ProductLanguageUSP_FormLoad: string = this.ProductLanguage + this.ProductLanguageUSP + ApiUrls.FormLoad;
        ProductLanguageUSP_BaseLoad: string = this.ProductLanguage + this.ProductLanguageUSP + ApiUrls.BaseLoad;
        ProductLanguageUSP_Save: string = this.ProductLanguage + this.ProductLanguageUSP + ApiUrls.Save;

        private ProductLanguageSEOInfo = 'SeoInfo/';
        ProductLanguageSEOInfo_FormLoad: string = this.ProductLanguage + this.ProductLanguageSEOInfo + ApiUrls.FormLoad;
        ProductLanguageSEOInfo_BaseLoad: string = this.ProductLanguage + this.ProductLanguageSEOInfo + ApiUrls.BaseLoad;
        ProductLanguageSEOInfo_Save: string = this.ProductLanguage + this.ProductLanguageSEOInfo + ApiUrls.Save;

        private ProductLanguageHighlight = 'Highlight/';
        ProductLanguageHighlight_FormLoad: string = this.ProductLanguage + this.ProductLanguageHighlight + ApiUrls.FormLoad;
        ProductLanguageHighlight_BaseLoad: string = this.ProductLanguage + this.ProductLanguageHighlight + ApiUrls.BaseLoad;
        ProductLanguageHighlight_Save: string = this.ProductLanguage + this.ProductLanguageHighlight + ApiUrls.Save;

        private ProductLanguageItinerary = 'Itinerary/';
        ProductLanguageItinerary_FormLoad: string = this.ProductLanguage + this.ProductLanguageItinerary + ApiUrls.FormLoad;
        ProductLanguageItinerary_BaseLoad: string = this.ProductLanguage + this.ProductLanguageItinerary + ApiUrls.BaseLoad;
        ProductLanguageItinerary_Save: string = this.ProductLanguage + this.ProductLanguageItinerary + ApiUrls.Save;
        ProductLanguageItinerary_SaveSightseeing: string = this.ProductLanguage + this.ProductLanguageItinerary + 'SaveSightseeing';
    };

    static Supplier = new class Supplier {
        private Supplier: string = ApiUrls.Prefix + 'Supplier/';

        private SearchSupplier = 'Search/';
        Search_API: string = this.Supplier + this.SearchSupplier;
        SearchSupplier_GridFormLoad: string = this.Supplier + this.SearchSupplier + ApiUrls.GridFormLoad;
        SearchSupplier_GridBaseLoad: string = this.Supplier + this.SearchSupplier + ApiUrls.GridBaseLoad;
        SearchSupplier_GridLoad: string = this.Supplier + this.SearchSupplier + ApiUrls.GridLoad;

        private CreateSupplier = 'CreateSupplier/';
        CreateSupplier_FormLoad: string = this.Supplier + this.CreateSupplier + ApiUrls.FormLoad;
        CreateSupplier_BaseLoad: string = this.Supplier + this.CreateSupplier + ApiUrls.BaseLoad;
        CreateSupplier_Save: string = this.Supplier + this.CreateSupplier + ApiUrls.Save;
        CreateSupplier_FormPermission: string = this.Supplier + this.CreateSupplier + ApiUrls.FormPermissions;

        private CreateBilling = 'Billing/';
        CreateBilling_FormLoad: string = this.Supplier + this.CreateBilling + ApiUrls.FormLoad;
        CreateBilling_BaseLoad: string = this.Supplier + this.CreateBilling + ApiUrls.BaseLoad;
        CreateBilling_Save: string = this.Supplier + this.CreateBilling + ApiUrls.Save;

        private CreateUser = 'User/';
        CreateUser_FormLoad: string = this.Supplier + this.CreateUser + ApiUrls.FormLoad;
        CreateUser_BaseLoad: string = this.Supplier + this.CreateUser + ApiUrls.BaseLoad;
        CreateUser_Save: string = this.Supplier + this.CreateUser + ApiUrls.Save;

        private CreateAccounts = 'Accounts/';
        CreateAccounts_FormLoad: string = this.Supplier + this.CreateAccounts + ApiUrls.FormLoad;
        CreateAccounts_BaseLoad: string = this.Supplier + this.CreateAccounts + ApiUrls.BaseLoad;
        CreateAccounts_Save: string = this.Supplier + this.CreateAccounts + ApiUrls.Save;

        private CreatePaymentCriteria = 'PaymentCriteria/';
        CreatePaymentCriteria_FormLoad: string = this.Supplier + this.CreatePaymentCriteria + ApiUrls.FormLoad;
        CreatePaymentCriteria_BaseLoad: string = this.Supplier + this.CreatePaymentCriteria + ApiUrls.BaseLoad;
        CreatePaymentCriteria_Save: string = this.Supplier + this.CreatePaymentCriteria + ApiUrls.Save;

        private CreateCreditLimit = 'CreditLimit/';
        CreateCreditLimit_FormLoad: string = this.Supplier + this.CreateCreditLimit + ApiUrls.FormLoad;
        CreateCreditLimit_BaseLoad: string = this.Supplier + this.CreateCreditLimit + ApiUrls.BaseLoad;
        CreateCreditLimit_Save: string = this.Supplier + this.CreateCreditLimit + ApiUrls.Save;
    };

    static UserAuthorization = new class UserAuthorization {
        private UserAuthorization: string = ApiUrls.Prefix + 'UserAuthorization/';
        private SearchAuth = 'Search/';
        Search_API: string = this.UserAuthorization + this.SearchAuth;
        SearchAuth_GridFormLoad: string = this.UserAuthorization + this.SearchAuth + ApiUrls.GridFormLoad;
        SearchAuth_GridBaseLoad: string = this.UserAuthorization + this.SearchAuth + ApiUrls.GridBaseLoad;
        SearchAuth_GridLoad: string = this.UserAuthorization + this.SearchAuth + ApiUrls.GridLoad;

        private CreateAuth = 'Create/';
        CreateAuth_FormLoad: string = this.UserAuthorization + this.CreateAuth + ApiUrls.FormLoad;
        CreateAuth_BaseLoad: string = this.UserAuthorization + this.CreateAuth + ApiUrls.BaseLoad;
        CreateAuth_Save: string = this.UserAuthorization + this.CreateAuth + ApiUrls.Save;

        private AdCreateAuth = 'AdvanceAuthorization/';
        AdCreateAuth_BaseLoad: string = this.UserAuthorization + this.AdCreateAuth + ApiUrls.BaseLoad;
        AdCreateAuth_Save: string = this.UserAuthorization + this.AdCreateAuth + ApiUrls.Save;
    };

    static Traveller = new class Traveller {
        private Traveller: string = ApiUrls.Prefix + 'Traveller/';
        private SearchTraveller = 'Search/';
        Search_API: string = this.Traveller + this.SearchTraveller;
        SearchTraveller_GridFormLoad: string = this.Traveller + this.SearchTraveller + ApiUrls.GridFormLoad;
        SearchTraveller_GridBaseLoad: string = this.Traveller + this.SearchTraveller + ApiUrls.GridBaseLoad;
        SearchTraveller_GridLoad: string = this.Traveller + this.SearchTraveller + ApiUrls.GridLoad;

        private CreateTraveller = 'Create/';
        CreateTraveller_FormLoad: string = this.Traveller + this.CreateTraveller + ApiUrls.FormLoad;
        CreateTraveller_BaseLoad: string = this.Traveller + this.CreateTraveller + ApiUrls.BaseLoad;
        CreateTraveller_Save: string = this.Traveller + this.CreateTraveller + ApiUrls.Save;
    };

    static Customer = new class Customer {
        private Customer: string = ApiUrls.Prefix + 'CustomerAccount/';
        private Customer1: string = ApiUrls.Prefix + 'Customer/';
        private SearchCustomer = 'Search/';
        Search_API: string = this.Customer1 + this.SearchCustomer;
        SearchCustomer_GridFormLoad: string = this.Customer1 + this.SearchCustomer + ApiUrls.GridFormLoad;
        SearchCustomer_GridBaseLoad: string = this.Customer1 + this.SearchCustomer + ApiUrls.GridBaseLoad;
        SearchCustomer_GridLoad: string = this.Customer1 + this.SearchCustomer + ApiUrls.GridLoad;

        private CreateCustomer = 'Create/';
        CreateCustomer_FormLoad: string = this.Customer + this.CreateCustomer + ApiUrls.FormLoad;
        CreateCustomer_BaseLoad: string = this.Customer + this.CreateCustomer + ApiUrls.BaseLoad;
        CreateCustomerConvert_BaseLoad: string = this.Customer + this.CreateCustomer + 'CreateCustomer';
        CreateGetDuplicateCustomers: string = this.Customer + this.CreateCustomer + 'GetDuplicateCustomers';

        CreateCustomer_Save: string = this.Customer + this.CreateCustomer + ApiUrls.Save;
        CreateCustomer_FormPermission: string = this.Customer + this.CreateCustomer + ApiUrls.FormPermissions;

        private CreateConnect = 'Connect/';
        CreateConnect_FormLoad: string = this.Customer + this.CreateConnect + ApiUrls.FormLoad;
        CreateConnect_BaseLoad: string = this.Customer + this.CreateConnect + ApiUrls.BaseLoad;
        CreateConnect_Save: string = this.Customer + this.CreateConnect + ApiUrls.Save;

        private CreateBilling = 'BillingBank/';
        CreateBilling_FormLoad: string = this.Customer + this.CreateBilling + ApiUrls.FormLoad;
        CreateBilling_BaseLoad: string = this.Customer + this.CreateBilling + ApiUrls.BaseLoad;
        CreateBilling_Save: string = this.Customer + this.CreateBilling + ApiUrls.Save;

        private CreateCreditLimit = 'CreditLimit/';
        CreateCreditLimit_FormLoad: string = this.Customer + this.CreateCreditLimit + ApiUrls.FormLoad;
        CreateCreditLimit_BaseLoad: string = this.Customer + this.CreateCreditLimit + ApiUrls.BaseLoad;
        CreateCreditLimit_Save: string = this.Customer + this.CreateCreditLimit + ApiUrls.Save;

        private CreateSellingCriteria = 'SellingCriteria/';
        CreateSellingCriteria_FormLoad: string = this.Customer + this.CreateSellingCriteria + ApiUrls.FormLoad;
        CreateSellingCriteria_BaseLoad: string = this.Customer + this.CreateSellingCriteria + ApiUrls.BaseLoad;
        CreateSellingCriteria_Save: string = this.Customer + this.CreateSellingCriteria + ApiUrls.Save;
    };

    static ConfigureMaster = new class ConfigureMaster {
        private ConfigureMaster: string = ApiUrls.Prefix + 'ConfigureMaster/';
        private ConfigureMaster1: string = ApiUrls.Prefix + 'Configure/';

        private SearchConfig = 'SearchMaster/';
        Search_API: string = this.ConfigureMaster + this.SearchConfig;
        SearchConfig_GridFormLoad: string = this.ConfigureMaster + this.SearchConfig + ApiUrls.GridFormLoad;
        SearchConfig_GridBaseLoad: string = this.ConfigureMaster + this.SearchConfig + ApiUrls.GridBaseLoad;
        SearchConfig_GridLoad: string = this.ConfigureMaster + this.SearchConfig + ApiUrls.GridLoad;

        private CreateConfig = 'Master/';
        CreateConfig_FormLoad: string = this.ConfigureMaster1 + this.CreateConfig + ApiUrls.FormLoad;
        CreateConfig_BaseLoad: string = this.ConfigureMaster1 + this.CreateConfig + ApiUrls.BaseLoad;
        CreateConfig_Save: string = this.ConfigureMaster1 + this.CreateConfig + ApiUrls.Save;
        CreateConfig_FormPermission: string = this.ConfigureMaster1 + this.CreateConfig + ApiUrls.FormPermissions;
    };

    static WhiteLabelStep3 = new class WhiteLabelStep3 {
        private WhiteLabelStep3: string = ApiUrls.Prefix + 'WhiteLabelStep3/';

        private ModuleSequence = 'ModuleSequence/';
        ModuleSequence_FormLoad: string = this.WhiteLabelStep3 + this.ModuleSequence + ApiUrls.FormLoad;
        ModuleSequence_BaseLoad: string = this.WhiteLabelStep3 + this.ModuleSequence + ApiUrls.BaseLoad;
        ModuleSequence_Save: string = this.WhiteLabelStep3 + this.ModuleSequence + ApiUrls.Save;
        ModuleSequence_FormPermission: string = this.WhiteLabelStep3 + this.ModuleSequence + ApiUrls.FormPermissions;
    };

    static WhiteLabelStep2 = new class WhiteLabelStep2 {
        private WhiteLabelStep2: string = ApiUrls.Prefix + 'WhiteLabelStep2/';

        private FeatureAuthorization = 'FeatureAuthorization/';
        FeatureAuthorization_FormLoad: string = this.WhiteLabelStep2 + this.FeatureAuthorization + ApiUrls.FormLoad;
        FeatureAuthorization_BaseLoad: string = this.WhiteLabelStep2 + this.FeatureAuthorization + ApiUrls.BaseLoad;
        FeatureAuthorization_Save: string = this.WhiteLabelStep2 + this.FeatureAuthorization + ApiUrls.Save;
        FeatureAuthorization_FormPermission: string = this.WhiteLabelStep2 + this.FeatureAuthorization + ApiUrls.FormPermissions;

        private CommonMasterAuthorization = 'CommonMasterAuthorization/';
        CommonMasterAuthorization_FormLoad: string = this.WhiteLabelStep2 + this.CommonMasterAuthorization + ApiUrls.FormLoad;
        CommonMasterAuthorization_BaseLoad: string = this.WhiteLabelStep2 + this.CommonMasterAuthorization + ApiUrls.BaseLoad;
        CommonMasterAuthorization_Save: string = this.WhiteLabelStep2 + this.CommonMasterAuthorization + ApiUrls.Save;
    };

    static Quotation = new class Quotation {
        private Quotation: string = ApiUrls.Prefix + 'Quotation/';
        private QuotationBasic: string = ApiUrls.Prefix + 'QuotationBasic/';

        private SearchQuotation = 'Search/';
        Search_API: string = this.QuotationBasic + this.SearchQuotation;
        SearchQuotation_GridFormLoad: string = this.QuotationBasic + this.SearchQuotation + ApiUrls.GridFormLoad;
        SearchQuotation_GridBaseLoad: string = this.QuotationBasic + this.SearchQuotation + ApiUrls.GridBaseLoad;
        SearchQuotation_GridLoad: string = this.QuotationBasic + this.SearchQuotation + ApiUrls.GridLoad;
        Quotation_ExportCostingSheet: string = this.QuotationBasic + 'ExportCostingSheet';

        Quotation_Basic_FormLoad: string = this.QuotationBasic + ApiUrls.FormLoad;
        Quotation_Basic_BaseLoad: string = this.QuotationBasic + ApiUrls.BaseLoad;
        Quotation_Basic_Save: string = this.QuotationBasic + ApiUrls.Save;
        Quotation_Basic_FormPermission: string = this.QuotationBasic + ApiUrls.FormPermissions;

        private QuoteWeb = 'QuoteWeb/';
        QuoteWeb_BaseLoad: string = this.Quotation + this.QuoteWeb + ApiUrls.BaseLoad;
        QuoteWeb_ExportQuotation: string = this.Quotation + this.QuoteWeb + 'ExportQuotation';

        private OverallDiscount = 'OverallDiscount/';
        OverallDiscount_FormLoad: string = this.Quotation + this.OverallDiscount + ApiUrls.FormLoad;
        OverallDiscount_BaseLoad: string = this.Quotation + this.OverallDiscount + ApiUrls.BaseLoad;
        OverallDiscount_Save: string = this.Quotation + this.OverallDiscount + ApiUrls.Save;
        OverallDiscount_FormPermission: string = this.Quotation + this.OverallDiscount + ApiUrls.FormPermissions;

        private OverallMargin = 'OverallMargin/';
        OverallMargin_FormLoad: string = this.Quotation + this.OverallMargin + ApiUrls.FormLoad;
        OverallMargin_BaseLoad: string = this.Quotation + this.OverallMargin + ApiUrls.BaseLoad;
        OverallMargin_Save: string = this.Quotation + this.OverallMargin + ApiUrls.Save;
        OverallMargin_FormPermission: string = this.Quotation + this.OverallMargin + ApiUrls.FormPermissions;

        private FOCBuiltUp = 'FOCBuiltUp/';
        FOCBuiltUp_Save: string = this.Quotation + this.FOCBuiltUp + ApiUrls.Save;

        private Hotel = 'Hotel/';
        Hotel_FormLoad: string = this.Quotation + this.Hotel + ApiUrls.FormLoad;
        Hotel_BaseLoad: string = this.Quotation + this.Hotel + ApiUrls.BaseLoad;
        Hotel_Save: string = this.Quotation + this.Hotel + ApiUrls.Save;
        Hotel_Common_BaseLoad: string = this.Quotation + this.Hotel + 'Common/' + ApiUrls.BaseLoad;

        private HotelRoomRate = 'HotelRoomRate/';
        HotelRoomRate_FormLoad: string = this.Quotation + this.HotelRoomRate + ApiUrls.FormLoad;
        HotelRoomRate_BaseLoad: string = this.Quotation + this.HotelRoomRate + ApiUrls.BaseLoad;
        HotelRoomRate_Save: string = this.Quotation + this.HotelRoomRate + ApiUrls.Save;

        private HotelAddCharges = 'HotelAddCharges/';
        HotelAddCharges_FormLoad: string = this.Quotation + this.HotelAddCharges + ApiUrls.FormLoad;
        HotelAddCharges_BaseLoad: string = this.Quotation + this.HotelAddCharges + ApiUrls.BaseLoad;
        HotelAddCharges_Save: string = this.Quotation + this.HotelAddCharges + ApiUrls.Save;

        private HotelAddChargesAbf = 'HotelAddChargesAbf/';
        HotelAddChargesAbf_FormLoad: string = this.Quotation + this.HotelAddChargesAbf + ApiUrls.FormLoad;
        HotelAddChargesAbf_BaseLoad: string = this.Quotation + this.HotelAddChargesAbf + ApiUrls.BaseLoad;
        HotelAddChargesAbf_Save: string = this.Quotation + this.HotelAddChargesAbf + ApiUrls.Save;

        private HotelFOCPolicy = 'HotelFOCPolicy/';
        HotelFOCPolicy_FormLoad: string = this.Quotation + this.HotelFOCPolicy + ApiUrls.FormLoad;
        HotelFOCPolicy_BaseLoad: string = this.Quotation + this.HotelFOCPolicy + ApiUrls.BaseLoad;
        HotelFOCPolicy_Save: string = this.Quotation + this.HotelFOCPolicy + ApiUrls.Save;

        private Sightseeing = 'Sightseeing/';
        Sightseeing_FormLoad: string = this.Quotation + this.Sightseeing + ApiUrls.FormLoad;
        Sightseeing_BaseLoad: string = this.Quotation + this.Sightseeing + ApiUrls.BaseLoad;
        Sightseeing_Save: string = this.Quotation + this.Sightseeing + ApiUrls.Save;

        private Meal = 'Meal/';
        Meal_FormLoad: string = this.Quotation + this.Meal + ApiUrls.FormLoad;
        Meal_BaseLoad: string = this.Quotation + this.Meal + ApiUrls.BaseLoad;
        Meal_Save: string = this.Quotation + this.Meal + ApiUrls.Save;

        private TransferFitSic = 'TransferFitSic/';
        TransferFitSic_FormLoad: string = this.Quotation + this.TransferFitSic + ApiUrls.FormLoad;
        TransferFitSic_BaseLoad: string = this.Quotation + this.TransferFitSic + ApiUrls.BaseLoad;
        TransferFitSic_Save: string = this.Quotation + this.TransferFitSic + ApiUrls.Save;

        private GuideFitSic = 'GuideFitSic/';
        GuideFitSic_FormLoad: string = this.Quotation + this.GuideFitSic + ApiUrls.FormLoad;
        GuideFitSic_BaseLoad: string = this.Quotation + this.GuideFitSic + ApiUrls.BaseLoad;
        GuideFitSic_Save: string = this.Quotation + this.GuideFitSic + ApiUrls.Save;

        private TermsAndConditionstrx = 'TermsAndConditionstrx/';
        TermsAndConditionstrx_FormLoad: string = this.Quotation + this.TermsAndConditionstrx + ApiUrls.FormLoad;
        TermsAndConditionstrx_BaseLoad: string = this.Quotation + this.TermsAndConditionstrx + ApiUrls.BaseLoad;
        TermsAndConditionstrx_Save: string = this.Quotation + this.TermsAndConditionstrx + ApiUrls.Save;

        private Exclusionstrx = 'Exclusionstrx/';
        Exclusionstrx_FormLoad: string = this.Quotation + this.Exclusionstrx + ApiUrls.FormLoad;
        Exclusionstrx_BaseLoad: string = this.Quotation + this.Exclusionstrx + ApiUrls.BaseLoad;
        Exclusionstrx_Save: string = this.Quotation + this.Exclusionstrx + ApiUrls.Save;

        private ImpNotestrx = 'ImpNotestrx/';
        ImpNotestrx_FormLoad: string = this.Quotation + this.ImpNotestrx + ApiUrls.FormLoad;
        ImpNotestrx_BaseLoad: string = this.Quotation + this.ImpNotestrx + ApiUrls.BaseLoad;
        ImpNotestrx_Save: string = this.Quotation + this.ImpNotestrx + ApiUrls.Save;

        private FuncRoom = 'FuncRoom/';
        FuncRoom_FormLoad: string = this.Quotation + this.FuncRoom + ApiUrls.FormLoad;
        FuncRoom_BaseLoad: string = this.Quotation + this.FuncRoom + ApiUrls.BaseLoad;
        FuncRoom_Save: string = this.Quotation + this.FuncRoom + ApiUrls.Save;

        private FuncRoomOtherDetails = 'FuncRoomOtherDetails/';
        FuncRoomOtherDetails_FormLoad: string = this.Quotation + this.FuncRoomOtherDetails + ApiUrls.FormLoad;
        FuncRoomOtherDetails_BaseLoad: string = this.Quotation + this.FuncRoomOtherDetails + ApiUrls.BaseLoad;
        FuncRoomOtherDetails_Save: string = this.Quotation + this.FuncRoomOtherDetails + ApiUrls.Save;

        private GalaDinner = 'GalaDinner/';
        GalaDinner_FormLoad: string = this.Quotation + this.GalaDinner + ApiUrls.FormLoad;
        GalaDinner_BaseLoad: string = this.Quotation + this.GalaDinner + ApiUrls.BaseLoad;
        GalaDinner_Save: string = this.Quotation + this.GalaDinner + ApiUrls.Save;

        private GalaDinnerSampleMenu = 'GalaDinnerSampleMenu/';
        GalaDinnerSampleMenu_FormLoad: string = this.Quotation + this.GalaDinnerSampleMenu + ApiUrls.FormLoad;
        GalaDinnerSampleMenu_BaseLoad: string = this.Quotation + this.GalaDinnerSampleMenu + ApiUrls.BaseLoad;
        GalaDinnerSampleMenu_Save: string = this.Quotation + this.GalaDinnerSampleMenu + ApiUrls.Save;

        private OptionalServices = 'OptionalServices/';
        OptionalServices_FormLoad: string = this.Quotation + this.OptionalServices + ApiUrls.FormLoad;
        OptionalServices_BaseLoad: string = this.Quotation + this.OptionalServices + ApiUrls.BaseLoad;
        OptionalServices_Save: string = this.Quotation + this.OptionalServices + ApiUrls.Save;

        private Transfer = 'Transfer/';
        Transfer_FormLoad: string = this.Quotation + this.Transfer + ApiUrls.FormLoad;
        Transfer_BaseLoad: string = this.Quotation + this.Transfer + ApiUrls.BaseLoad;
        Transfer_Save: string = this.Quotation + this.Transfer + ApiUrls.Save;

        private Guide = 'Guide/';
        Guide_FormLoad: string = this.Quotation + this.Guide + ApiUrls.FormLoad;
        Guide_BaseLoad: string = this.Quotation + this.Guide + ApiUrls.BaseLoad;
        Guide_Save: string = this.Quotation + this.Guide + ApiUrls.Save;

        private TransferSic = 'TransferSic/';
        TransferSic_FormLoad: string = this.Quotation + this.TransferSic + ApiUrls.FormLoad;
        TransferSic_BaseLoad: string = this.Quotation + this.TransferSic + ApiUrls.BaseLoad;
        TransferSic_Save: string = this.Quotation + this.TransferSic + ApiUrls.Save;

        private AddFOC = 'AddFOC/';
        AddFOC_FormLoad: string = this.Quotation + this.AddFOC + ApiUrls.FormLoad;
        AddFOC_BaseLoad: string = this.Quotation + this.AddFOC + ApiUrls.BaseLoad;
        AddFOC_Save: string = this.Quotation + this.AddFOC + ApiUrls.Save;

        private GenerateQuot = 'GenerateQuot/';
        GenerateQuot_BaseLoad: string = this.Quotation + this.GenerateQuot + ApiUrls.BaseLoad;
        GenerateQuot_Save: string = this.Quotation + this.GenerateQuot + ApiUrls.Save;

        private CopyQuot = 'CopyQuot/';
        CopyQuot_BaseLoad: string = this.Quotation + this.CopyQuot + ApiUrls.BaseLoad;
        CopyQuot_Save: string = this.Quotation + this.CopyQuot + ApiUrls.Save;

        private SentToAgent = 'SentToAgent/';
        SentToAgent_BaseLoad: string = this.Quotation + this.SentToAgent + ApiUrls.BaseLoad;
        SentToAgent_Save: string = this.Quotation + this.SentToAgent + ApiUrls.Save;
        Quotation_ExportToExcel: string = this.Quotation + 'ExportToExcel';

        private Book = 'Book/';
        Book_FormLoad: string = this.Quotation + this.Book + ApiUrls.FormLoad;
        Book_BaseLoad: string = this.Quotation + this.Book + ApiUrls.BaseLoad;
        Book_Save: string = this.Quotation + this.Book + ApiUrls.Save;

        private Itinerary = 'Itinerary/';
        Itinerary_FormLoad: string = this.Quotation + this.Itinerary + ApiUrls.FormLoad;
        Itinerary_BaseLoad: string = this.Quotation + this.Itinerary + ApiUrls.BaseLoad;
        Itinerary_Save: string = this.Quotation + this.Itinerary + ApiUrls.Save;

        private Supplement = 'Supplement/';
        Supplement_FormLoad: string = this.Quotation + this.Supplement + ApiUrls.FormLoad;
        Supplement_BaseLoad: string = this.Quotation + this.Supplement + ApiUrls.BaseLoad;
        Supplement_Save: string = this.Quotation + this.Supplement + ApiUrls.Save;

    };

    static ReportWriter = new class ReportWriter {
        private prefix: string = ApiUrls.Prefix + 'ReportWriter/';

        private SearchReport = 'Search/';
        Search_API: string = this.prefix + this.SearchReport;
        SearchReport_GridFormLoad: string = this.prefix + this.SearchReport + ApiUrls.GridFormLoad;
        SearchReport_GridBaseLoad: string = this.prefix + this.SearchReport + ApiUrls.GridBaseLoad;
        SearchReport_GridLoad: string = this.prefix + this.SearchReport + ApiUrls.GridLoad;

        private CreateReport = 'Create/';
        CreateReport_FormLoad: string = this.prefix + this.CreateReport + ApiUrls.FormLoad;
        CreateReport_BaseLoad: string = this.prefix + this.CreateReport + ApiUrls.BaseLoad;
        CreateReport_Save: string = this.prefix + this.CreateReport + ApiUrls.Save;
        CreateReport_ReportTemplateHtml: string = this.prefix + this.CreateReport + 'ReportTemplateHtml';
        CreateReport_FormatReportTemplate: string = this.prefix + this.CreateReport + 'FormatReportTemplate';
    };

    static DashboardWriter = new class DashboardWriter {
        private prefix: string = ApiUrls.Prefix + 'DashboardWriter/';

        private SearchDashboard = 'Search/';
        Search_API: string = this.prefix + this.SearchDashboard;
        SearchDashboard_GridFormLoad: string = this.prefix + this.SearchDashboard + ApiUrls.GridFormLoad;
        SearchDashboard_GridBaseLoad: string = this.prefix + this.SearchDashboard + ApiUrls.GridBaseLoad;
        SearchDashboard_GridLoad: string = this.prefix + this.SearchDashboard + ApiUrls.GridLoad;

        private CreateDashboard = 'Create/';
        CreateDashboard_FormLoad: string = this.prefix + this.CreateDashboard + ApiUrls.FormLoad;
        CreateDashboard_BaseLoad: string = this.prefix + this.CreateDashboard + ApiUrls.BaseLoad;
        CreateDashboard_Save: string = this.prefix + this.CreateDashboard + ApiUrls.Save;
    };

    static Reports = new class Reports {
        private prefix: string = ApiUrls.Prefix + 'Reports/';

        GetReports: string = this.prefix + 'GetReports';
        ViewReport: string = this.prefix + 'ViewReport';
        ExportReport: string = this.prefix + 'ExportReport';
        ExportChart: string = this.prefix + 'ExportChart';
        SendEmailWithAttachment: string = this.prefix + 'SendEmailWithAttachment';
        GetChartConfiguration: string = this.prefix + 'GetChartConfiguration';
        GetStatisticsCountConfiguration: string = this.prefix + 'GetStatisticsCountConfiguration';
        GetSourceFields: string = this.prefix + 'GetSourceAPIFields';

        Search_API(category: string): string {
            return this.prefix + category + '/';
        }

        SearchReport_GridFormLoad(category: string): string {
            return this.prefix + category + '/' + ApiUrls.GridFormLoad;
        }

        SearchReport_GridBaseLoad(category: string): string {
            return this.prefix + category + '/' + ApiUrls.GridBaseLoad;
        }

        SearchReport_GridLoad(category: string): string {
            return this.prefix + category + '/' + ApiUrls.GridLoad;
        }
    };

    static Dashboard = new class Dashboard {
        private prefix: string = ApiUrls.Prefix + 'ReportDashboard/';

        Dashboard_Permission: string = this.prefix + 'FormPermissions';
        Dashboard_FormLoad: string = this.prefix + ApiUrls.FormLoad;
        Dashboard_BaseLoad: string = this.prefix + ApiUrls.BaseLoad;
        Dashboard_GetDashboards: string = this.prefix + 'GetDashboards';
        Dashboard_SavePreference: string = this.prefix + 'SavePreferences';
    };


    static ReportCorporatePermission = new class ReportCorporatePermission {
        private ReportCorporatePermission: string = ApiUrls.Prefix + 'ReportCorporatePermission/';
        ReportCorporatePermission_FormLoad: string = this.ReportCorporatePermission + ApiUrls.FormLoad;
        ReportCorporatePermission_BaseLoad: string = this.ReportCorporatePermission + ApiUrls.BaseLoad;
        ReportCorporatePermission_Save: string = this.ReportCorporatePermission + ApiUrls.Save;
    };

    static ReportPermission = new class ReportPermission {
        private ReportPermission: string = ApiUrls.Prefix + 'ReportPermission/';
        ReportPermission_FormLoad: string = this.ReportPermission + ApiUrls.FormLoad;
        ReportPermission_BaseLoad: string = this.ReportPermission + ApiUrls.BaseLoad;
        ReportPermission_Save: string = this.ReportPermission + ApiUrls.Save;
    };

    static Task = new class Task {
        private Task: string = ApiUrls.Prefix + 'MyDesk/';
        private SearchTask = 'TaskSearch/';
        Search_API: string = this.Task + this.SearchTask;
        SearchTask_GridFormLoad: string = this.Task + this.SearchTask + ApiUrls.GridFormLoad;
        SearchTask_GridBaseLoad: string = this.Task + this.SearchTask + ApiUrls.GridBaseLoad;
        SearchTask_GridLoad: string = this.Task + this.SearchTask + ApiUrls.GridLoad;

        private TaskCreate = 'TaskCreate/';
        TaskCreate_FormLoad: string = this.Task + this.TaskCreate + ApiUrls.FormLoad;
        TaskCreate_BaseLoad: string = this.Task + this.TaskCreate + ApiUrls.BaseLoad;
        TaskCreate_Save: string = this.Task + this.TaskCreate + ApiUrls.Save;
    };

    static Event = new class Event {
        private Event: string = ApiUrls.Prefix + 'MyDesk/';
        private SearchEvent = 'EventSearch/';
        Search_API: string = this.Event + this.SearchEvent;
        SearchEvent_GridFormLoad: string = this.Event + this.SearchEvent + ApiUrls.GridFormLoad;
        SearchEvent_GridBaseLoad: string = this.Event + this.SearchEvent + ApiUrls.GridBaseLoad;
        SearchEvent_GridLoad: string = this.Event + this.SearchEvent + ApiUrls.GridLoad;

        private EventCreate = 'EventCreate/';
        EventCreate_FormLoad: string = this.Event + this.EventCreate + ApiUrls.FormLoad;
        EventCreate_BaseLoad: string = this.Event + this.EventCreate + ApiUrls.BaseLoad;
        EventCreate_Save: string = this.Event + this.EventCreate + ApiUrls.Save;
    };

    static Notification = new class Notification {
        private Notification: string = ApiUrls.Prefix + 'MyDesk/';
        private SearchNotification = 'NotificationSearch/';
        Search_API: string = this.Notification + this.SearchNotification;
        SearchNotification_GridFormLoad: string = this.Notification + this.SearchNotification + ApiUrls.GridFormLoad;
        SearchNotification_GridBaseLoad: string = this.Notification + this.SearchNotification + ApiUrls.GridBaseLoad;
        SearchNotification_GridLoad: string = this.Notification + this.SearchNotification + ApiUrls.GridLoad;

        private NotificationCreate = 'NotificationCreate/';
        NotificationCreate_FormLoad: string = this.Notification + this.NotificationCreate + ApiUrls.FormLoad;
        NotificationCreate_BaseLoad: string = this.Notification + this.NotificationCreate + ApiUrls.BaseLoad;
        NotificationCreate_Save: string = this.Notification + this.NotificationCreate + ApiUrls.Save;
    };

    static Calander = new class Calander {
        private Calander: string = ApiUrls.Prefix + 'MyDesk/';
        private SearchCalander = 'Calander/';
        Search_API: string = this.Calander + this.SearchCalander;
        Calander_FormLoad: string = this.Calander + this.SearchCalander + ApiUrls.FormLoad;
        Calander_BaseLoad: string = this.Calander + this.SearchCalander + ApiUrls.BaseLoad;
    };

    static CustomForms = new class CustomForms {
        private CustomForms: string = ApiUrls.Prefix + 'CustomForms/';

        private SearchCustomForms = 'CustomForm/List/';
        Search_API: string = this.CustomForms + this.SearchCustomForms;
        SearchCustomForms_GridFormLoad: string = this.CustomForms + this.SearchCustomForms + ApiUrls.GridFormLoad;
        SearchCustomForms_GridBaseLoad: string = this.CustomForms + this.SearchCustomForms + ApiUrls.GridBaseLoad;
        SearchCustomForms_GridLoad: string = this.CustomForms + this.SearchCustomForms + ApiUrls.GridLoad;

        private SearchCustomFormData = 'CustomFormData/List/';
        SearchCustomFormData_API: string = this.CustomForms + this.SearchCustomFormData;
        SearchCustomFormData_GridFormLoad: string = this.CustomForms + this.SearchCustomFormData + ApiUrls.GridFormLoad;
        SearchCustomFormData_GridBaseLoad: string = this.CustomForms + this.SearchCustomFormData + ApiUrls.GridBaseLoad;
        SearchCustomFormData_GridLoad: string = this.CustomForms + this.SearchCustomFormData + ApiUrls.GridLoad;
        SearchCustomFormData_ExportToExcel: string = this.CustomForms + this.SearchCustomFormData + 'ExportToExcel';

        private CreateCustomForms = 'CustomForm/Add/';
        CreateCustomForms_FormLoad: string = this.CustomForms + this.CreateCustomForms + ApiUrls.FormLoad;
        CreateCustomForms_BaseLoad: string = this.CustomForms + this.CreateCustomForms + ApiUrls.BaseLoad;
        CreateCustomForms_Save: string = this.CustomForms + this.CreateCustomForms + ApiUrls.Save;
        CreateCustomForms_SavePermissions: string = this.CustomForms + this.CreateCustomForms + 'SavePermissions';
        CreateCustomForms_SaveDataPermissions: string = this.CustomForms + this.CreateCustomForms + 'SaveDataPermissions';

        private CreateCustomFormData = 'CustomFormData/Add/';
        CreateCustomFormData_FormLoad: string = this.CustomForms + this.CreateCustomFormData + ApiUrls.FormLoad;
        CreateCustomFormData_BaseLoad: string = this.CustomForms + this.CreateCustomFormData + ApiUrls.BaseLoad;
        CreateCustomFormData_Save: string = this.CustomForms + this.CreateCustomFormData + ApiUrls.Save;

        private CustomFormConfiguration = 'CustomFormConfiguration/Add/';
        CustomFormConfiguration_FormLoad: string = this.CustomForms + this.CustomFormConfiguration + ApiUrls.FormLoad;
        CustomFormConfiguration_BaseLoad: string = this.CustomForms + this.CustomFormConfiguration + ApiUrls.BaseLoad;
        CustomFormConfiguration_Save: string = this.CustomForms + this.CustomFormConfiguration + ApiUrls.Save;
        CustomFormConfiguration_SaveAsTemplate: string = this.CustomForms + this.CustomFormConfiguration + 'SaveAsTemplate';

        private CustomFormActions = 'CustomFormActions/Add/';
        CustomFormActions_FormLoad: string = this.CustomForms + this.CustomFormActions + ApiUrls.FormLoad;
        CustomFormActions_BaseLoad: string = this.CustomForms + this.CustomFormActions + ApiUrls.BaseLoad;
        CustomFormActions_Save: string = this.CustomForms + this.CustomFormActions + ApiUrls.Save;

        private CustomFormGridConfiguration = 'CustomFormGridConfiguration/';
        CustomFormGridConfiguration_FormLoad: string = this.CustomForms + this.CustomFormGridConfiguration + ApiUrls.FormLoad;
        CustomFormGridConfiguration_BaseLoad: string = this.CustomForms + this.CustomFormGridConfiguration + ApiUrls.BaseLoad;
        CustomFormGridConfiguration_Save: string = this.CustomForms + this.CustomFormGridConfiguration + ApiUrls.Save;

        private CustomFormRules = 'CustomFormRules/';
        CustomFormRules_FormLoad: string = this.CustomForms + this.CustomFormRules + ApiUrls.FormLoad;
        CustomFormRules_BaseLoad: string = this.CustomForms + this.CustomFormRules + ApiUrls.BaseLoad;
        CustomFormRules_Save: string = this.CustomForms + this.CustomFormRules + ApiUrls.Save;
    };

    static AccessControlList = new class AccessControlList {
        private AccessControlList: string = ApiUrls.Prefix + 'AccessControlList/';

        private CreateAccessControlList = 'AccessControlList/';
        AccessControlList_FormLoad: string = this.AccessControlList + this.CreateAccessControlList + ApiUrls.FormLoad;
    };

    static Asterisk = new class Asterisk {
        private Asterisk: string = ApiUrls.Prefix + 'AsteriskUsers/';

        private AsteriskSearch = 'Search/';
        AsteriskSearch_FormLoad: string = this.Asterisk + this.AsteriskSearch + ApiUrls.GridFormLoad;
        AsteriskSearch_BaseLoad: string = this.Asterisk + this.AsteriskSearch + ApiUrls.GridBaseLoad;
        AsteriskSearch_GridLoad: string = this.Asterisk + this.AsteriskSearch + ApiUrls.GridLoad;
    };

    static SupportCase = new class SupportCase {
        private SupportCase: string = ApiUrls.Prefix + 'SupportCase/';
        private SupportCaseClientCommunication: string = ApiUrls.Prefix + 'SupportCaseClientCommunication/';
        private SupportCaseInternalCommunication: string = ApiUrls.Prefix + 'SupportCaseInternalCommunication/';

        private SupportSearch = 'Search/';
        Search_API: string = this.SupportCase + this.SupportSearch;
        SupportSearch_FormLoad: string = this.SupportCase + this.SupportSearch + ApiUrls.GridFormLoad;
        SupportSearch_BaseLoad: string = this.SupportCase + this.SupportSearch + ApiUrls.GridBaseLoad;
        SupportSearch_GridLoad: string = this.SupportCase + this.SupportSearch + ApiUrls.GridLoad;

        private SupportCreate = 'Create/';
        SupportCreate_FormLoad: string = this.SupportCase + this.SupportCreate + ApiUrls.FormLoad;
        SupportCreate_BaseLoad: string = this.SupportCase + this.SupportCreate + ApiUrls.BaseLoad;
        SupportCreate_Save: string = this.SupportCase + this.SupportCreate + ApiUrls.Save;

        private SupportCaseClientCommunicationCreate = 'Create/';
        SupportCaseClientCommunicationCreate_FormLoad: string = this.SupportCaseClientCommunication + this.SupportCaseClientCommunicationCreate + ApiUrls.FormLoad;
        SupportCaseClientCommunicationCreate_BaseLoad: string = this.SupportCaseClientCommunication + this.SupportCaseClientCommunicationCreate + ApiUrls.BaseLoad;
        SupportCaseClientCommunicationCreate_Save: string = this.SupportCaseClientCommunication + this.SupportCaseClientCommunicationCreate + ApiUrls.Save;

        private SupportCaseInternalCommunicationCreate = 'Create/';
        SupportCaseInternalCommunicationCreate_FormLoad: string = this.SupportCaseInternalCommunication + this.SupportCaseInternalCommunicationCreate + ApiUrls.FormLoad;
        SupportCaseInternalCommunicationCreate_BaseLoad: string = this.SupportCaseInternalCommunication + this.SupportCaseInternalCommunicationCreate + ApiUrls.BaseLoad;
        SupportCaseInternalCommunicationCreate_Save: string = this.SupportCaseInternalCommunication + this.SupportCaseInternalCommunicationCreate + ApiUrls.Save;
    };

    static AllInOneBox = new class AllInOneBox {
        private AllInOneBox: string = ApiUrls.Prefix + 'AllInOneBox/';

        private AllInOneBoxSearch = 'Search/';
        Search_API: string = this.AllInOneBox + this.AllInOneBoxSearch;
        AllInOneBoxSearch_FormLoad: string = this.AllInOneBox + this.AllInOneBoxSearch + ApiUrls.GridFormLoad;
        AllInOneBoxSearch_BaseLoad: string = this.AllInOneBox + this.AllInOneBoxSearch + ApiUrls.GridBaseLoad;
        AllInOneBoxSearch_GridLoad: string = this.AllInOneBox + this.AllInOneBoxSearch + ApiUrls.GridLoad;

        private AllInOneBoxCreate = 'Header/';
        AllInOneBoxCreate_FormLoad: string = this.AllInOneBox + this.AllInOneBoxCreate + ApiUrls.FormLoad;
        AllInOneBoxCreate_BaseLoad: string = this.AllInOneBox + this.AllInOneBoxCreate + ApiUrls.BaseLoad;
        AllInOneBoxCreate_Save: string = this.AllInOneBox + this.AllInOneBoxCreate + ApiUrls.Save;

        private AllInOneBoxDetail = 'Details/';
        AllInOneBoxDetail_FormLoad: string = this.AllInOneBox + this.AllInOneBoxDetail + ApiUrls.FormLoad;
        AllInOneBoxDetail_BaseLoad: string = this.AllInOneBox + this.AllInOneBoxDetail + ApiUrls.BaseLoad;
        AllInOneBoxDetail_Save: string = this.AllInOneBox + this.AllInOneBoxDetail + ApiUrls.Save;
    };

    static HotelContract = new class HotelContract {
        private HotelContract: string = ApiUrls.Prefix + 'HotelContract/';

        private HotelContractSearch = 'Search/';
        Search_API: string = this.HotelContract + this.HotelContractSearch;
        HotelContractSearch_FormLoad: string = this.HotelContract + this.HotelContractSearch + ApiUrls.GridFormLoad;
        HotelContractSearch_BaseLoad: string = this.HotelContract + this.HotelContractSearch + ApiUrls.GridBaseLoad;
        HotelContractSearch_GridLoad: string = this.HotelContract + this.HotelContractSearch + ApiUrls.GridLoad;

        private HotelContractCreate = 'Create/';
        HotelContractCreate_FormLoad: string = this.HotelContract + this.HotelContractCreate + ApiUrls.FormLoad;
        HotelContractCreate_BaseLoad: string = this.HotelContract + this.HotelContractCreate + ApiUrls.BaseLoad;
        HotelContractCreate_Save: string = this.HotelContract + this.HotelContractCreate + ApiUrls.Save;

        private HotelContractPolicy = 'Policy/';
        HotelContractPolicy_FormLoad: string = this.HotelContract + this.HotelContractPolicy + ApiUrls.FormLoad;
        HotelContractPolicy_BaseLoad: string = this.HotelContract + this.HotelContractPolicy + ApiUrls.BaseLoad;
        HotelContractPolicy_Save: string = this.HotelContract + this.HotelContractPolicy + ApiUrls.Save;

        private HotelContractBlackoutPeriod = 'BlackoutPeriod/';
        HotelContractBlackoutPeriod_FormLoad: string = this.HotelContract + this.HotelContractBlackoutPeriod + ApiUrls.FormLoad;
        HotelContractBlackoutPeriod_BaseLoad: string = this.HotelContract + this.HotelContractBlackoutPeriod + ApiUrls.BaseLoad;
        HotelContractBlackoutPeriod_Save: string = this.HotelContract + this.HotelContractBlackoutPeriod + ApiUrls.Save;

        private HotelContractMealPrice = 'MealPrice/';
        HotelContractMealPrice_FormLoad: string = this.HotelContract + this.HotelContractMealPrice + ApiUrls.FormLoad;
        HotelContractMealPrice_BaseLoad: string = this.HotelContract + this.HotelContractMealPrice + ApiUrls.BaseLoad;
        HotelContractMealPrice_Save: string = this.HotelContract + this.HotelContractMealPrice + ApiUrls.Save;

        private HotelContractSurchargeComulsory = 'SurchargeComulsory/';
        HotelContractSurchargeComulsory_FormLoad: string = this.HotelContract + this.HotelContractSurchargeComulsory + ApiUrls.FormLoad;
        HotelContractSurchargeComulsory_BaseLoad: string = this.HotelContract + this.HotelContractSurchargeComulsory + ApiUrls.BaseLoad;
        HotelContractSurchargeComulsory_Save: string = this.HotelContract + this.HotelContractSurchargeComulsory + ApiUrls.Save;
    };

    static HotelRoom = new class HotelRoom {
        private HotelRoom: string = ApiUrls.Prefix + 'HotelRoom/';

        private HotelRoomSearch = 'Search/';
        Search_API: string = this.HotelRoom + this.HotelRoomSearch;
        HotelRoomSearch_FormLoad: string = this.HotelRoom + this.HotelRoomSearch + ApiUrls.GridFormLoad;
        HotelRoomSearch_BaseLoad: string = this.HotelRoom + this.HotelRoomSearch + ApiUrls.GridBaseLoad;
        HotelRoomSearch_GridLoad: string = this.HotelRoom + this.HotelRoomSearch + ApiUrls.GridLoad;

        private HotelRoomCreate = 'Create/';
        HotelRoomCreate_FormLoad: string = this.HotelRoom + this.HotelRoomCreate + ApiUrls.FormLoad;
        HotelRoomCreate_BaseLoad: string = this.HotelRoom + this.HotelRoomCreate + ApiUrls.BaseLoad;
        HotelRoomCreate_Save: string = this.HotelRoom + this.HotelRoomCreate + ApiUrls.Save;

        private HotelRoomRate = 'Rate/';
        HotelRoomRate_FormLoad: string = this.HotelRoom + this.HotelRoomRate + ApiUrls.FormLoad;
        HotelRoomRate_BaseLoad: string = this.HotelRoom + this.HotelRoomRate + ApiUrls.BaseLoad;
        HotelRoomRate_Save: string = this.HotelRoom + this.HotelRoomRate + ApiUrls.Save;
    };

    static Plans = new class Plans {
        private Plans: string = ApiUrls.Prefix + 'ManagePlan/';

        private PlansSearch = 'SearchPlan/';
        Search_API: string = this.Plans + this.PlansSearch;
        PlansSearch_FormLoad: string = this.Plans + this.PlansSearch + ApiUrls.GridFormLoad;
        PlansSearch_BaseLoad: string = this.Plans + this.PlansSearch + ApiUrls.GridBaseLoad;
        PlansSearch_GridLoad: string = this.Plans + this.PlansSearch + ApiUrls.GridLoad;

        private PlansCreate = 'Create/';
        PlansCreate_FormLoad: string = this.Plans + this.PlansCreate + ApiUrls.FormLoad;
        PlansCreate_BaseLoad: string = this.Plans + this.PlansCreate + ApiUrls.BaseLoad;
        PlansCreate_Save: string = this.Plans + this.PlansCreate + ApiUrls.Save;

        private PlansFeatureGroupPermissions = 'PlanFeatureGroupPermissions/';
        PlansFeatureGroupPermissions_FormLoad: string = this.Plans + this.PlansFeatureGroupPermissions + ApiUrls.FormLoad;
        PlansFeatureGroupPermissions_BaseLoad: string = this.Plans + this.PlansFeatureGroupPermissions + ApiUrls.BaseLoad;
        PlansFeatureGroupPermissions_Save: string = this.Plans + this.PlansFeatureGroupPermissions + ApiUrls.Save;

        private PlanFeatureMasterPermissions = 'PlanFeatureMasterPermissions/';
        PlanFeatureMasterPermissions_FormLoad: string = this.Plans + this.PlanFeatureMasterPermissions + ApiUrls.FormLoad;
        PlanFeatureMasterPermissions_BaseLoad: string = this.Plans + this.PlanFeatureMasterPermissions + ApiUrls.BaseLoad;
        PlanFeatureMasterPermissions_Save: string = this.Plans + this.PlanFeatureMasterPermissions + ApiUrls.Save;

        private ScreenPermissions = 'ScreenPermissions/';
        ScreenPermissions_FormLoad: string = this.Plans + this.ScreenPermissions + ApiUrls.FormLoad;
        ScreenPermissions_BaseLoad: string = this.Plans + this.ScreenPermissions + ApiUrls.BaseLoad;
        ScreenPermissions_Save: string = this.Plans + this.ScreenPermissions + ApiUrls.Save;

        private TabPermissions = 'TabPermissions/';
        TabPermissions_FormLoad: string = this.Plans + this.TabPermissions + ApiUrls.FormLoad;
        TabPermissions_BaseLoad: string = this.Plans + this.TabPermissions + ApiUrls.BaseLoad;
        TabPermissions_Save: string = this.Plans + this.TabPermissions + ApiUrls.Save;

        private TabSectionPermissions = 'TabSectionPermissions/';
        TabSectionPermissions_FormLoad: string = this.Plans + this.TabSectionPermissions + ApiUrls.FormLoad;
        TabSectionPermissions_BaseLoad: string = this.Plans + this.TabSectionPermissions + ApiUrls.BaseLoad;
        TabSectionPermissions_Save: string = this.Plans + this.TabSectionPermissions + ApiUrls.Save;
    };
}


