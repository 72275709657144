export class AppSettings {

	// public static readonly ApiBaseUrl: string = "http://apiqa.techpure.co.uk/";
	public static readonly ApiBaseUrl: string = "https://apilive.techpure.co.uk/";
	//public static readonly ApiBaseUrl: string = 'http://localhost:3545/';

	public static readonly AWSurl: string = "https://cdn.techpure.co.uk/";
	public static readonly AWSurlNoSlash: string = "https://cdn.techpure.co.uk";
	public static readonly XToken: string = "F6BF25A5-9A37-4C83-A2D9-5C706FE4D6FB";
	public static readonly XAuthType: string = "1";

	/* Test free api key */
	//public static readonly Emailaccesskey: string = "89933091f6ab7ce48764fc870b33956d";
	//public static readonly Phoneaccesskey: string = "d5044bb148e3277f40e442162f4b213e";

	/* Paid api key */
	public static readonly Emailaccesskey: string = "3b4692b4f50cb1fae43a8e15e9f78ba4";
	public static readonly Phoneaccesskey: string = "417471b1f52c0283da2ed906becb61ee";


	private static _browserUrl: string = window.location.origin;
	public static get BrowserUrl(): string {
		if (this._browserUrl == "http://localhost:4200" || this._browserUrl == "http://localhost:4300") {
			// this._browserUrl = "http://flqa.techpure.co.uk";
			this._browserUrl = "https://agent.blueplanetdestination.com";
			// this._browserUrl = "https://admin.heyago.com";
			 // this._browserUrl = "https://erp.flamingotravels.co.in";
			// this._browserUrl = "http://tuqa.techpure.co.uk";
			//this._browserUrl = "https://travelzunlimited.com";
			  // this._browserUrl = "https://erp.travelzunlimited.com";
			//this._browserUrl = "http://erpqa.techpure.co.uk";
			 // this._browserUrl = "https://erp.techpure.co.uk";
		}
		else if(this._browserUrl == "http://bi.techpure.co.uk"){
			this._browserUrl = "https://erp.techpure.co.uk";
		}
		return this._browserUrl;
	}
}
