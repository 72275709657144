import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tree-chart',
  templateUrl: './tree-chart.component.html',
  styleUrls: ['./tree-chart.component.css']
})
export class TreeChartComponent implements OnInit {

  @Input('data') data: any;
  @Output('drop') drop = new EventEmitter<any>();
  treeData: any = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(item) {
    if (item.data && item.data.currentValue) {
      this.treeData = item.data.currentValue;
    }
  }

  dropNode(event) {

  }

}
