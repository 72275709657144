import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule, Routes, ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../../../core/services/api.service';

@Component({
  selector: 'app-common-summary',
  templateUrl: './common-summary.component.html',
  styleUrls: ['./common-summary.component.css']
})
export class CommonSummaryComponent implements OnInit {

  @Input('summaryData') summaryData: { [index: string]: any } = {};
  @Output() goBack = new EventEmitter();
  //summaryData: { [index: string]: any } = {};

  constructor(
    private router: Router,
    private httpService: ApiService
  ) { }

  ngOnInit() {
    this.getFormSummary();
  }

  getFormSummary() {
    /* $('.mainright').removeClass("hidesummary");
    var data = {
      Type: "FormSummary",
      SrNo: this.recordSrno
    };
    this.httpService.post(this.apiUrl, data)
      .subscribe(res => {
        if (res.ResponseType == 1) {
          if (this.summaryData) {
            if (this.summaryData['InclusionIconClass'] != undefined) {
              this.summaryData['InclusionIconClass'] = this.summaryData['InclusionIconClass'].split(',');
            }
          }
        }
      }); */

      if (this.summaryData) {
        if (this.summaryData['InclusionIconClass'] != undefined) {
          this.summaryData['InclusionIconClass'] = this.summaryData['InclusionIconClass'].split(',');
        }
      }
  }

  refreshForm() {
    /* $('#tag-id').val('');
    $('.semanticDrp').dropdown();
    $('.semanticDrp').dropdown({ forceSelection: false });
    if (this.recordSrno == 0 || this.recordSrno == undefined) {
      this.router.navigate([this.backPath, { srno: 0, tablesrno: 0 }], { skipLocationChange: true });
    }
    if (this.recordSrno > 0) {
      this.router.navigate([this.backPath, { srno: this.recordSrno, tablesrno: 0 }], { skipLocationChange: true });
    } */

    this.goBack.emit();
  }

  /* createNewForm() {
    $('.semanticDrp').dropdown();
    $('.semanticDrp').dropdown({ forceSelection: false });
    $('.mainright').addClass("hidesummary");
    this.recordSrno = 0;
    //this.tablesr = 0;
    this.router.navigate([this.backPath, { srno: 0, tablesrno: 0 }], { skipLocationChange: true });
  } */

}
