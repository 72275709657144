import { trigger, state, keyframes, style, animate, transition } from '@angular/animations'; 

export const myAwesomeAnimation = trigger('myAwesomeAnimation', [
    state('showin', style({transform: 'inherit'})),
   	state('tabin', style({transform: 'translateX(0)'})),
    state('show', style({
      opacity: 1,
      display: 'block'
    })),
    state('hide',   style({
      opacity: 0,
      display: 'none'
    })),
    transition('void => tabin', [
      animate(300, keyframes([
        style({opacity: 0,offset: 0, transform: 'translateX(+100%)'}),
        style({opacity: 1,offset: 1, transform: 'translateX(0)'}),
      ]))
    ]),
    transition('tabin => void', [
      animate(300, keyframes([
        style({opacity: 0,offset: 0, transform: 'translateX(+100%)'}),
        style({opacity: 1,offset: 1, transform: 'translateX(0)'}),
      ]))
    ]),
    transition('void => *', [
      animate(700, keyframes([
        style({opacity: 0,offset: 0}),
        style({opacity: 1,offset: 1}),
      ]))
    ]),
    transition('* => void', [
      animate(700, keyframes([
        style({opacity: 0,offset: 0}),
        style({opacity: 1,offset: 1})
      ]))
    ]),
    transition('show => hide', animate('300ms ease-out')),
    transition('hide => show', animate('300ms ease-in')),
   	transition(':enter', [
      style({ opacity: 0 }),
      animate('0.8s', style({ opacity: 1 }))
  	]),
]);