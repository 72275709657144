import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiUrls } from '../../constants/ApiUrls';
import { ApiService } from '../../../core/services/api.service';
import { CommonUtils } from '../../utility/common-utils';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.css']
})
export class SearchHeaderComponent implements OnInit {

  @Input('navUrl') navUrl: string;
  @Input('isClose') isClose: string;
  @Input('isAddPermisson') isAddPermisson: any;
  @Input('getTagsURL') getTagsURL: string;
  @Input('selectedView') selectedView: string;
  @Input('CustomFormSrno') CustomFormSrno: any;
  @Input() showAdvancedSearch: boolean = true;


  my_tags: any = [];
  isModel: any = false;
  isModelClass:any = false;
  view_popup: any = false;
  selected_view: any = 'default_view';
  search_tag_holder: any = [];
  selected_search_tag: any = [];
  isAdd: any = true;

  @Output('toggleChange')
  toggleChange = new EventEmitter<any>();

  @Output('changeClick')
  changeViewClick = new EventEmitter<any>();

  @Output('defaultClick')
  defaultViewClick = new EventEmitter<any>();

  @Output('applyTags')
  applyTags = new EventEmitter<any>();

  @Output('refresh')
  refreshClick = new EventEmitter<any>();


  constructor(private httpService: ApiService, private router: Router) { }

  ngOnInit() {
    //TODO: After utilization remove this code and make common code
    if (this.navUrl == 'agent/createagent') {
      this.getPermission();
    }
    //TODO: After utilization remove this code and make common code
    if (this.navUrl == 'agentsignup/create') {
      this.isAdd = false;
    }
  }

  ngOnChanges(item) {
    if (item.selectedView && item.selectedView.currentValue != undefined) {
      this.selected_view = item.selectedView.currentValue;
    }
    if (item.isClose && item.isClose.currentValue != undefined) {
      this.isModelClass = item.isClose.currentValue;
    }
  }

  toggleModal() {
    this.isModelClass = !this.isModelClass;
    this.isModel = !this.isModel;
    this.toggleChange.emit(this.isModel);
    CommonUtils.InitDropdown();
  }

  openViewPopup(e) {
    e.stopPropagation();
    if (this.view_popup == true) {
      this.view_popup = false
    }
    else {
      this.view_popup = true;
    }
  }

  defaultView() {
    this.view_popup = false;
    this.defaultViewClick.emit();
  }

  changeView(event) {
    this.view_popup = false;
    this.changeViewClick.emit(event);
  }


  getSearchTags = (text: string) => {
    var tagObj = {
      "Type": "Conditional",
      "Control": "TagSuggestion",
      "Value": text
    }
    return this.httpService
      .post(this.getTagsURL, tagObj)
      .pipe(map(res => res.Data.Table));
  }

  onTagAdd(event) {
    let tag_text = event.display;
    this.selected_search_tag.push(tag_text);
    this.search_tag_holder.push(event.value);
    let arrStr = this.search_tag_holder.join();
    var nType = {
      tagId: arrStr,
      tagString: this.selected_search_tag
    }
    this.applyTags.emit(nType);
  }

  onTagRemove(event) {
    for (let i in this.search_tag_holder) {
      if (this.search_tag_holder[i] == event.value) {
        this.search_tag_holder.splice(i, 1);
      }
    }
    let arrStr = this.search_tag_holder.join();
    for (let i in this.selected_search_tag) {
      if (this.selected_search_tag[i] == event.display) {
        this.selected_search_tag.splice(i, 1);
      }
    }
    var nType = {
      tagId: arrStr,
      tagString: this.selected_search_tag
    }
    this.applyTags.emit(nType);
  }

  callcreatemode() {
    if (this.CustomFormSrno) {
      this.router.navigate([this.navUrl, { srno: 0, csrno: this.CustomFormSrno }], { skipLocationChange: true });
    } else {
      this.router.navigate([this.navUrl, { srno: 0, tablesrno: 0 }], { skipLocationChange: true });
    }
  }

  refresh() {
    $("input[id='tag-id']").val("");
    this.search_tag_holder = [];
    this.selected_search_tag = [];
    this.my_tags = [];
    this.refreshClick.emit();
  }

  getPermission() {
    this.httpService.post(ApiUrls.Agent.CreateAgent_FormPermission).subscribe(res => {
      if (res.ResponseType == 1) {
        let permission = res.Data.Table;
        if (permission) {
          let sel = permission.filter(d => d.FormTab_Srno == "F003-01");
          if (sel.length > 0) {
            this.isAdd = sel[0].AddRights;
          } else {
            this.isAdd = true;
          }
        };
      }
    });
  }
}
