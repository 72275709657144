import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchMaster'
})
export class SearchMasterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || !args) {
      return value;
    }
    if (value[0].Value) {
      return value.filter(value => value.Value.toLowerCase().indexOf(args.toLowerCase()) !== -1);
    }
    if (value[0].Title) {
      return value.filter(value => value.Title.toLowerCase().indexOf(args.toLowerCase()) !== -1);
    }
    if (value[0].Name) {
      return value.filter(value => value.Name.toLowerCase().indexOf(args.toLowerCase()) !== -1);
    }
  }

}
