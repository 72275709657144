import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-access-denied-chkbox',
  templateUrl: './access-denied-chkbox.component.html',
  styleUrls: ['./access-denied-chkbox.component.css']
})
export class AccessDeniedChkboxComponent implements OnInit {
  form: any;
  //modules: any;

  @Input('loadData') loadData: any;
  @Input() modules: any[];
  @Input() filterModuleCode: any;
  @Input() groupTitle: any;
  @Input() childTitle: any;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      Modules: this.fb.array([])
    });
  }


  getChildFormData() {
    if (this.form.valid) {
      return this.form.value;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modules && changes.modules.currentValue) {
      for (let i = 0; i < this.modules.length; i++) {
        this.addModuleGroup();
        this.modules[i].Children.forEach(element => {
          this.addFormGroup(i);
        });
      }

      this.form.get("Modules").patchValue(this.modules);
    }
  }

  getModuleGroup() {
    return this.fb.group({
      ModuleName: [""],
      ModuleCode: [""],     
      Granted: [false],
      Denied: [false],
      Children: this.fb.array([])
    });
  }

  getFormGroup() {
    return this.fb.group({
      ScreenCode: [""],
      ScreenName: [""],
      FormSrno: [""],
      FormName: [""],
      FormTabSrno: [""],
      TabHeader: [""],
      Granted: [false],
      Denied: [false]
    });
  }

  addModuleGroup() {
    let items = this.form.get("Modules") as FormArray;

    items.push(this.getModuleGroup());

    let itemFormGroup = items.at(items.length - 1);

    itemFormGroup.get("Granted").valueChanges.subscribe(val => {
      this.onGrantedAllOrDeniedAllChanged(
        itemFormGroup,
        val,
        "Granted",
        "Denied"
      );
    });

    // itemFormGroup.get("Denied").valueChanges.subscribe(val => {
    //   this.onGrantedAllOrDeniedAllChanged(
    //     itemFormGroup,
    //     val,
    //     "Denied",
    //     "Granted"
    //   );
    // });
  }

  addFormGroup(index) {
    let items = this.form.get("Modules") as FormArray;
    let children = items.at(index).get("Children") as FormArray;

    children.push(this.getFormGroup());

    let itemFormGroup = children.at(children.length - 1);

    itemFormGroup.get("Granted").valueChanges.subscribe(val => {
      this.onGrantedOrDeniedChanged(
        itemFormGroup,
        val,
        "Granted",
        "Denied"
      );
    });

    itemFormGroup.get("Denied").valueChanges.subscribe(val => {
      this.onGrantedOrDeniedChanged(
        itemFormGroup,
        val,
        "Denied",
        "Granted"
      );
    });
  }

  onGrantedOrDeniedChanged(
    itemFormGroup: AbstractControl,
    val,
    source,
    target
  ) {
    if (val) {
      itemFormGroup.get(target).setValue(false, { emitEvent: false });
    }

    let targetCount = 0;
    let sourceCount = 0;
    let children = itemFormGroup.parent as FormArray;

    children.controls.forEach(element => {
      if (element.get("Granted").value) sourceCount++;
      if (element.get("Denied").value) targetCount++;
    });

    if (children.length == sourceCount) {
      //Checked
      itemFormGroup.parent.parent
        .get("Granted")
        .setValue(true, { emitEvent: false });

      // itemFormGroup.parent.parent
      //   .get(target)
      //   .setValue(false, { emitEvent: false });
    } else {
      //UnChecked	OR Indeterminate


      itemFormGroup.parent.parent
        .get("Granted")
        .setValue(false, { emitEvent: false });

      // if (children.length !== sourceCount && sourceCount > 0) {
      //   itemFormGroup.parent.parent
      //     .get(source)
      //     .setValue("check-indeterminate", { emitEvent: false });
      // } else if (sourceCount == 0) {
      //   itemFormGroup.parent.parent
      //     .get(source)
      //     .setValue(false, { emitEvent: false });
      // }

      // if (children.length !== targetCount && targetCount > 0) {
      //   itemFormGroup.parent.parent
      //     .get(target)
      //     .setValue("check-indeterminate", { emitEvent: false });
      // } else if (targetCount == 0) {
      //   itemFormGroup.parent.parent
      //     .get(target)
      //     .setValue(false, { emitEvent: false });
      // }
    }
  }

  onGrantedAllOrDeniedAllChanged(
    itemFormGroup: AbstractControl,
    val,
    source,
    target
  ) {
    if (val) {
      itemFormGroup.get(target).setValue(false, { emitEvent: false });
    }

    let children = itemFormGroup.get("Children") as FormArray;

    children.controls.forEach(element => {
      element.get(source).setValue(val, { emitEvent: false });
      if (val) element.get(target).setValue(!val, { emitEvent: false });
    });
  }
}