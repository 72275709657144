import { Injectable } from '@angular/core';


@Injectable()
export class CacheService {

    getCorporate(): number {
        return this.get('corporate');
    }

    setCorporate(val: number) {
        this.save('corporate', val);
    }

    getClientId(): string {
        return this.get('clientid');
    }

    setClientId(val: string) {
        this.save('clientid', val);
    }

    getTokenId(): string {
        return this.get('tokenid');
    }

    setTokenId(val: string) {
        this.save('tokenid', val);
    }

    getUsername(): number {
        return this.get('username');
    }

    setUsername(val: number) {
        this.save('username', val);
    }

    getEmail(): string {
        return this.get('email');
    }

    setIsTemporaryToken(val: boolean) {
        this.save('istemporarytoken', val);
    }

    getIsTemporaryToken(): string {
        return this.get('istemporarytoken');
    }

    setEmail(val: string) {
        this.save('email', val);
    }

    getSrno(): string {
        return this.get('Srno');
    }

    setSrno(val: string) {
        this.save('Srno', val);
    }

    getToken(): string {
        return this.get('jwtToken');
    }

    setToken(val: string) {
        this.save('jwtToken', val);
    }

    getRefreshToken(): string {
        return this.get('refreshToken');
    }

    setRefreshToken(val: string) {
        this.save('refreshToken', val);
    }

    getTheme(): string {
        return this.get('theme');
    }

    setTheme(val: string) {
        this.save('theme', val);
    }

    getFavicon(): string {
        return this.get('favicon');
    }

    setFavicon(val: string) {
        this.save('favicon', val);
    }

    getPageTitle(): string {
        return this.get('title');
    }

    setPageTitle(val: string) {
        this.save('title', val);
    }

    getProfilePic(): string {
        return this.get('profilePic');
    }

    setProfilePic(val: string) {
        this.save('profilePic', val);
    }

    getMenuPosition(): string {
        return this.get('menuPosition');
    }

    setMenuPosition(val: string) {
        this.save('menuPosition', val);
    }

    getFooterText(): string {
        return this.get('footerText');
    }

    setFooterText(val: string) {
        this.save('footerText', val);
    }

    getNickName(): string {
        return this.get('nickName');
    }

    setNickName(val: string) {
        this.save('nickName', val);
    }

    getUserType(): string {
        return this.get('usertype');
    }

    setUserType(val: string) {
        this.save('usertype', val);
    }

    getRoleName(): string {
        return this.get('roleName');
    }

    setRoleName(val: string) {
        this.save('roleName', val);
    }

    getLogoUrl(): string {
        return this.get('logoUrl');
    }

    setLogoUrl(val: string) {
        this.save('logoUrl', val);
    }

    getPrintLogoUrl(): string {
        return this.get('printlogoUrl');
    }

    setPrintLogoUrl(val: string) {
        this.save('printlogoUrl', val);
    }

    getTokenExpires(): string {
        return this.get('expires');
    }

    setTokenExpires(val: string) {
        this.save('expires', val);
    }

    getIndustry(): string {
        return this.get('industry');
    }

    setIndustry(val: string) {
        this.save('industry', val);
    }

    getUIPreference(): object {
        let uiPref = this.get('UIPreference');

        return uiPref ? JSON.parse(uiPref) : null;
    }

    setUIPreference(val: object) {
        this.save('UIPreference', JSON.stringify(val));
    }

    getPermissions() {
        let ary = this.get('permissions');
        return ary ? JSON.parse(ary) : [];
    }

    setPermissions(val: any) {
        this.save('permissions', JSON.stringify(val));
    }

    removeAll() {
        window.localStorage.clear();
    }

    getPageSize() {
        return 25;
    }

    private get(key: string) {
        return window.localStorage[key];
    }

    private save(key: string, value: any) {
        window.localStorage[key] = value;
    }

    private remove(key: string) {
        window.localStorage.removeItem(key);
    }
}