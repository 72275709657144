import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-view-create-update-delete-chkbox',
  templateUrl: './view-create-update-delete-chkbox.component.html',
  styleUrls: ['./view-create-update-delete-chkbox.component.css']
})
export class ViewCreateUpdateDeleteChkboxComponent implements OnInit {
  form: any;
  //modules: any;

  @Input() modules: any;
  @Input('loadData') loadData: any;
  @Input() filterModuleCode: any;
  @Input() filterSection: any;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      Modules: this.fb.array([])
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modules && changes.modules.currentValue) {
      for (let i = 0; i < this.modules.length; i++) {
        this.addModuleGroup();
        this.modules[i].Children.forEach(element => {
          this.addTabGroup(i);
        });
      }

      this.form.get("Modules").patchValue(this.modules);
    }
  }

  getModuleGroup() {
    return this.fb.group({
      ModuleCode: [""],
      ModuleName: [""],
      ViewRights: [false],
      AddRights: [false],
      UpdateRights: [false],
      DeleteRights: [false],
      Children: this.fb.array([])
    });
  }

  getTabGroup() {
    return this.fb.group({
      ScreenCode: [""],
      FormSrno: [""],
      FormName: [""],
      FormTabSrno: [""],
      TabHeader: [""],
      FormTabSectionSrno: [""],
      SectionName: [""],
      ViewRights: [false],
      AddRights: [false],
      UpdateRights: [false],
      DeleteRights: [false]
    });
  }

  addModuleGroup() {
    let items = this.form.get("Modules") as FormArray;

    items.push(this.getModuleGroup());

    let itemFormGroup = items.at(items.length - 1);

    itemFormGroup.get("ViewRights").valueChanges.subscribe(val => {
      this.onGrantedAllOrDeniedAllChanged(
        itemFormGroup,
        val,
        "ViewRights"
      );
    });

    itemFormGroup.get("AddRights").valueChanges.subscribe(val => {
      this.onGrantedAllOrDeniedAllChanged(
        itemFormGroup,
        val,
        "AddRights"
      );
    });

    itemFormGroup.get("UpdateRights").valueChanges.subscribe(val => {
      this.onGrantedAllOrDeniedAllChanged(
        itemFormGroup,
        val,
        "UpdateRights"
      );
    });

    itemFormGroup.get("DeleteRights").valueChanges.subscribe(val => {
      this.onGrantedAllOrDeniedAllChanged(
        itemFormGroup,
        val,
        "DeleteRights"
      );
    });
  }

  addTabGroup(index) {
    let items = this.form.get("Modules") as FormArray;
    let tabs = items.at(index).get("Children") as FormArray;

    tabs.push(this.getTabGroup());

    let itemFormGroup = tabs.at(tabs.length - 1);

    itemFormGroup.get("ViewRights").valueChanges.subscribe(val => {
      this.onGrantedOrDeniedChanged(itemFormGroup, val, "ViewRights");
    });

    itemFormGroup.get("AddRights").valueChanges.subscribe(val => {
      this.onGrantedOrDeniedChanged(itemFormGroup, val, "AddRights");
    });

    itemFormGroup.get("UpdateRights").valueChanges.subscribe(val => {
      this.onGrantedOrDeniedChanged(itemFormGroup, val, "UpdateRights");
    });

    itemFormGroup.get("DeleteRights").valueChanges.subscribe(val => {
      this.onGrantedOrDeniedChanged(itemFormGroup, val, "DeleteRights");
    });
  }

  onGrantedOrDeniedChanged(itemFormGroup: AbstractControl, val, source) {
    // if (val) {
    // 	itemFormGroup.get(target).setValue(false, { emitEvent: false });
    // }

    let targetCount = 0;
    let sourceCount = 0;
    let tabs = itemFormGroup.parent as FormArray;

    tabs.controls.forEach(element => {
      if (element.get("ViewRights").value && element.get("AddRights").value && element.get("UpdateRights").value && element.get("DeleteRights").value) {
        sourceCount++;
      }
    });

    if (tabs.length == sourceCount) {
      //Checked
      itemFormGroup.parent.parent
        .get("ViewRights")
        .setValue(true, { emitEvent: false });

      // itemFormGroup.parent.parent
      // 	.get(target)
      // 	.setValue(false, { emitEvent: false });
    } else {
      //UnChecked	OR Indeterminate
      itemFormGroup.parent.parent
        .get("ViewRights")
        .setValue(false, { emitEvent: false });

      // if (tabs.length !== sourceCount && sourceCount > 0) {
      //   itemFormGroup.parent.parent
      //     .get(source)
      //     .setValue("check-indeterminate", { emitEvent: false });
      // } else if (sourceCount == 0) {
      //   itemFormGroup.parent.parent
      //     .get(source)
      //     .setValue(false, { emitEvent: false });
      // }

      // if (tabs.length !== targetCount && targetCount > 0) {
      // 	itemFormGroup.parent.parent
      // 		.get(target)
      // 		.setValue("check-indeterminate", { emitEvent: false });
      // } else if (targetCount == 0) {
      // 	itemFormGroup.parent.parent
      // 		.get(target)
      // 		.setValue(false, { emitEvent: false });
      // }
    }
  }

  onGrantedAllOrDeniedAllChanged(
    itemFormGroup: AbstractControl,
    val,
    source
  ) {
    // if (val) {
    // 	itemFormGroup.get(target).setValue(false, { emitEvent: false });
    // }

    let tabs = itemFormGroup.get("Children") as FormArray;

    tabs.controls.forEach(element => {
      element.get("ViewRights").setValue(val, { emitEvent: false });
      element.get("AddRights").setValue(val, { emitEvent: false });
      element.get("UpdateRights").setValue(val, { emitEvent: false });
      element.get("DeleteRights").setValue(val, { emitEvent: false });
    });
  }

  getChildFormData() {
    if (this.form.valid) {
      return this.form.value;
    }
    return false;
  }
}
