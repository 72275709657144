import { Component, OnInit, Input } from '@angular/core';
import { ModelDialogComponent } from '../../../shared/components/model-dialog/model-dialog.component';
import { DialogService, DialogComponent } from 'ng2-bootstrap-modal';
import { DialogModel } from '../../../shared/models/dialog.model';
import { CommonUtils } from '../../../shared/utility/common-utils';
import { ResponseType } from '../../../shared/enums/enum';
import { ApiService } from '../../services/api.service';
import { ApiUrls } from '../../../shared/constants/ApiUrls';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-lock-unlock-user',
  templateUrl: './lock-unlock-user.component.html',
  styleUrls: ['./lock-unlock-user.component.css']
})
export class LockUnlockUserComponent extends ModelDialogComponent<any, boolean> implements DialogModel<any>, OnInit {

  lockUserModal: any;
  err_message: any;
  userData: any = [];
  isLockEnable: any = true;
  isUnLockEnable: any = true;
  minutesData: any = [
    { Id: 5, Value: "5" },
    { Id: 10, Value: "10" },
    { Id: 20, Value: "20" },
    { Id: 30, Value: "30" },
    { Id: 60, Value: "60" },
    { Id: 120, Value: "120" }];
  constructor(
    dialogService: DialogService,
    private _fb: FormBuilder,
    private apiService: ApiService) {
    super(dialogService);
    this.lockUserModal = this._fb.group({
      UserId: ["", Validators.required],
      Minutes: [null, Validators.required],
    });
  }
  ngOnInit() {
    this.getFormLoad();
  }
  ngAfterViewInit() {
    $('.ui.search.dropdown.forced').dropdown({ forceSelection: false });
  }
  getFormLoad() {
    let params = {};
    this.apiService.post(ApiUrls.LockUser.FormLoad).subscribe(res => {
      if (res.ResponseType == 1) {
        this.userData = res.Data.Users;
      }
    });
  }
  getBaseLoadLock() {
    if (!this.lockUserModal.controls['UserId'].valid) {
      this.err_message = "Please Select User";
      return false;
    }
    if (!this.lockUserModal.controls['Minutes'].valid) {
      this.err_message = "Please Select Minutes";
      return false;
    }
    let params = {
      "Srno": this.lockUserModal.controls['UserId'].value,
      "Minutes": parseInt(this.lockUserModal.controls['Minutes'].value),
      "Type": "Lock"
    };
    this.apiService.post(ApiUrls.LockUser.BaseLoad, params).subscribe(res => {
      if (res.ResponseType == 1) {
        this.err_message = false;
        CommonUtils.toaster(res['Message'], ResponseType.SUCCESS);
        this.close();
      }
    });
  }
  getBaseLoadUnLock() {
    if (!this.lockUserModal.controls['UserId'].valid) {
      this.err_message = "Please Select User";
      return false;
    }
    let params = {
      "Srno": this.lockUserModal.controls['UserId'].value,
      "Type": "Unlock"
    };
    this.apiService.post(ApiUrls.LockUser.BaseLoad, params).subscribe(res => {
      if (res.ResponseType == 1) {
        this.err_message = false;
        CommonUtils.toaster(res['Message'], ResponseType.SUCCESS);
        this.close();
      }
    });
  }
  setAction() {
    let Id = this.lockUserModal.controls['UserId'].value;
    let sel = this.userData.filter(d => d.Id == Id);
    if(sel.length > 0){
        if(sel[0].IsLocked == 1){
            this.isUnLockEnable = false;
            this.isLockEnable = true;
        }else{
          this.isUnLockEnable = true;
            this.isLockEnable = false;
        }
    }
  }
  dismiss(event) {
    this.close();
  }
}
