import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DialogService } from "ng2-bootstrap-modal";

import { ApiUrls } from '../../constants/ApiUrls';
import { ApiService } from '../../../core/services/api.service';
import { CacheService } from '../../../core/services/cache.service';
import { ModelDialogComponent } from '../model-dialog/model-dialog.component';

@Component({
  selector: 'app-change-view',
  templateUrl: './change-view.component.html',
  styleUrls: ['./change-view.component.css']
})

export class ChangeViewComponent extends ModelDialogComponent<any, boolean> implements OnInit {
  theme: any;
  view_type: any;
  view_list: any;
  selected_view: any;
  all_view_list: any;
  GridView_SRNO: any;
  clicked_view_id: any;
  length_alert: any = false;
  max_colums: any;
  view_list2: any = [];

  sortable_options1 = {
    group: 'selected_view',
    revertClone: false,
    ghostClass: "sortable-ghost",
    onAdd: (event: any) => {
    },
    onRemove: (event: any) => {
    },
    onUpdate: (event: any) => {
    },
    onMove: (event: any) => {
      // if (this.view_list2.length >= this.max_colums) {
      //   this.length_alert = true;
      //   return false;
      // }
    }
  }
  sortable_options2 = {
    group: 'selected_view',
    filter: ".true",
    pull: true,
    put: true,
    onAdd: (event: any) => {
      let unique = this.view_list2.filter((elem, index, self) => {
        return index == self.indexOf(elem);
      })
    },
  }

  constructor(
    dialogService: DialogService,
    private httpService: ApiService,
    private cacheService: CacheService) {
    super(dialogService);

    this.theme = this.cacheService.getTheme();
  }

  ngOnInit() {
    this.getDefaultFields();
  }

  confirm(event) {
    this.applyView();
  }

  applyView() {
    let column_data = [];
    let data = this.view_list2;

    for (let i in data) {
      column_data.push({
        'GridView_Column_SRNO': data[i].SRNO,
        'UserDisplayColumnSeq': (parseInt(i) + 1).toString()
      })
    }

    let params = {
      "GridViewSrno": this.clicked_view_id,
      "JsonGridViewUserColumn": JSON.stringify(column_data),
      "Type": "ApplyButton"
    };

    this.httpService.post(this.dialogModel.APIUrl + ApiUrls.GridBaseLoad, params)
      .subscribe(res => {
        if (res.ResponseType == 1) {
          if (res.Data && res.Data.Table && res.Data.Table.length > 0 && res.Data.Table[0].help == "Success") {
            this.result = res.Data.Table[0].GridView_SRNO;
            this.close();
          }
        }
      });
  }

  getViewTypeList(value, column_count) {
    this.selected_view = value;
    this.max_colums = column_count;
    this.clicked_view_id = value;
    let data_holder = [];
    let selected_cols = [];
    for (let data of this.all_view_list) {
      if (value == data.GridView_SRNO && data.UserDisplayColumnSeq == "" && data.FixedOrder == true) {
        selected_cols.push(data);
      }
      if (value == data.GridView_SRNO && data.UserDisplayColumnSeq == "" && data.FixedOrder == false) {
        data_holder.push(data);
      }
      if (value == data.GridView_SRNO && data.UserDisplayColumnSeq != "" && data.FixedOrder == true) {
        selected_cols.push(data);
      }
      if (value == data.GridView_SRNO && data.UserDisplayColumnSeq != "" && data.FixedOrder == false) {
        selected_cols.push(data);
      }
    }
    this.view_list = data_holder;
    this.view_list2 = selected_cols;
  }

  getDefaultFields() {
    let params = {
      "Control": "viewsfield",
      "Type": "Conditional"
    };

    this.httpService.post(this.dialogModel.APIUrl + ApiUrls.GridBaseLoad, params)
      .subscribe(res => {
        if (res.ResponseType == 1) {
          let data = res.Data;
          this.selected_view = this.dialogModel.view_id;
          this.view_type = data.Table;
          this.all_view_list = data.Table1;

          for (let selected_view_data of data.Table) {
            if (this.dialogModel.view_id == selected_view_data.Id) {
              this.getViewTypeList(selected_view_data.Id, selected_view_data.DisplayColumnCount);
            }
          }
        }
      });
  }
}
