import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { RouterModule, Routes, ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../../../core/services/api.service';
import { ResponseContentType, RequestContentType, ResponseType } from '../../enums/enum';
import { CommonUtils } from '../../utility/common-utils';
import { style } from '@angular/animations';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.css']
})
export class DeleteButtonComponent implements OnInit {

  @Input('recordSrno') recordSrno: any;
  @Input('apiUrl') apiUrl: any;
  @Output() onDeleted = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private httpService: ApiService
  ) { }

  ngOnInit() {

  }

  deleteRecord() {
    var self = this;
    //CommonUtils.toaster('Are you sure you want to delete this record?<br /><br /><button id="yes" type="button" style="border: none; background-color: mediumseagreen;">Yes</button>&nbsp;&nbsp;&nbsp;&nbsp;<button id="no" type="button" style="border: none; background-color: saddlebrown;">No</button>', ResponseType.WARNING)

    var data = {
      Type: "Delete",
      Control: "Delete",
      SrNo: self.recordSrno,
    };
    self.httpService.post(self.apiUrl, data)
      .subscribe(res => {
        if (res.ResponseType == 1) {
          CommonUtils.toaster(res.Message, ResponseType.SUCCESS)
          this.hideConfirmationDialog(false);
          self.onDeleted.emit();
        }
        else if (res.ResponseType == 2) {
          CommonUtils.toaster(res.Message, ResponseType.ERROR)
        }
      });
  }

  showConfirmationDialog() {
    $('.mini.modal').modal('show');
  }

  hideConfirmationDialog(showMsg) {
    if (showMsg) {
      CommonUtils.toaster("Your record has been safe :)", ResponseType.SUCCESS);
    }

    $('.mini.modal').modal('hide');
  }
}
