import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { promise } from 'protractor';
import { CacheService } from './cache.service';

@Injectable()
export class PermissionService {
    private _permissions = [];
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private loading$: Observable<boolean>;

    constructor(
        private httpService: ApiService,
        private cacheService: CacheService
    ) {
        this.loading$ = this.loadingSubject.asObservable();
    }

    get permissions() {
        if (this._permissions.length > 0) {
            return this._permissions;
        }
        else {
            return this.cacheService.getPermissions();
        }
    }
    // async getPermissions(url: string): Promise<any> {
    //     const res = await this.httpService.post(url).toPromise();
    //     if (res.ResponseType == 1) {
    //         return res.Data.Permissions;
    //     } else {
    //         return [];
    //     }
    // }    

    async getAllPermissions(): Promise<any> {
        if (this._permissions.length > 0) {
            return new Promise(resolve => {
                resolve(this._permissions);
            });
        }
        else {
            return new Promise(resolve => {
                this.loading$.subscribe(t => {
                    if (t) {
                        resolve(this._permissions);
                    }
                })
            });
        }
    }

    getTabPermissions(permissions: Array<any>, tabs: string[]) {
        let result = {};
        tabs.map(t => {
            let isShow = false;
            if (permissions && permissions.length > 0) {
                isShow = permissions.some((val, index, ary) => {
                    if (val.FormTab_Srno == t && val.ViewRights)
                        return true;
                });
            }
            result[t] = isShow;
        })

        return result;
    }

    getTabSectionPermissions(permissions: Array<any>, tab: string, tabSections: string[]) {
        let result = {
            AddRights: false,
            UpdateRights: false,
            DeleteRights: false
        };

        tabSections.map(t => {
            let permission = {
                ViewRights: false,
                AddRights: false,
                UpdateRights: false,
                DeleteRights: false
            };

            if (permissions && permissions.length > 0) {
                let item = permissions.filter((val, index, ary) => {
                    if (val.FormTabSection_Srno == t)
                        return true;
                });

                if (item.length > 0) {
                    permission.ViewRights = item[0].ViewRights;
                    permission.AddRights = item[0].AddRights;
                    permission.UpdateRights = item[0].UpdateRights;
                    permission.DeleteRights = item[0].DeleteRights;

                    result.AddRights = result.AddRights || permission.AddRights;
                    result.UpdateRights = result.UpdateRights || permission.UpdateRights;
                    result.DeleteRights = result.DeleteRights || permission.DeleteRights;
                }
            }
            result[t] = permission;
        })

        return result;
    }

    setPermisions(data: string[]) {
        this._permissions = data;
        this.cacheService.setPermissions(data);

        this.loadingSubject.next(true);
    }

    getFormPermissions(formSrno: string): Array<any> {
        return this.permissions.filter(t => {
            if (t && t.Form_Srno === formSrno && t.ViewRights) {
                return true;
            }
        });
    }
}