import { Component, OnInit, Input } from '@angular/core';
import { ModelDialogComponent } from '../../../shared/components/model-dialog/model-dialog.component';
import { DialogService, DialogComponent } from 'ng2-bootstrap-modal';
import { DialogModel } from '../../../shared/models/dialog.model';
import { CommonUtils } from '../../../shared/utility/common-utils';
import { ResponseType } from '../../../shared/enums/enum';
import { ApiService } from '../../services/api.service';
import { ApiUrls } from '../../../shared/constants/ApiUrls';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends ModelDialogComponent<any, any> implements DialogModel<any>, OnInit {

  changePasswordModal: any;
  cid: any;
  password_hint: any;
  password_hint_data: any;
  err_message: any;
  showOldPassword: boolean = false;
  isValidPassword: boolean = false;
  email: string = "";
  password_type: string = "password";
  old_password_type: string = "password";
  new_password_type: string = "password";

  constructor(
    dialogService: DialogService,
    private _fb: FormBuilder,
    private apiService: ApiService) {
    super(dialogService);

  }

  ngOnInit() {
    //let data = super.getValue();
    this.showOldPassword = this.dialogModel.showOldPassword;
    this.email = this.dialogModel.email;

    this.changePasswordModal = this._fb.group({
      oldPassword: [this.showOldPassword ? "" : this.dialogModel.oldPassword, this.showOldPassword ? Validators.required : null],
      newPassword: ["", Validators.required],
      confirmNewPassword: ["", Validators.required]
    });
    this.getPasswordHints();
  }

  getPasswordHints() {
    let params = {
      "Corporate": this.cid,
      "Type": "PasswordTooltip"
    };
    this.apiService.post(ApiUrls.CorporateUser.CreateUser_BaseLoad, params)
      .subscribe(
        data => {
          if (data.ResponseType == 1) {
            let password_hint = data.Data.PasswordTooltips[0].PasswordTooltip.split("|");
            this.password_hint = [];
            if (data.Data.PasswordCriterias && data.Data.PasswordCriterias.length) {
              let password_hint_data = data.Data.PasswordCriterias[0];
              this.password_hint_data = data.Data.PasswordCriterias[0];

              this.password_hint.push({
                "Id": 0,
                "Status": true,
                "Display": false,
                "Value": "New password must meet the following requirements",
              })

              let pStatus = true;
              let cStatus = true;
              let nStatus = true;
              let sStatus = true;
              let tStatus = true;
              if (password_hint_data.PasswordMinLength > 0) {
                pStatus = false;
              };
              this.password_hint.push({
                "Id": 1,
                "Status": pStatus,
                "Display": pStatus,
                "Value": "Minimum " + password_hint_data.PasswordMinLength + " Characters Long",
              })
              if (password_hint_data.NoOfCapitalChars > 0) {
                cStatus = false;
              };
              this.password_hint.push({
                "Id": 2,
                "Status": cStatus,
                "Display": cStatus,
                "Value": "Minimum " + password_hint_data.NoOfCapitalChars + " Capital Letter",
              })
              if (password_hint_data.NoOfNumericChars > 0) {
                nStatus = false;
              };
              this.password_hint.push({
                "Id": 3,
                "Status": nStatus,
                "Display": nStatus,
                "Value": "Minimum " + password_hint_data.NoOfNumericChars + " Number",
              })
              if (password_hint_data.NoOfSpecialChars > 0) {
                sStatus = false;
              };
              this.password_hint.push({
                "Id": 4,
                "Status": sStatus,
                "Display": sStatus,
                "Value": "Minimum " + password_hint_data.NoOfSpecialChars + " Special Character",
              })
              // if (password_hint_data.LastSamePasswordDisallow > 0) {
              //   tStatus = true;
              // };
              this.password_hint.push({
                "Id": 5,
                "Status": true,
                "Display": true,
                "Value": "Should not Match with Last " + password_hint_data.LastSamePasswordDisallow + " Password",
              })
              this.password_hint.push({
                "Id": 6,
                "Status": false,
                "Display": false,
                "Value": "Confirm Password Should Match",
              })
            };
          }
        });
  }

  hasUpperCase(myString) {
    return (/[A-Z]/.test(myString));
  }

  hasNumber(myString) {
    return /\d/.test(myString);
  }
  hasSpecial(myString) {
    return /^[a-zA-Z0-9]*$/.test(myString)
  }

  checkTooltip() {
    setTimeout(() => {

      for (const ds in this.password_hint) {
        if (this.password_hint[ds].Id > 0) {
          if (this.changePasswordModal.controls['newPassword'].value) {
            if (this.password_hint_data.PasswordMinLength > 0 && this.password_hint[ds].Id == 1) {
              if (this.changePasswordModal.controls['newPassword'].value.length >= this.password_hint_data.PasswordMinLength) {
                this.password_hint[ds].Status = true;
              } else {
                this.password_hint[ds].Status = false;
              }
            }
            if (this.password_hint_data.NoOfCapitalChars > 0 && this.password_hint[ds].Id == 2) {
              if (this.hasUpperCase(this.changePasswordModal.controls['newPassword'].value)) {
                this.password_hint[ds].Status = true;
              } else {
                this.password_hint[ds].Status = false;
              }
            }
            if (this.password_hint_data.NoOfNumericChars > 0 && this.password_hint[ds].Id == 3) {
              if (this.hasNumber(this.changePasswordModal.controls['newPassword'].value)) {
                this.password_hint[ds].Status = true;
              } else {
                this.password_hint[ds].Status = false;
              }
            }
            if (this.password_hint_data.NoOfSpecialChars > 0 && this.password_hint[ds].Id == 4) {
              if (!this.hasSpecial(this.changePasswordModal.controls['newPassword'].value)) {
                this.password_hint[ds].Status = true;
              } else {
                this.password_hint[ds].Status = false;
              }
            }

            if (this.password_hint[ds].Id == 6) {
              if (this.changePasswordModal.controls['newPassword'].value && this.changePasswordModal.controls['newPassword'].value == this.changePasswordModal.controls['confirmNewPassword'].value) {
                this.password_hint[ds].Status = true;
              } else {
                this.password_hint[ds].Status = false;
              }
            }
          } else {
            this.password_hint[ds].Status = false;
          }
        }
      }

      // console.log(this.password_hint);
      let incs = 0;
      for (var row in this.password_hint) {
        if (this.password_hint[row].Status || this.password_hint[row].Display) {
          incs++;
        };
      }
      if (incs == this.password_hint.length) {
        this.isValidPassword = true;
      } else {
        this.isValidPassword = false;
      }

    }, 300)
  }

  togglePassword() {
    if (this.password_type == 'password') {
      this.password_type = 'text';
    } else {
      this.password_type = 'password';
    }
  }

  toggleNewPassword() {
    if (this.new_password_type == 'password') {
      this.new_password_type = 'text';
    } else {
      this.new_password_type = 'password';
    }
  }

  toggleOldPassword() {
    if (this.old_password_type == 'password') {
      this.old_password_type = 'text';
    } else {
      this.old_password_type = 'password';
    }
  }

  changePassword() {
    if (!this.changePasswordModal.controls['oldPassword'].valid) {
      this.err_message = "Please Enter Old Password";
      return false;
    }
    else if (!this.changePasswordModal.controls['newPassword'].valid) {
      this.err_message = "Please Enter New Password";
      return false;
    }
    else if (this.changePasswordModal.controls['newPassword'].value != this.changePasswordModal.controls['confirmNewPassword'].value) {
      this.err_message = "Confirm New Password is not match";
      return false;
    }
    else {
      let params = {
        "Type": "ChangePassword",
        "Password": this.changePasswordModal.controls['newPassword'].value,
        "OldPassword": this.changePasswordModal.controls['oldPassword'].value
      };
      this.apiService.post(ApiUrls.CorporateUser.CreateUser_BaseLoad, params)
        .subscribe(
          res => {
            if (res.ResponseType == 1) {
              this.err_message = false;
              this.result = {
                password: params.Password
              };
              CommonUtils.toaster(res['Message'], ResponseType.SUCCESS); //success_data.message.MessageHeader, 
              this.close();
            }
            else {
              this.err_message = res.Message;
              //CommonUtils.toaster(res.Message, ResponseType.ERROR); //success_data.message.MessageHeader, 
            }
          },
          err => {
            // console.log(err);
          }
        );
    }
  }

  onlyPassword(event) {
    if (event.charCode == 38 || event.charCode == 39 || event.charCode == 32) {
      return false;
    } else {
      return true;
    }
  }
}
