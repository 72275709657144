import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BootstrapModalModule, DialogService } from 'ng2-bootstrap-modal';
import { NgSemanticModule } from 'ng-semantic';
import { TagInputModule } from 'ngx-chips';
import { MyDatePickerModule } from 'mydatepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import * as $ from 'jquery';
import { SortablejsModule, SortablejsOptions } from 'angular-sortablejs';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { TrumbowygModule } from 'ng2-lazy-trumbowyg';
import { ModelDialogComponent } from './components/model-dialog/model-dialog.component';
import { AdvanceSearchComponent } from './components/advance-search/advance-search.component';
import { ChangeViewComponent } from './components/change-view/change-view.component';
import { GridBoxComponent } from './components/grid-box/grid-box.component';
import { SearchHeaderComponent } from './components/search-header/search-header.component';
import { DeleteButtonComponent } from './components/delete-button/delete-button.component';
import { CommonSummaryComponent } from './components/common-summary/common-summary.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';

import { SearchMasterPipe } from './pipes/search-master.pipe';

import { AccessDeniedChkboxComponent } from './components/access-denied-chkbox/access-denied-chkbox.component';
import { ViewCreateUpdateDeleteChkboxComponent } from './components/view-create-update-delete-chkbox/view-create-update-delete-chkbox.component';
import { BaseGridComponent } from './components/base-grid/base-grid.component';
import { TreeChartComponent } from './components/tree-chart/tree-chart.component';
import { NodesListService } from './components/tree-chart/services/nodesList.service';
import { Tree } from './components/tree-chart/tree.component';
import { Node } from './components/tree-chart/node';
import { NgProgressModule } from 'ngx-progressbar';
import { DndModule } from 'ng2-dnd';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BootstrapModalModule.forRoot({ container: document.body }),
    NgSemanticModule,
    TagInputModule,
    MyDatePickerModule,
    InfiniteScrollModule,
    SortablejsModule,
    NgProgressModule,
    DndModule.forRoot(),
    MalihuScrollbarModule.forRoot(),
    TrumbowygModule.forRoot({ plugins: ['colors', 'noembed', 'preformatted', 'pasteimage', 'upload','table'], version: '2.9.4' })
  ],
  declarations: [
    ModelDialogComponent,
    AdvanceSearchComponent,
    ChangeViewComponent,
    GridBoxComponent,
    SearchHeaderComponent,
    DeleteButtonComponent,
    CommonSummaryComponent,
    SearchMasterPipe,
    AccessDeniedChkboxComponent,
    ViewCreateUpdateDeleteChkboxComponent,
    BaseGridComponent,
    HtmlEditorComponent,
    TreeChartComponent,
    Tree,
    Node
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    MyDatePickerModule,
    InfiniteScrollModule,
    SortablejsModule,
    MalihuScrollbarModule,
    BootstrapModalModule,
    NgProgressModule,
    DndModule,    

    ModelDialogComponent,
    BaseGridComponent,
    HtmlEditorComponent,
    TreeChartComponent,
    GridBoxComponent,
    SearchHeaderComponent,
    AdvanceSearchComponent,
    DeleteButtonComponent,
    CommonSummaryComponent,
    ChangeViewComponent,
    SearchMasterPipe,
    AccessDeniedChkboxComponent,
    ViewCreateUpdateDeleteChkboxComponent
  ],
  providers: [
    DialogService,
    NodesListService
  ],
  entryComponents: [
    ChangeViewComponent
  ],
})
export class SharedModule { }
