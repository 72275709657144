import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.css']
})
export class HtmlEditorComponent implements OnInit {

  @Input('input') input: string;
  @Output('output') output = new EventEmitter<any>();

  initialHtmlEditorValue: string = '';
  optionsHtmlEditor: any = {
    autogrow: true,
    removeformatPasted: true,
    semantic: false,
    btns: ['foreColor', 'backColor', ['viewHTML'],
    ['undo', 'redo'], // Only supported in Blink browsers
    ['formatting'],
    ['strong', 'em', 'del'],
    ['superscript', 'subscript'],
    ['link'],
    ['table'],
    ['insertImage'],
    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
    ['unorderedList', 'orderedList'],
    ['horizontalRule'],
    ['removeformat']]
  };
  content:any;
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(item) {
    if (item.input && item.input.currentValue) {
      this.initialHtmlEditorValue = item.input.currentValue;
    }
  }
  saveChange(){
    this.output.emit(this.content);
  }
}
