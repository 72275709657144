import { AppSettings } from "../constants/AppSettings";
import { ResponseType } from "../enums/enum";
import "toastr";
import * as _ from 'lodash';

export class CommonUtils {
    public static getCDNUrlForCommonMasterContent(corporateId: number, imageurl: string) {
        return AppSettings.AWSurl + 'Documents/' + corporateId + '/MasterData/' + imageurl;
    }

    public static getCDNUrlForMasterDataContent(corporateId: number, masterCode: string, srno: string, imageurl: string) {
        return AppSettings.AWSurl + 'Documents/' + corporateId + '/MasterData/' + masterCode + '/' + srno + '/' + imageurl;
    }

    public static getCDNUrlForContent(corporateId: number, folderName: string, srno: number, imageurl: string) {
        return AppSettings.AWSurl + 'Documents/' + corporateId + '/' + folderName + '/' + srno + '/' + imageurl;
    }

    public static getNoUserProfilePic(corporateId: number) {
        //return AppSettings.AWSurl + 'Documents/' + corporateId + '/Profile/image.jpg';
        return AppSettings.AWSurl + 'Documents/0/Profile/image.jpg';
    }

    public static setFavicon(img) {
        var favicon = document.querySelector('link[rel="icon"]');

        if (!favicon) {
            favicon = document.createElement('link');
            favicon.setAttribute('rel', 'shortcut icon');
            var head = document.querySelector('head');
            head.appendChild(favicon);
        }


        favicon.setAttribute('type', 'image/png');
        favicon.setAttribute('href', img);
    }

    public static toaster(message, type: ResponseType) {
        toastr.options.positionClass = 'toast-top-center';
        toastr.options.closeButton = true;
        toastr.options.progressBar = true;
        toastr.options.preventDuplicates = true;
        toastr.options.newestOnTop = true;

        if (type === ResponseType.ERROR) {
            toastr.error(message);
        } else if (type === ResponseType.SUCCESS) {
            toastr.success(message);
        }
    }

    public static InitDropdown() {
        setTimeout(() => {
            $('.ui.search.dropdown').dropdown({ fullTextSearch: true, forceSelection: false });
        }, 10);
    }

    public static SemanticDropdown() {
        setTimeout(() => {
            $('.semanticDrp').dropdown({ fullTextSearch: true, forceSelection: false });
        }, 10);
    }

    public static ClearSemanticDropdown() {
        $('.semanticDrp').dropdown('clear');
    }

    public static clone(value) {
        return _.cloneDeepWith(value, this.cloneCustomizer);
    }

    static cloneCustomizer(value) {
        if (_.isElement(value)) {
            return value.cloneNode(true);
        }
    }

    // Keypress Restriction Validation
    public static preventEnterKey(event) {
        if (event.keyCode == 10 || event.keyCode == 13)
            //event.preventDefault();
            return false;
    }

    public static onlyName(event) {
        if ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 || event.charCode == 46) {
            return true;
        } else {
            return false;
        }
    }

    public static onlyNumericKey(event) {
        if ((event.charCode >= 48 && event.charCode <= 57)) {
            return true;
        } else {
            return false;
        }
    }

    public static onlyDecimalNo(event) {
        if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46) {
            return true;
        } else {
            return false;
        }
    }

    public static onlyPassword(event) {
        if (event.charCode == 38 || event.charCode == 39 || event.charCode == 32) {
            return false;
        } else {
            return true;
        }
    }
}