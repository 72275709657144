import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { CommonUtils } from '../../utility/common-utils';

@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.css']
})
export class AdvanceSearchComponent implements OnInit {

  //Input Perameter
  @Input('isOpen') isOpen: boolean;
  @Input('isClearRefresh') isClearRefresh: boolean;

  @Input() advancedSearch: TemplateRef<any>;
  @Input() drpSearchBy: any;
  //Output Perameter
  @Output('applySearchCriteria')
  applySearchCriteria = new EventEmitter<any>();

  @Output('clearSearchCriteria')
  clearSearchCriteria = new EventEmitter<any>();

  @Output('closeModal')
  closeModal = new EventEmitter<any>();

  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years = [];
  quarters = ["1st Quarter", "2nd Quarter", "3rd Quarter", "4th Quarter"];
  advance_search_search_by: any;
  keywords: any = [];
  criteria: any = 'none';
  advance_search_from_year: any;
  advance_search_to_year: any;
  advance_search_from_quarter: any;
  advance_search_to_quarter: any;
  advance_search_from_month: any;
  advance_search_to_month: any;
  advance_search_from_date: any;
  advance_search_from_date_model: any;
  advance_search_to_date: any;
  advance_search_to_date_model: any;
  is_validate = false;
  valid_date = false;
  valid_year = false;
  valid_quarter = false;
  valid_from_date = false;
  valid_to_date = false;
  valid_from_month = false;
  valid_to_month = false;
  valid_from_quarter = false;
  valid_to_quarter = false;
  valid_from_year = false;
  valid_to_year = false;
  valid_month = false;
  valid_criteria = false;
  valid_search_by = false;

  constructor() {

  }

  ngOnInit() {
    this.getYears();
  }

  ngOnChanges(item) {
    if (item.drpSearchBy && item.drpSearchBy.currentValue) {
      this.setSerchByDropDown();
    }
    if (item.isOpen && item.isOpen.currentValue) {
      if (this.isOpen == true && item.isOpen.currentValue == true) {
        this.isOpen = false;
      }
      setTimeout(() => {
        this.isOpen = item.isOpen.currentValue;
      }, 100);
    }
    if (item.isClearRefresh && item.isClearRefresh.currentValue) {
      if (item.isClearRefresh.currentValue) {
        this.reset();
      }
    }
  }

  getAdvanceSearch() {
    if (this.advance_search_search_by == -1) {
        let nType = {
          'from_date': this.advance_search_from_date,
          'to_date': this.advance_search_to_date,
          'search_by': this.advance_search_search_by,
          'keywords': this.keywords
        }
        this.applySearchCriteria.emit(nType);
    } else {

      this.advancedSearchValidation();
      this.getSearchTextFormation();
      if (this.is_validate == true) {
        //TODO:
      }
      else {
        this.getCriteria();
        let nType = {
          'from_date': this.advance_search_from_date,
          'to_date': this.advance_search_to_date,
          'search_by': this.advance_search_search_by,
          'keywords': this.keywords
        }
        this.applySearchCriteria.emit(nType);
      }
    }
  }

  advancedSearchValidation() {
    this.is_validate = false;
    if (this.advance_search_search_by === '0' || this.advance_search_search_by === "") {
      this.advance_search_from_date = "";
      this.advance_search_to_date = "";
      return false;
    }
    else if (this.advance_search_search_by === '0' || this.advance_search_search_by === "" && this.criteria != "none") {
      this.valid_search_by = true;
      this.is_validate = true;
      return false;
    }

    if (this.criteria === "none" || this.criteria === undefined) {
      this.valid_criteria = true;
      this.is_validate = true;
      return false;
    }
    else if (this.criteria === 'at') {
      if (this.advance_search_from_date == undefined || this.advance_search_from_date == "") {
        this.valid_from_date = true;
        this.is_validate = true;
        return false;
      }
    }
    else if (this.criteria === 'between') {
      if (this.advance_search_from_date == undefined || this.advance_search_from_date == "") {
        this.valid_from_date = true;
        this.is_validate = true;
        return false;
      }
      else if (this.advance_search_to_date == undefined || this.advance_search_to_date == "") {
        this.valid_to_date = true;
        this.is_validate = true;
        return false;
      }

      /* between */
      if (this.advance_search_from_date > this.advance_search_to_date) {
        this.valid_date = true;
        this.is_validate = true;
        return false;
      }
    }
    else if (this.criteria === 'at_month') {
      if (this.advance_search_from_year == undefined || this.advance_search_from_year == "") {
        this.valid_from_year = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_from_month == undefined || this.advance_search_from_month == "") {
        this.valid_from_month = true;
        this.is_validate = true;
        return false;
      }
    }
    else if (this.criteria === 'between_month') {
      if (this.advance_search_from_year == undefined || this.advance_search_from_year == "") {
        this.valid_from_year = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_from_month == undefined || this.advance_search_from_month == "") {
        this.valid_from_month = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_to_year == undefined || this.advance_search_to_year == "") {
        this.valid_to_year = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_to_month == undefined || this.advance_search_to_month == "") {
        this.valid_to_month = true;
        this.is_validate = true;
        return false;
      }

      /* between */
      if (this.advance_search_from_year > this.advance_search_to_year) {
        this.valid_year = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_from_year == this.advance_search_to_year) {
        if (this.advance_search_from_month > this.advance_search_to_month) {
          this.valid_month = true;
          this.is_validate = true;
          return false;
        }
      }
    }
    else if (this.criteria === 'at_quarter') {
      if (this.advance_search_from_year == undefined || this.advance_search_from_year == "") {
        this.valid_from_year = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_from_quarter == undefined || this.advance_search_from_quarter == "") {
        this.valid_from_quarter = true;
        this.is_validate = true;
        return false;
      }
    }
    else if (this.criteria === 'between_quarter') {
      if (this.advance_search_from_year == undefined || this.advance_search_from_year == "") {
        this.valid_from_year = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_from_quarter == undefined || this.advance_search_from_quarter == "") {
        this.valid_from_quarter = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_to_year == undefined || this.advance_search_to_year == "") {
        this.valid_to_year = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_to_quarter == undefined || this.advance_search_to_quarter == "") {
        this.valid_to_quarter = true;
        this.is_validate = true;
        return false;
      }

      /*between validations*/
      if (this.advance_search_from_year > this.advance_search_to_year) {
        this.valid_year = true;
        this.is_validate = true;
        return false;
      }

      if (this.advance_search_to_quarter > this.advance_search_to_quarter) {
        this.valid_date = true;
        this.is_validate = true;
        return false;
      }
      if (this.advance_search_from_year == this.advance_search_to_year) {
        if (this.advance_search_from_quarter == '2nd Quarter' && this.advance_search_to_quarter == '1st Quarter' || this.advance_search_from_quarter == '3rd Quarter' && this.advance_search_to_quarter == '1st Quarter' || this.advance_search_from_quarter == '4th Quarter' && this.advance_search_to_quarter == '1st Quarter' || this.advance_search_from_quarter == '3rd Quarter' && this.advance_search_to_quarter == '2nd Quarter' || this.advance_search_from_quarter == '4th Quarter' && this.advance_search_to_quarter == '2nd Quarter' || this.advance_search_from_quarter == '4th Quarter' && this.advance_search_to_quarter == '3rd Quarter') {
          this.valid_quarter = true;
          this.is_validate = true;
          return false;
        }
      }
    }
    else if (this.criteria === 'at_year') {
      if (this.advance_search_from_year == undefined && this.advance_search_from_year == "") {
        this.valid_from_year = true;
        this.is_validate = true;
        return false;
      }
    }
  }

  reset() {
    this.criteria = 'none';
    this.advance_search_search_by = '-1';
    this.advance_search_from_year = undefined;
    this.advance_search_from_month = undefined;
    this.advance_search_to_year = undefined;
    this.advance_search_to_month = undefined;
    this.advance_search_from_quarter = undefined;
    this.advance_search_to_quarter = undefined;
    this.is_validate = false;
    this.valid_from_date = false;
    this.valid_to_date = false;
    this.valid_from_month = false;
    this.valid_to_month = false;
    this.valid_from_quarter = false;
    this.valid_to_quarter = false;
    this.valid_from_year = false;
    this.valid_to_year = false;
    this.valid_criteria = false;
    this.valid_search_by = false;
    this.valid_criteria = false;
    this.valid_date = false;
    this.valid_month = false;
    this.valid_quarter = false;
    this.valid_year = false;
    this.advance_search_from_date = undefined;
    this.advance_search_to_date = undefined;
    this.advance_search_from_date_model = "";
    this.advance_search_to_date_model = "";
    this.getSearchTextFormation();
    let nType = {
      'from_date': "",
      'to_date': "",
      'search_by': "",
      'reset': true,
      'keywords': this.keywords
    }
    //this.applySearchCriteria.emit(nType);
    this.clearSearchCriteria.emit(nType);
    CommonUtils.InitDropdown();
  }

  getYears() {
    let currentYear = new Date().getFullYear(), years = [];
    let startYear = 1980;

    while (startYear <= currentYear) {
      this.years.push(startYear++);
    }
  }

  toggleModal() {
    this.isOpen = false;
    this.closeModal.emit(false);
  }

  getCriteria() {
    var d = new Date();
    if (this.advance_search_from_month == undefined) {
      this.advance_search_from_month = d.getMonth() + 1;
    }
    if (this.advance_search_from_year == undefined) {
      this.advance_search_from_year = d.getFullYear();
    }
    if (this.advance_search_to_month == undefined) {
      this.advance_search_to_month = d.getMonth() + 1;
    }
    if (this.advance_search_to_year == undefined) {
      this.advance_search_to_year = d.getFullYear();
    }
    if (this.criteria == 'at') {
      this.advance_search_to_date = this.advance_search_from_date;
    }
    else if (this.criteria == 'between') {
    }

    else if (this.criteria == 'at_month') {
      this.advance_search_from_date = this.advance_search_from_year + "/" + this.advance_search_from_month + "/" + 1;
      this.advance_search_to_date = this.getLastDate(this.advance_search_from_year, this.advance_search_from_month);
    }
    else if (this.criteria == 'between_month') {
      this.advance_search_from_date = this.advance_search_from_year + "/" + this.advance_search_from_month + "/" + 1;
      this.advance_search_to_date = this.getLastDate(this.advance_search_to_year, this.advance_search_to_month);
    }
    else if (this.criteria == 'at_quarter') {
      let quarter = this.advance_search_from_quarter;
      if (quarter == '1st Quarter') {
        this.advance_search_from_date = this.advance_search_from_year + "/01/01";
        this.advance_search_to_date = this.getLastDate(this.advance_search_from_year, 3);
      }
      else if (quarter == '2nd Quarter') {
        this.advance_search_from_date = this.advance_search_from_year + "/04/01";
        this.advance_search_to_date = this.getLastDate(this.advance_search_from_year, 6);

      }
      else if (quarter == '3rd Quarter') {
        this.advance_search_from_date = this.advance_search_from_year + "/07/01";
        this.advance_search_to_date = this.getLastDate(this.advance_search_from_year, 9);
      }
      else if (quarter == '4th Quarter') {
        this.advance_search_from_date = this.advance_search_from_year + "/10/1";
        this.advance_search_to_date = this.getLastDate(this.advance_search_from_year, 12);
      }
    }
    else if (this.criteria == 'between_quarter') {
      let from_quarter = this.advance_search_from_quarter;
      if (from_quarter == '1st Quarter') {
        this.advance_search_from_date = this.advance_search_from_year + "/01/01";
      }
      else if (from_quarter == '2nd Quarter') {
        this.advance_search_from_date = this.advance_search_from_year + "/04/01";
      }
      else if (from_quarter == '3rd Quarter') {
        this.advance_search_from_date = this.advance_search_from_year + "/07/01";
      }
      else if (from_quarter == '4th Quarter') {
        this.advance_search_from_date = this.advance_search_from_year + "/10/01";
      }
      let to_quarter = this.advance_search_to_quarter;
      if (to_quarter == '1st Quarter') {
        this.advance_search_to_date = this.getLastDate(this.advance_search_to_year, 3);
      }
      else if (to_quarter == '2nd Quarter') {
        this.advance_search_to_date = this.getLastDate(this.advance_search_to_year, 6);
      }
      else if (to_quarter == '3rd Quarter') {
        this.advance_search_to_date = this.getLastDate(this.advance_search_to_year, 9);
      }
      else if (to_quarter == '4th Quarter') {
        this.advance_search_to_date = this.getLastDate(this.advance_search_to_year, 12);
      }
    }
    else if (this.criteria == 'at_year') {
      this.advance_search_from_date = this.advance_search_from_year + "/" + '01' + "/" + '01';
      this.advance_search_to_date = this.advance_search_from_year + "/" + '12' + "/" + '31';
    }
  }

  getLastDate(Year, Month) {
    var d = new Date(parseInt(Year), parseInt(Month), 0);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let full_date = Year + '/' + mm + '/' + dd;
    return full_date;
  }

  flaseTooltip(from) {

    setTimeout(() => {
      $('.ui.search.dropdown.forced').dropdown({ forceSelection: false });
    }, 10);

    if (from == 'valid_search_by') {
      this.valid_search_by = false;
      this.valid_criteria = false;
    }
    if (from == 'valid_criteria') {
      this.valid_criteria = false;
      this.is_validate = false;
      this.valid_from_date = false;
      this.valid_to_date = false;
      this.valid_from_month = false;
      this.valid_to_month = false;
      this.valid_from_quarter = false;
      this.valid_to_quarter = false;
      this.valid_from_year = false;
      this.valid_to_year = false;

      this.valid_search_by = false;
      this.valid_criteria = false;

      this.valid_date = false;
      this.valid_month = false;
      this.valid_quarter = false;
      this.valid_year = false;
      if (this.criteria == 'at_month' || this.criteria == 'between_month' || this.criteria == 'between_quarter' || this.criteria == 'at_quarter' || this.criteria == 'at_year') {
        this.advance_search_from_year = new Date().getFullYear();
      } else {
        this.advance_search_from_year = "";
      }
      if (this.criteria == 'between_month' || this.criteria == 'between_quarter') {
        this.advance_search_to_year = new Date().getFullYear();
      } else {
        this.advance_search_to_year = "";
      }
    }

    if (from == 'valid_from_month') {
      this.valid_from_month = false;
      this.valid_month = false;
    }
    if (from == 'valid_to_month') {
      this.valid_to_month = false;
      this.valid_month = false;
    }
    if (from == 'valid_from_quarter') {
      this.valid_from_quarter = false;
      this.valid_quarter = false;
    }
    if (from == 'valid_to_quarter') {
      this.valid_to_quarter = false;
      this.valid_quarter = false;
    }
    if (from == 'valid_from_year') {
      this.valid_from_year = false;
      this.valid_year = false;
      this.valid_month = false;
      this.valid_quarter = false;
    }
    if (from == 'valid_to_year') {
      this.valid_from_year = false;
      this.valid_year = false;
    }
  }

  onDateChangedFrom(event) {
    this.advance_search_from_date = undefined;
    if (event.formatted != undefined) {
      this.advance_search_from_date = event.formatted.split('-').reverse().join('/');
      this.valid_from_date = false;
    }
  }
  removeDateModel() {
    this.advance_search_from_date_model = "";
    this.advance_search_to_date_model = "";
  }

  onDateChangedTo(event) {
    this.advance_search_to_date = event.formatted.split('-').reverse().join('/');
    this.valid_to_date = false;
    this.valid_date = false;
  }

  setSerchByDropDown() {
    if (this.drpSearchBy && this.drpSearchBy.length > 0)
      this.advance_search_search_by = this.drpSearchBy[0].Id;
  }

  getSearchTextFormation() {
    this.keywords = [];
    if (this.advance_search_search_by !== undefined && this.advance_search_search_by !== "" && this.advance_search_search_by !== 'undefined') {
      for (let data of this.drpSearchBy) {
        if (data.Id == this.advance_search_search_by) {
          if (this.criteria !== undefined && this.criteria !== "" && this.advance_search_search_by !== 'undefined') {
            if (this.criteria === "none") {

            }
            else if (this.criteria === 'at') {
              let criteria = this.criteria.charAt(0).toUpperCase() + this.criteria.slice(1);
              criteria = criteria.replace('_', ' ');
              this.keywords.push(data.Value + " at " + this.ddmmyyyy(this.advance_search_from_date));
            }
            else if (this.criteria === 'between') {
              this.keywords.push(data.Value + " between " + this.ddmmyyyy(this.advance_search_from_date) + " and " + this.ddmmyyyy(this.advance_search_to_date));
            }
            else if (this.criteria === 'at_month') {
              this.keywords.push(data.Value + " between " + this.ddmmyyyy(this.advance_search_from_date) + " and " + this.ddmmyyyy(this.advance_search_to_date));
            }
            else if (this.criteria === 'between_month') {
              this.keywords.push(data.Value + " between " + this.ddmmyyyy(this.advance_search_from_date) + " and " + this.ddmmyyyy(this.advance_search_to_date));
            }
            else if (this.criteria === 'at_quarter') {
              this.keywords.push(data.Value + " between " + this.ddmmyyyy(this.advance_search_from_date) + " and " + this.ddmmyyyy(this.advance_search_to_date));
            }
            else if (this.criteria === 'between_quarter') {
              this.keywords.push(data.Value + " between " + this.ddmmyyyy(this.advance_search_from_date) + " and " + this.ddmmyyyy(this.advance_search_to_date));
            }
            else if (this.criteria === 'at_year') {
              this.keywords.push(data.Value + " between " + this.ddmmyyyy(this.advance_search_from_date) + " and " + this.ddmmyyyy(this.advance_search_to_date));
            }
          }
        }
      }
    }
  }

  ddmmyyyy(date) {
    if (date == undefined || date == "" || date == null) {
      return 'Today';
    }
    let formated_date = date.split('/').reverse().join('/')
    return formated_date;
  }
}
