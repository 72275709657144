import { Component, OnInit, HostListener } from '@angular/core';
import { CommonUtils } from './shared/utility/common-utils';
import { CacheService } from './core/services/cache.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private cacheService: CacheService,
    private titleService: Title) {
  }

  ngOnInit(): void {
    CommonUtils.setFavicon(this.cacheService.getFavicon());
    this.titleService.setTitle(this.cacheService.getPageTitle());
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    // console.log(event);
    // event.returnValue = "Are you sure you want to leave?";
    // event.preventDefault();
    // event.stopPropagation();
    // return false;
  }
}
