import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { DialogModel } from '../../models/dialog.model';

@Component({
  selector: 'app-model-dialog',
  templateUrl: './model-dialog.component.html',
  styleUrls: ['./model-dialog.component.css']
})
export class ModelDialogComponent<T, TResponse> extends DialogComponent<T, TResponse> implements DialogModel<T> {
  public dialogModel: T

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  dismiss(event) {
    this.close();
  }
}
