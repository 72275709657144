import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { ChangeViewComponent } from '../change-view/change-view.component';
import { ApiUrls } from '../../constants/ApiUrls';
import { DialogService } from 'ng2-bootstrap-modal';
import { ApiService } from '../../../core/services/api.service';
import { CacheService } from '../../../core/services/cache.service';
import { IMyOptions } from 'mydatepicker';
import { myAwesomeAnimation } from '../../../shared/utility/animations';

@Component({
  selector: 'app-base-grid',
  templateUrl: './base-grid.component.html',
  styleUrls: ['./base-grid.component.css'],
  animations: [
    myAwesomeAnimation
  ]
})
export class BaseGridComponent implements OnInit {
  //Commanon Component Perameter
  @Input() advancedSearch: TemplateRef<any>;
  @Input() gridBoxlayout: TemplateRef<any>;
  @Input() gridHeaderlayout: TemplateRef<any>;

  @Input() navUrl: string;
  @Input() CustomFormSrno: string;
  @Input() isAddPermisson: any;
  @Input() showAdvancedSearch: boolean = true;

  @Input() getTagsURL: string;

  @Input() formLoadUrl: string;

  @Input() searchApiURL: string;

  @Input()
  currentPage: number = 1;

  @Input() totalRecords: number;

  @Input() records: any;

  @Input() fields: any;

  @Output('edit')
  edit = new EventEmitter<any>();

  @Output()
  afterFormLoad = new EventEmitter<any>();

  @Output()
  afterClearSearchCriteria = new EventEmitter<any>();

  @Output()
  getGridData = new EventEmitter<any>();

  fix_menu = false;
  menu_position: any;
  state: any;
  theme: any;
  view_popup = false;
  selected_view: any;
  myDatePickerOptions: IMyOptions = { dateFormat: 'dd-mm-yyyy' };
  appliedviewname: any;

  is_load_first_time: boolean = false;
  is_add_rights: boolean = false;
  modal_status: boolean = false;
  isClearRefresh: boolean = false;

  view_id: any;
  default_view_id: any;
  default_viewname: any;
  appliedSearchCriteria: any;
  appliedTags: any;


  drpSearchBy: any;

  constructor(public dialogService: DialogService,
    private httpService: ApiService,
    private cacheService: CacheService) { }


  ngOnInit() {
    this.is_load_first_time = true;
    window.scrollTo(0, 0);
    this.theme = this.cacheService.getTheme();
    this.menu_position = this.cacheService.getMenuPosition();
    this.is_add_rights = this.isAddPermisson;

    this.getCurrentView_Id();
    this.reloadGridData(true, true);
  }

  ngOnChanges(item) {
    if (item && !item.formLoadUrl) {
      this.is_load_first_time = false;
    }
  }

  toggleAdvancedSeach(event) {
    if (this.modal_status == false && event == false) {
      this.modal_status = true;
    } else {
      this.modal_status = event;
    }
  }

  applySearchCriteria(event) {
    this.appliedSearchCriteria = event;
    this.reloadGridData(true);
  }

  clearSearchCriteria(event) {
    this.afterClearSearchCriteria.emit(event);
    this.appliedSearchCriteria = null;
    this.reloadGridData(true);
  }

  applyTags(event) {
    this.appliedTags = event;
    this.reloadGridData(true);
  }

  refreshGrid(event) {
    this.appliedTags = null;
    this.clearSearchCriteria(null);
    this.isClearRefresh = true;
  }

  onEdit(event) {
    this.edit.emit(event);
  }

  reloadGridData(resetPageNo = false, requiredFields = false) {
    if (resetPageNo) {
      this.currentPage = 1;
    }

    let keywords = this.getSearchCriteriaKeywords();

    let params = {
      "GridViewSrno": this.view_id,
      "PageNo": this.currentPage,
      "RecordsPerPage": this.cacheService.getPageSize(),
      "IsColumnReq": requiredFields,
      "TagSearchValue": this.appliedTags ? this.appliedTags.tagId : null,
      "FromDate": this.appliedSearchCriteria ? this.appliedSearchCriteria.from_date : null,
      "ToDate": this.appliedSearchCriteria ? this.appliedSearchCriteria.to_date : null,
      "SearchBy": this.appliedSearchCriteria ? this.appliedSearchCriteria.search_by : null,
      "OrderBy": "",
      "Direction": "",
      "SearchCriteriaKeywords": JSON.stringify(keywords)
    }

    this.getGridData.emit(params);
  }

  pageChanged() {
    if (!this.view_id)
      return;

    let pageSize = this.cacheService.getPageSize();

    let totalPages = parseInt((this.totalRecords / pageSize).toFixed(0)) + (this.totalRecords % pageSize > 0 ? 1 : 0);
    if (totalPages >= this.currentPage)
      this.currentPage++;

    this.reloadGridData();
  }

  getCurrentView_Id() {
    let params = {
      "Type": "Load"
    };

    this.httpService.post(this.formLoadUrl, params)
      .subscribe(res => {
        if (res.ResponseType == 1) {
          let data = res.Data;
          //&& data.Table[0].Default_GridView_SRNO != data.Table[0].GridView_SRNO
          if (data.Table[0].GridView_SRNO && data.Table[0].CurrentViewName) {
            this.view_id = data.Table[0].GridView_SRNO;
            this.appliedviewname = data.Table[0].CurrentViewName;
            this.selected_view = "change_view";
          } else {
            this.view_id = data.Table[0].Default_GridView_SRNO;
            this.appliedviewname = data.Table[0].ViewName;
            this.selected_view = "default_view";
          }
          this.default_view_id = data.Table[0].Default_GridView_SRNO;
          this.default_viewname = data.Table[0].ViewName;

          //---Start--Apply multiple pages in single pages
          if (data.Table && data.Table.length == 2) {
            let item;
            let form_type = window.location.hash.split('/');
            if (form_type[3] == 'credit' || form_type[2] == 'payment') {
              item = data.Table[0];
            }
            else {
              item = data.Table[1];
            }
            if (item.GridView_SRNO) {
              this.view_id = item.GridView_SRNO;
              this.selected_view = "change_view";
            } else {
              this.view_id = item.Default_GridView_SRNO;
              this.selected_view = "default_view";
            }
            this.default_view_id = item.Default_GridView_SRNO;
            this.appliedviewname = item.CurrentViewName;
          }
          //---End----//

          this.drpSearchBy = data.Table1;
          this.afterFormLoad.emit(data);
        }
      });
  }

  changeView(event) {
    this.view_popup = false;
    let modal = this.dialogService.addDialog(ChangeViewComponent, {
      dialogModel: {
        APIUrl: this.searchApiURL,
        view_id: this.view_id
      }
    }).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.view_id = isConfirmed;
        this.selected_view = 'change_view';

        this.reloadGridData(true, true);
      }
    });
  }

  defaultView(event) {
    this.view_popup = false;
    this.view_id = this.default_view_id;
    this.appliedviewname = this.default_viewname;
    this.selected_view = 'default_view';

    this.reloadGridData(true, true);
  }

  getSearchCriteriaKeywords() {
    let keywords = [];

    if (this.appliedTags) {
      keywords.push({
        Key: 'Tags',
        Value: JSON.stringify(this.appliedTags.tagString)
      })
    }

    if (this.appliedSearchCriteria && this.appliedSearchCriteria.search_by && this.appliedSearchCriteria.search_by != "0") {
      keywords.push({
        Key: 'SearchBy',
        Value: this.appliedSearchCriteria.keywords && this.appliedSearchCriteria.keywords.length ? this.appliedSearchCriteria.keywords[0] : ""
      })
    }

    return keywords;
  }
}
