import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AuthenticationService } from './services/authentication.service';
import { CacheService } from './services/cache.service';
import { ApiService } from './services/api.service';
import { QuotService } from './services/quot.service';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { ChangePasswordComponent } from './header/change-password/change-password.component';
import { LockUnlockUserComponent } from './header/lock-unlock-user/lock-unlock-user.component';
import { PermissionService } from './services/permission.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    HttpModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    ChangePasswordComponent,
    LockUnlockUserComponent
  ],
  providers: [
    AuthenticationService,
    CacheService,
    ApiService,
    QuotService,
    PermissionService
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ],
  entryComponents: [
    ChangePasswordComponent,
    LockUnlockUserComponent
  ]
})
export class CoreModule { }
