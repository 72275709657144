import { Injectable, EventEmitter } from '@angular/core';
import { RequestOptions, Headers, Http, URLSearchParams } from '@angular/http';
import { HttpParams } from '@angular/common/http';
import { CacheService } from './cache.service';
import { ResponseContentType, RequestContentType, ResponseType } from '../../shared/enums/enum';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../shared/constants/AppSettings';
import { Router } from '@angular/router';

@Injectable()

export class QuotService {
	selected_all_hotels = [];
    selected_transfer_guides = [];
    selected_sight_meals = [];
    selected_galas = [];
    selected_optional_data = [];
    UpdateExchangeRate: EventEmitter<any>;
    SetPdfFileUrl: EventEmitter<any>;

    constructor() {
    	this.UpdateExchangeRate = new EventEmitter();
    	this.SetPdfFileUrl = new EventEmitter();
    }
}